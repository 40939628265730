import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { setInitialize, setUser, logout } from 'redux/slices/authSlice';
import axios from 'axios';
import { API_URL } from 'config/appConfig';
import { PATH_PAGE } from 'config/paths';
// ----------------------------------------------------------------------
export default function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken, refreshToken, remember, user, isLoading, isAuthenticated } = useSelector(
    (state) => state.auth
  );

  return {
    remember,
    isAuthenticated,
    isLoading,
    user,
    initAuth: async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const refreshToken = window.localStorage.getItem('refreshToken');
        if (accessToken) {
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          const response = await axios.get(API_URL.authenticateByToken);
          const { user } = response.data;
          dispatch(
            setInitialize({
              isAuthenticated: true,
              user,
              accessToken,
              refreshToken
            })
          );
        } else {
          dispatch(
            setInitialize({
              isAuthenticated: false,
              user: null,
              accessToken: null,
              refreshToken: null
            })
          );
        }
      } catch (error) {
        console.error(error);
        dispatch(
          setInitialize({
            isAuthenticated: false,
            user: null,
            accessToken: null
          })
        );
      }
    },
    login: async (email, password, remember) => {
      try {
        const response = await axios.post(API_URL.login, {
          email,
          password
        });
        if (response && response.data) {
          const { tokens, user } = response.data;
          dispatch(
            setUser({
              user,
              accessToken: tokens.access.token,
              refreshToken: tokens.refresh.token,
              remember
            })
          );
        }
      } catch (error) {
        console.error();
        throw error;
      }
    },
    logout: async () => {
      try {
        const refreshToken = window.localStorage.getItem('refreshToken');
        if (!refreshToken) {
          console.log('no refresh token founded');
          dispatch(logout());
          return;
        }
        dispatch(logout());
        await axios.post(API_URL.logout, {
          refreshToken
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    handleErrorAxios: () => {
      try {
        // Add a 401 response interceptor
        axios.interceptors.response.use(
          function (response) {
            return response;
          },
          function (error) {
            if (error.response.status === 401) {
              console.log('not authenticated');
              // handle error: inform user, go to login, etc
              // navigate(`${PATH_PAGE.auth.login}`);
              dispatch(
                setInitialize({
                  isAuthenticated: false,
                  user: null,
                  accessToken: null,
                  refreshToken: null
                })
              );
            } else {
              return Promise.reject(error);
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
  };
}
