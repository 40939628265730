import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { PATH_PAGE, PATH_APP } from 'config/paths';
// ----------------------------------------------------------------------
export default function RequireGuest({ children }) {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  return isAuthenticated === false ? (
    children
  ) : (
    <Navigate to={PATH_APP.dashboard} replace state={{ path: location.pathname }} />
  );
}
