import React, { forwardRef, useRef } from 'react';

import ReportAnalyse from './ReportAnalyse';
import ReportPointTracing from './ReportPointTracing';
import ReportSuper from './ReportSuper';
import ReportCoverPage from './ReportCoverPage';
// ----------------------------------------------------------------------
const ReportToPrint = forwardRef(({ ...props }, ref) => {
  return (
    <div ref={ref}>
      <ReportCoverPage />
      <div className="page-break" />
      <ReportAnalyse />
      <div className="page-break" />
      <ReportPointTracing />
      <div className="page-break" />
      <ReportSuper />
    </div>
  );
});
export default ReportToPrint;
