import React, { useEffect, useRef, useState } from 'react';
import { Tooltip, Box, IconButton } from '@mui/material';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import { SVGEDITOR_WIDTH, SVGEDITOR_HEIGHT } from 'config/appConfig';
import * as d3 from 'd3';
// ----------------------------------------------------------------------
const pngWidth = SVGEDITOR_WIDTH;
const pngHeight = SVGEDITOR_HEIGHT;
const MARGIN = 10;
const TRANSITION_TIME = 750;
// ----------------------------------------------------------------------

const SvgToImgDownload = React.forwardRef(({ svgEl, imgName, addRight }, ref) => {
  const [bbox, setbbox] = useState(null);
  const timer = useRef(null);
  useEffect(() => {
    if (!svgEl) return;
    const timeout = setTimeout(() => {
      const offsets = document.getElementById(svgEl).getBoundingClientRect();
      const top = offsets.top + MARGIN;
      const left = offsets.left;
      const right = offsets.right + MARGIN;
      const bottom = offsets.left;
      if (offsets) {
        setbbox({ top, left, right, bottom });
      }
    }, 1000);
    return () => {
      clearTimeout(timeout);
      clearTimeout(timer);
    };
  }, [svgEl]);
  const downloadImg = () => {
    try {
      if (!bbox) return;
      if (!ref || !ref.current) return;
      const svgSelected = d3.select(ref.current);
      d3.select('#globalGroup').selectAll('path').attr('fill', 'transparent');
      d3.select('#globalGroup').selectAll('use').style('display', 'none');
      svgSelected
        .transition()
        .duration(TRANSITION_TIME)
        .selectAll('g')
        .attr('transform', 'translate(0,0) scale(1)');
      timer.current = setTimeout(() => {
        const canvas = document.createElement('canvas'); // create <canvas> element
        const context = canvas.getContext('2d');
        canvas.width = pngWidth;
        canvas.height = pngHeight;
        const image = new Image(); // create <img> element
        image.onload = function () {
          context.fillStyle = context.createPattern(image, 'no-repeat');
          context.fillRect(0, 0, canvas.width, canvas.height);
          const href = canvas.toDataURL('image/png');
          const name = `${imgName ? imgName : new Date().getTime()}.png`;
          const a = document.createElement('a');
          a.download = name;
          a.href = href;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        };
        const svgStr = document.getElementById(svgEl).outerHTML;
        const svg = new Blob([svgStr], { type: 'image/svg+xml' });
        image.src = URL.createObjectURL(svg);
      }, Number(TRANSITION_TIME + 100));
    } catch (error) {
      console.error(error);
    }
  };
  if (!bbox) return <></>;
  return (
    <Box
      sx={{
        position: 'fixed',
        top: `${bbox.top}px`,
        right: `${MARGIN * 2 + addRight ? Number(addRight) : 0}px`
      }}
    >
      <Tooltip title="Sauvegarder la photo en png">
        <IconButton color="primary" aria-label="delete" size="medium" onClick={downloadImg}>
          <CenterFocusWeakIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Box>
  );
});

export default SvgToImgDownload;
