import React, { useState } from 'react';

import {
  Button,
  IconButton,
  Card,
  Popover,
  CardActions,
  CardContent,
  Typography
} from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
// ----------------------------------------------------------------------
export default function DeleteTracingBtn({ editTracingStep, onDeleteTracing }) {
  const [submitting, setsubmitting] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleConfirm = () => {
    onDeleteTracing();
    handleClose();
  };
  if (editTracingStep) return <></>;
  return (
    <div>
      <Button aria-label="details" size="small" onClick={handleClick} color="error">
        <RestartAltIcon /> Réinitialiser les tracés
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Card
          sx={{
            minWidth: 275,
            backgroundColor: '#1A2027'
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Etes vous sur de réinitialiser ?
            </Typography>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" disabled={submitting} onClick={handleConfirm}>
              Valider
            </Button>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </div>
  );
}
