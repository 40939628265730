import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PolylineIcon from '@mui/icons-material/Polyline';
import { styled, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import DoneIcon from '@mui/icons-material/Done';
import NearMeIcon from '@mui/icons-material/NearMe';
import { MIconButton } from 'theme/@material-extend/Buttons';
import {
  SVGPathData,
  SVGPathDataTransformer,
  SVGPathDataEncoder,
  SVGPathDataParser
} from 'svg-pathdata';
import * as d3 from 'd3';
import { zoom } from 'd3-zoom';
import { drag } from 'd3-drag';
import { path } from 'd3-path';
import {
  setMovePoint,
  getBoundingBoxCenter,
  drawTransfertPath,
  applyTranformToGroupToPath,
  getMovePoint,
  toRel,
  parseToCommands
} from 'utils/pathUtil';
import {
  SVGEDITOR_WIDTH,
  SVGEDITOR_HEIGHT,
  SVGEDITOR_POINT_RADIUS,
  SVGEDITOR_LABEL_SPACING,
  SVGEDITOR_TOOLBAR_WIDTH,
  API_URL,
  SUPER_GENERALE,
  SUPER_MAXI,
  SUPER_MANDI,
  SUPER_COLOR__FILL,
  TRANSFERT_GUIDE,
  TRANSFERT_GUIDE_IDPATH,
  TRANCING_GROUP_ID,
  IMPLAN,
  SVGEDITOR_TRACING_STROKEWIDTH
} from 'config/appConfig';

import useReferentiel from 'hooks/useReferentiel';
import { blobToBase64 } from 'utils/imageUtil';
import usePointTracing from 'hooks/usePointTracing';
import useNotification from 'hooks/useNotification';
import {
  distanceBetweenPoints,
  toSvgData,
  xmlToBase64,
  getTransform,
  setTransform,
  ROTATE,
  TRANSLATE,
  SCALE,
  hexToRGB
} from 'utils/d3Util';
import usePatient from 'hooks/usePatient';
import SvgToImgDownload from 'components/media/SvgToImgDownload';
import SvgEditorCloseBtn from 'components/d3/SvgEditorCloseBtn';
import useEditPath from 'hooks/useEditPath';
import TransformSuper from './TransformSuper';
// ----------------------------------------------------------------------
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));
const TRANSITION_TIME = 750;
const TRACING_GROUP = {
  globalGroup: TRANCING_GROUP_ID,
  idGroup: null,
  currentNoTransform: null
};
// ----------------------------------------------------------------------
export default function DialogSuperEditor({ editSuperposition, onComplete, handleClose }) {
  const {
    mixins: { toolbar }
  } = useTheme();
  const { refs } = useReferentiel();
  const { notif } = useNotification();
  /* svg editor */
  const svgRef = useRef(null);
  const zoomHandler = zoom();
  const handler = drag();
  const timer = useRef(null);
  const { patient } = usePatient();
  const disabledField = patient && patient.id && patient.lockOff;
  const [transfertGuide, settransfertGuide] = useState(null);
  const [currentTracings, setcurrentTracings] = useState(null);
  //--------------------------------------------------------------------
  const boxTransformGroupRef = useRef(null);
  const editTracingGroupRef = useRef(TRACING_GROUP);
  // ----------------------------------------------------------------------
  const {
    initEditSvgGroup,
    handleUpdateSvgGroup,
    getEditGroupRef,
    restoreToStep,
    redrawUsesForGroup
  } = useEditPath();
  // ----------------------------------------------------------------------
  /**listener keydown for edit path */
  useEffect(() => {
    document.addEventListener('keydown', keydownHandler);
    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, []);
  // ----------------------------------------------------------------------
  const KEYDOWN = [90, 89];
  const keydownHandler = (e) => {
    if (KEYDOWN.includes(e.keyCode) && e.ctrlKey) {
      const { histoGroup, createdAt } = getEditGroupRef();
      const isCtrlZ = e.keyCode === 90;
      const isHistoNotEmpty = histoGroup.length > 0;
      if (!isHistoNotEmpty) return;
      const idxCurrentState = histoGroup.findIndex((it) => {
        return it.createdAt.getTime() === createdAt.getTime();
      });
      const idx = isCtrlZ ? idxCurrentState - 1 : idxCurrentState + 1;
      if (idx < 0 || idx > histoGroup.length - 1) return;

      const goToHisto = histoGroup[idx];
      restoreToStep(goToHisto);
    }
  };
  //--------------------------------------------------------------------
  useEffect(() => {
    if (!editSuperposition) return;
    if (!editSuperposition.tracings) return;
    setcurrentTracings(editSuperposition.tracings);
    if (editSuperposition.transfertGuide) {
      settransfertGuide({
        ...editSuperposition.transfertGuide,
        updatedAt: new Date()
      });
    } else {
      settransfertGuide({ path: null, updatedAt: new Date() });
    }
  }, [editSuperposition]);
  // ----------------------------------------------------------------------
  /** draw maxi or mandi path */
  useEffect(() => {
    if (!editSuperposition) return;
    if (!editSuperposition.tracings) return;

    let timeout = setTimeout(() => {
      const arrImLine = [];
      editSuperposition.tracings.forEach((pointTracing) => {
        const { color } = pointTracing;
        if (pointTracing.implineMaxi) {
          arrImLine.push({ ...pointTracing.implineMaxi, color });
        }
        if (pointTracing.implineMandi) {
          arrImLine.push({ ...pointTracing.implineMandi, color });
        }
      });
      if (arrImLine.length > 0) {
        drawImplanLines(arrImLine);
      }
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [editSuperposition]);
  /** drawImplanLines */
  const drawImplanLines = (arr) => {
    try {
      if (!svgRef.current) return;
      const svg = d3.select(svgRef.current);
      /** remove all circle or use */
      const gTracing = svg.select(`#${TRANCING_GROUP_ID}`);
      gTracing.select(`#${IMPLAN}`).remove();
      const g = gTracing.append('g').attr('id', IMPLAN);
      arr.forEach((implan) => {
        const { path, originPath } = implan;
        g.append('path')
          .attr('d', path)
          .attr('fill', 'transparent')
          .attr('stroke', implan.color ? implan.color : 'black')
          .attr('stroke-width', SVGEDITOR_TRACING_STROKEWIDTH);
      });
      g.raise();
    } catch (error) {
      console.error(error);
    }
  };
  // ----------------------------------------------------------------------

  /** construct tracing on svg */
  useEffect(() => {
    if (!currentTracings) return;
    if (!transfertGuide) return;
    if (!transfertGuide.updatedAt) return;
    //reset
    boxTransformGroupRef.current.style.display = 'none';
    const gGlobal = d3.select(`#${TRANCING_GROUP_ID}`);
    currentTracings.forEach((trac) => {
      const { color, id, tracings } = trac;
      const tracingGroupId = 'tracingGroup_' + id;
      const toBeDeleted = d3.select(`#${tracingGroupId}`);
      if (!toBeDeleted.empty()) {
        toBeDeleted.remove();
      }
      const g = gGlobal
        .append('g')
        .attr('id', tracingGroupId)
        .attr('idGroup', tracingGroupId)
        .attr('idPointTracing', id)
        .attr('groupColor', color);
      tracings.forEach((it) => {
        const idPath = `id_${trac.id}_${it.id}`;
        g.append('path')
          .attr('id', idPath)
          .attr('code', it.code)
          .attr('idPath', idPath)
          .attr('idGroup', tracingGroupId)
          .attr('d', it.imgpath)
          .attr('fill', 'transparent')
          .attr('stroke', color ? color : 'black')
          .attr('stroke-width', SVGEDITOR_TRACING_STROKEWIDTH);
      });

      if (!transfertGuide.paths) {
        g.on('mouseover', function (d) {
          d3.select(this).style('cursor', 'move').attr('fill', 'black');
        })
          .on('mouseout', function (d) {})
          .on('click', (e) => {
            handleClickOnPath(tracingGroupId);
          })
          .call(handler.on('start', dragstarted).on('drag', dragged).on('end', dragended))
          .raise();
      }
    });

    if (transfertGuide.paths) {
      /** add tranfert guide */
      gGlobal.select(`#${TRANSFERT_GUIDE}`).remove();
      const g = gGlobal.append('g').attr('id', TRANSFERT_GUIDE).attr('idGroup', TRANSFERT_GUIDE);
      transfertGuide.paths.forEach((p) => {
        g.append('path')
          .attr('id', `${p.id}`)
          .attr('idGroup', TRANSFERT_GUIDE)
          .attr('d', p.path)
          .attr('fill', 'transparent')
          .attr('stroke', p.color ? p.color : 'black')
          .attr('stroke-width', SVGEDITOR_TRACING_STROKEWIDTH);
      });
      if (!transfertGuide.lockChange) {
        g.on('mouseover', function (d) {
          d3.select(this).style('cursor', 'move').attr('fill', 'black');
        })
          .on('mouseout', function (d) {})
          .call(handler.on('start', dragstarted).on('drag', dragged).on('end', dragended))
          .raise();
      }
    }
    /** initialize state of svg */
    initEditSvgGroup(TRANCING_GROUP_ID);
  }, [currentTracings, transfertGuide]);
  // ----------------------------------------------------------------------
  const handleClickOnPath = (tracingGroupId) => {
    setForAllGroup('path', [{ key: 'fill', value: 'transparent' }]);
    boxTransformGroupRef.current.style.display = 'none';
    const isGroupEditing = d3.select(`#${tracingGroupId}`).attr('editing') === 'true';

    if (!isGroupEditing) {
      const groupColor = d3.select(`#${tracingGroupId}`).attr('groupColor')
        ? hexToRGB(d3.select(`#${tracingGroupId}`).attr('groupColor'), 0.2)
        : SUPER_COLOR__FILL;
      d3.select(`#${tracingGroupId}`)
        .attr('editing', true)
        .raise()
        .selectAll('path')
        .attr('fill', groupColor);
      boxTransformGroupRef.current.style.display = 'block';
      editTracingGroupRef.current = {
        globalGroup: TRANCING_GROUP_ID,
        idGroup: tracingGroupId,
        currentNoTransform: {
          centerPoint: getBoundingBoxCenter(d3.select(`#${tracingGroupId}`)),
          nodes: d3
            .select(`#${tracingGroupId}`)
            .selectAll('path')
            .nodes()
            .map((it) => {
              const pathGroup = d3.select(it);
              return { id: pathGroup.attr('id'), imgpath: pathGroup.attr('d') };
            })
        }
      };
    } else {
      d3.select(`#${tracingGroupId}`).attr('editing', null);
      editTracingGroupRef.current = {
        globalGroup: TRANCING_GROUP_ID,
        idGroup: tracingGroupId,
        currentNoTransform: {
          centerPoint: getBoundingBoxCenter(d3.select(`#${tracingGroupId}`))
        }
      };
    }
  };
  const setForAllGroup = (type, values) => {
    currentTracings.forEach((it) => {
      const { id } = it;
      const idGroup = `tracingGroup_${id}`;
      const group = d3.select(`#${idGroup}`);
      if (!group.empty()) {
        values.forEach((item) => {
          const { key, value } = item;
          if (type === 'path') {
            group.selectAll('path').attr(key, value);
          } else if (type === 'group') {
            group.attr(key, value);
          }
        });
      }
    });
  };
  // ----------------------------------------------------------------------
  /** drag functions */
  const dragstarted = (event, d) => {
    const { sourceEvent } = event;
    if (!sourceEvent) return;
    const { target } = sourceEvent;
    const movePoint = d3.select(target);
    const idGroup = movePoint.attr('idGroup');
    if (!idGroup) return;
  };
  const dragged = (event, d) => {
    const { x, y, dx, dy } = event;
    const { sourceEvent } = event;
    if (!sourceEvent) return;
    const { target } = sourceEvent;
    const movePoint = d3.select(target);

    if (!movePoint) return;
    const isTransfertGuide = movePoint.attr('idGroup') === TRANSFERT_GUIDE;
    if (isTransfertGuide) {
      //const idPath = movePoint.attr('idPath');
      // if (!idPath) return;
      const targetX = Number(movePoint.attr('x')) + Number(dx);
      const targetY = Number(movePoint.attr('y')) + Number(dy);
      movePoint.attr('x', targetX).attr('y', targetY);
      const translateValue = { x: Number(dx), y: Number(dy) };

      d3.select(`#${TRANSFERT_GUIDE}`)
        .selectAll('path')
        .nodes()
        .forEach((node) => {
          const nodePath = d3.select(node);
          const currentPath = nodePath.attr('d');
          /** imgPath, scale, rotate, centerPoint, translate */
          const encodePath = applyTranformToGroupToPath(currentPath, null, translateValue);
          nodePath.attr('d', encodePath);
        });

      return;
    }
    const idGroup = getGroupEditing(movePoint);
    if (!idGroup) return;
    const translateValue = { x: Number(dx), y: Number(dy) };
    d3.select(`#${idGroup}`)
      .selectAll('path')
      .nodes()
      .forEach((node) => {
        const nodePath = d3.select(node);
        const currentPath = nodePath.attr('d');
        /** imgPath, scale, rotate, centerPoint, translate */
        const encodePath = applyTranformToGroupToPath(currentPath, null, translateValue);
        nodePath.attr('d', encodePath);
      });
  };
  const dragended = (event, d) => {
    const { sourceEvent } = event;
    if (!sourceEvent) return;
    const { target } = sourceEvent;
    const movePoint = d3.select(target);
    if (!movePoint) return;
    const idGroup = getGroupEditing(movePoint);
    if (!idGroup) return;

    timer.current = setTimeout(() => {
      const isGroupEditingAgain = d3.select(`#${idGroup}`).attr('editing') === 'true';
      if (isGroupEditingAgain) {
        handleUpdateSvgGroup(TRANCING_GROUP_ID);
      }
    }, 500);
  };

  const getGroupEditing = (movePoint) => {
    const idGroup = movePoint.attr('idGroup');
    const g = d3.select(`#${idGroup}`);
    const isCurrentEditing = !g.empty() && g.attr('editing') === 'true' ? true : false;
    if (isCurrentEditing) {
      return idGroup;
    }

    for (let i = 0; i < currentTracings.length; i++) {
      const it = currentTracings[i];
      const { id } = it;
      const idGroup = `tracingGroup_${id}`;
      const g = d3.select(`#${idGroup}`);
      if (g && !g.empty() && g.attr('editing') === 'true') {
        return idGroup;
      }
    }

    return null;
  };
  //--------------------------------------------------------------------
  /* 
  validate xml
  */
  const handleValidate = async () => {
    if (disabledField) return;
    try {
      if (!svgRef || !svgRef.current) return;
      if (!editSuperposition) return;
      if (!transfertGuide) return;
      if (!transfertGuide.paths) {
        throw new Error('Veuillez placer le guide de transfert');
      }
      const svg = d3.select(svgRef.current);
      /** remove all circle or use */
      svg.selectAll('circle').remove();
      svg.selectAll('use').remove();
      svg
        .transition()
        .duration(TRANSITION_TIME)
        .selectAll('g')
        .attr('transform', 'translate(0,0) scale(1)');
      timer.current = setTimeout(async () => {
        try {
          const xmlData = xmlToBase64(toSvgData(document.getElementById('svg')));
          const pathTransfertGroup = d3.select(`#${TRANSFERT_GUIDE}`);
          let newTracings = [...currentTracings];
          let transfert = null;

          if (pathTransfertGroup && !pathTransfertGroup.empty()) {
            transfert = { ...transfertGuide, lockChange: true, updatedAt: null };
            const newPaths = transfert.paths.map((p) => {
              const newP = { ...p };
              const newPath = d3.select(`#${p.id}`).attr('d');
              return { ...newP, path: newPath };
            });
            transfert.paths = newPaths;
            newTracings = [...currentTracings].map((t) => {
              const newT = { ...t };
              const transferByPointTracing = transfert.paths.find((p) => {
                const splitStr = p.id.split('_');
                const idP = splitStr[splitStr.length - 1];
                return String(idP) === String(t.id);
              });
              return {
                ...newT,
                transfertGuide: transferByPointTracing ? { ...transferByPointTracing } : null
              };
            });
          }

          const newEditSuper = {
            ...editSuperposition,
            xmlData,
            tracings: [...newTracings],
            transfertGuide: transfert
          };
          onComplete(newEditSuper);
          handleClose();
        } catch (error) {
          notif(null, error);
        }
      }, Number(TRANSITION_TIME + 100));
    } catch (error) {
      console.error(error);
      notif(null, error);
    }
  };
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!svgRef || !svgRef.current) return;
    const svg = d3.select(svgRef.current);
    /* zoom handle */
    svg.call(
      zoomHandler
        .extent([
          [0, 0],
          [SVGEDITOR_WIDTH, SVGEDITOR_HEIGHT]
        ])
        .scaleExtent([1, 8])
        .on('zoom', zoomed)
    );
  }, [svgRef.current]);
  /* zoom function */
  const zoomed = ({ transform }) => {
    if (!svgRef || !svgRef.current) return;
    const g = d3.select('#globalGroup');
    g.attr('transform', transform);
  };
  useEffect(() => {
    if (!timer || !timer.current) return;
    // clear on component unmount
    return () => {
      clearTimeout(timer.current);
    };
  }, []);
  //--------------------------------------------------------------------
  const onSelectTransfertGuide = async () => {
    if (disabledField) return;
    if (!currentTracings) return;
    if (!transfertGuide) return;
    /** apply tranform to all path within group */
    const newTracings = currentTracings.map((trac) => {
      const { tracings } = trac;
      const newTrac = tracings.map((it) => {
        const idPath = `id_${trac.id}_${it.id}`;
        let contentPath = d3.select(`#${idPath}`).attr('d');
        let commands = [];
        if (contentPath) {
          contentPath = toRel(contentPath);
          commands = parseToCommands(contentPath);
        }
        return { ...it, imgpath: contentPath, commands: commands };
      });
      return { ...trac, tracings: newTrac };
    });
    setcurrentTracings(newTracings);
    /** there are as many transfertGuide as tracings within superposition => transfert guide become a group  */
    const arrTransfert = [];
    newTracings.forEach((t) => {
      const { distance, distancePixel } = t.radio;
      const path = drawTransfertPath(distance, distancePixel);
      arrTransfert.push({
        id: `${TRANSFERT_GUIDE_IDPATH}_${t.id}`,
        color: t.color,
        path,
        updatedAt: new Date()
      });
    });
    settransfertGuide({ paths: arrTransfert, updatedAt: new Date() });
  };

  //--------------------------------------------------------------------
  if (!editSuperposition) return <></>;
  //--------------------------------------------------------------------
  const disabledSave =
    disabledField ||
    (editSuperposition.transfertGuide &&
      editSuperposition.transfertGuide.paths &&
      editSuperposition.transfertGuide.lockChange)
      ? true
      : false;
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          ml: `${SVGEDITOR_TOOLBAR_WIDTH}px`,
          zIndex: 1300
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {`Edition de ${editSuperposition.label}`}
          </Typography>
          <SvgEditorCloseBtn onClose={handleClose} />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: SVGEDITOR_TOOLBAR_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: SVGEDITOR_TOOLBAR_WIDTH,
            boxSizing: 'border-box'
          }
        }}
        variant="permanent"
        anchor="left"
      >
        <DrawerHeader />
        <Divider />
        <List sx={{ mt: 2 }}>
          <ListItem disablePadding>
            <Button
              fullWidth
              disabled={disabledField}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                mx: 2.5
              }}
              onClick={() => handleValidate()}
              variant="contained"
              color="primary"
            >
              <DoneIcon /> Enregistrer
            </Button>
          </ListItem>
        </List>
        <Divider />
        <List sx={{ mt: 4 }}>
          {editSuperposition.code === SUPER_GENERALE && transfertGuide && (
            <AddTransfertGuide
              transfertGuide={transfertGuide}
              editSuperposition={editSuperposition}
              onSelectTransfertGuide={onSelectTransfertGuide}
            />
          )}
        </List>
      </Drawer>
      <TransformSuper
        svgRef={svgRef}
        editTracingGroupRef={editTracingGroupRef}
        boxTransformGroupRef={boxTransformGroupRef}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          px: 3,
          width: `100%`,
          height: `calc(100vh - (${toolbar?.minHeight}px + ${15}px))`,
          marginTop: `${toolbar?.minHeight + 10}px`,
          overflow: 'hidden'
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          id="svg"
          ref={svgRef}
          viewBox={`0 0 ${SVGEDITOR_WIDTH} ${SVGEDITOR_HEIGHT}`}
          style={{
            backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAHUlEQVQ4jWNgYGAQIYAJglEDhoUBg9+FowbQ2gAARjwKARjtnN8AAAAASUVORK5CYII=')`,
            height: `100%`,
            width: `100%`
          }}
          preserveAspectRatio="xMinYMin"
        >
          <defs>
            <g id="pointer" transform="scale(2.8)">
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                focusable="false"
                aria-hidden="true"
                data-testid="ControlCameraIcon"
                tabIndex="-1"
                title="ControlCamera"
              >
                <path d="M15.54 5.54 13.77 7.3 12 5.54 10.23 7.3 8.46 5.54 12 2zm2.92 10-1.76-1.77L18.46 12l-1.76-1.77 1.76-1.77L22 12zm-10 2.92 1.77-1.76L12 18.46l1.77-1.76 1.77 1.76L12 22zm-2.92-10 1.76 1.77L5.54 12l1.76 1.77-1.76 1.77L2 12z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
            </g>
          </defs>
          <g id="globalGroup" cursor="grab">
            <g id={TRANCING_GROUP_ID} />
          </g>
        </svg>
        <SvgToImgDownload svgEl="svg" ref={svgRef} addRight="80" />
      </Box>
    </Box>
  );
}
// ----------------------------------------------------------------------

const AddTransfertGuide = ({ transfertGuide, editSuperposition, onSelectTransfertGuide }) => {
  if (!editSuperposition) return <></>;
  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      {editSuperposition.transfertGuide ? (
        <>
          <ListItemButton
            sx={{
              justifyContent: 'initial',
              px: 2.5
            }}
            disabled
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 3,
                justifyContent: 'center'
              }}
            >
              <DoneIcon />
            </ListItemIcon>
            <ListItemText primary={`Placement du guide de transfert effectué`} />
          </ListItemButton>
        </>
      ) : (
        <ListItemButton
          sx={{
            justifyContent: 'initial',
            px: 2.5
          }}
          onClick={() => onSelectTransfertGuide()}
          disabled={transfertGuide.lookChange}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 3,
              justifyContent: 'center'
            }}
          >
            <PolylineIcon />
          </ListItemIcon>
          <ListItemText primary="Placement du guide de transfert" />
        </ListItemButton>
      )}
    </ListItem>
  );
};
