import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import { fDateTimeSuffix } from 'utils/formatTime';

import {
  API_URL,
  SVGEDITOR_WIDTH,
  SVGEDITOR_HEIGHT,
  SVGEDITOR_TOOLBAR_WIDTH
} from 'config/appConfig';
import TracingsSvgEditor from 'components/d3/TracingsSvgEditor';
import axios from 'axios';
import { blobToBase64 } from 'utils/imageUtil';
import useNotification from 'hooks/useNotification';
import useReferentiel from 'hooks/useReferentiel';

// ----------------------------------------------------------------------
export default function DialogTracingsSettings({ tracings, radio }) {
  const [img64, setimg64] = useState(null);
  const [open, setOpen] = React.useState(false);
  const { notif } = useNotification();
  const { saveTracingsPos, initRefs } = useReferentiel();
  useEffect(() => {
    if (!radio) return;
    if (!radio.imgFile) return;
    const init = async () => {
      try {
        const url = `${API_URL.file}/${radio.imgFile}`;
        const response = await axios.get(url, {
          responseType: 'arraybuffer'
        });
        const blob = new Blob([response.data], {
          type: response.headers['content-type']
        });
        // const imageDataUrl = URL.createObjectURL(blob);
        const data64 = await blobToBase64(blob);
        setimg64(data64);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [radio]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handeComplete = async (tracings) => {
    await saveTracingsPos(tracings);
    await initRefs();
    notif('Sauvegarde réussie');
  };
  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        <PictureInPictureAltIcon sx={{ ml: 2 }} />
        Modifier les tracés
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            ml: `${SVGEDITOR_TOOLBAR_WIDTH}px`,
            zIndex: 1300
          }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              Modifier les tracés
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {img64 && tracings && (
          <TracingsSvgEditor
            title="Modifier les tracés"
            handleClose={handleClose}
            width={SVGEDITOR_WIDTH}
            height={SVGEDITOR_HEIGHT}
            img64={img64}
            tracings={tracings}
            onComplete={handeComplete}
          />
        )}
      </Dialog>
    </>
  );
}
