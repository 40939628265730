import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import Page from 'components/Page';
import useReport from 'hooks/useReport';
import usePatient from 'hooks/usePatient';
import EditReportBtn from './EditReportBtn';
import ReportAnalyse from './ReportAnalyse';
import ReportFake from './ReportFake';
// ----------------------------------------------------------------------

export default function EditReportPage() {
  const { patient } = usePatient();
  const { getReportById } = useReport();
  const { idReport } = useParams();
  const [editReport, seteditReport] = useState(null);
  useEffect(() => {
    if (!idReport) return;
    if (!patient) return;
    const init = async () => {
      const res = await getReportById(idReport);
      if (res) {
        seteditReport(res);
      }
    };
    init();
  }, [idReport, patient]);

  if (!editReport) return <></>;
  return (
    <Page
      title="Edition des rapports | OCS - Orthodontie | Céphalométrie | Superposition"
      style={{ height: '100%' }}
    >
      <Container maxWidth="xl" style={{ height: '100%' }}>
        <Box sx={{ pb: 5 }} display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h4">Liste des rapports</Typography>
          </Box>
        </Box>
        {/* <ReportAnalyse editReport={editReport} /> */}
        {editReport && <ReportFake editReport={editReport} />}
      </Container>
    </Page>
  );
}
