import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Button, Checkbox, FormControlLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import useNotification from 'hooks/useNotification';
import useReferentiel from 'hooks/useReferentiel';
import ColorPicker from 'components/color/ColorPicker';
import { API_URL } from 'config/appConfig';
import SimpleUploadFile from 'components/file/SimpleUploadFile';
import ImgBoxFromUrl from 'components/media/ImgBoxFromUrl';
import faker from 'faker';
// ----------------------------------------------------------------------

export default function AverageValuesForm({ averageValue, onComplete }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleComplete = (values) => {
    onComplete(values);
    handleClose();
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-' + faker.datatype.uuid() : undefined;

  return (
    <>
      <Button onClick={handleClick}>
        <AddIcon /> Configurer les valeurs moyennes
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => console.log()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Paper sx={{ width: 520, maxWidth: '100%', px: 3 }}>
          <FormEdit averageValue={averageValue} onComplete={handleComplete} />
        </Paper>
      </Popover>
    </>
  );
}
const FormEdit = ({ averageValue, onComplete }) => {
  const arrGender = ['Monsieur', 'Madame'];
  const ageArr = Array.from({ length: 8 }, (_, i) => i + 9);
  useEffect(() => {}, [averageValue]);

  const getShapeSchema = () => {
    const obj = {};
    arrGender.forEach((gender) => {
      ageArr.forEach((it, i) => {
        obj[`${gender}_${it}`] = Yup.string();
      });
    });
    return obj;
  };
  const getInitialValues = () => {
    const obj = {};
    arrGender.forEach((gender) => {
      ageArr.forEach((it, i) => {
        let val = '';
        if (Array.isArray(averageValue) && averageValue.length > 0) {
          const avgValFounded = averageValue.find(
            (a) => a.sexe === gender && Number(a.age) === Number(it)
          );
          if (avgValFounded) {
            val = avgValFounded.value;
          }
        }
        obj[`${gender}_${it}`] = val;
      });
    });
    return obj;
  };
  const getFields = () => {
    try {
      const arr = [];
      arrGender.forEach((gender) => {
        ageArr.forEach((it, i) => {
          const fieldId = `${gender}_${it}`;
          const fieldLabel = `${gender === 'Monsieur' ? 'Garçon' : 'Fille'} ${it} ans`;
          arr.push({ fieldId, fieldLabel });
        });
      });
      return arr;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  const averagechema = Yup.object().shape({
    ...getShapeSchema()
  });
  const formik = useFormik({
    initialValues: {
      ...getInitialValues()
    },
    validationSchema: averagechema,
    onSubmit: async () => {
      try {
        const arr = [];
        Object.entries(values).forEach(([key, value]) => {
          const strSplit = key.split('_');
          arr.push({
            sexe: strSplit[0],
            age: strSplit[1],
            value
          });
        });
        console.log(values);
        onComplete(arr);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange
  } = formik;

  return (
    <>
      <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-end">
        <IconButton onClick={onComplete} sx={{ my: 2 }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ mb: 5 }}>
            {getFields().map((it, i) => {
              const { fieldId, fieldLabel } = it;
              return (
                <TextField
                  key={i}
                  fullWidth
                  type="text"
                  label={fieldLabel}
                  {...getFieldProps(fieldId)}
                  error={Boolean(touched[fieldId] && errors[fieldId])}
                  helperText={touched[fieldId] && errors[fieldId]}
                />
              );
            })}
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mb: 10 }}
          >
            Valider
          </LoadingButton>
          <Box sx={{ width: '100%', height: '60px' }} />
        </Form>
      </FormikProvider>
    </>
  );
};
