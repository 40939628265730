import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Page from 'components/Page';
import usePatient from 'hooks/usePatient';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton, Typography } from '@mui/material';
import { PATH_APP } from 'config/paths';
import PatientInfoPage from './PatientInfoPage';
// ----------------------------------------------------------------------
export default function AddPatient() {
  const { patient, resetPatientForm } = usePatient();
  useEffect(() => {
    resetPatientForm();
  }, []);

  return (
    <Page title="Nouveau patient | OCS - Orthodontie | Céphalométrie | Superposition">
      {!patient && (
        <>
          <>
            <Box sx={{ pb: 5 }} display="flex" alignItems="center">
              <IconButton
                to={PATH_APP.patients}
                variant="contained"
                color="primary"
                component={RouterLink}
                sx={{ mr: 2 }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h4"> Nouveau patient </Typography>
            </Box>
          </>

          <PatientInfoPage />
        </>
      )}
    </Page>
  );
}
