import { useDispatch, useSelector } from 'react-redux';
import { startLoading, setInitialize } from 'redux/slices/refSlice';
import axios from 'axios';
import { API_URL } from 'config/appConfig';

// ----------------------------------------------------------------------

export default function useReferentiel() {
  const dispatch = useDispatch();
  const { refs } = useSelector((state) => state.ref);

  return {
    refs,
    initRefs: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.refs + '/all');
        if (res && res.data) {
          const refs = res.data;
          dispatch(setInitialize({ refs }));
        } else {
          throw new Error('Référentiels ne sont pas disponibles');
        }
      } catch (error) {
        console.error(error);
        throw new Error('Référentiels ne sont pas disponibles');
      }
    },
    saveRadio: async (o) => {
      try {
        if (!o || !o.id) return;
        await axios.patch(API_URL.refs + '/radio/' + o.id, o);
      } catch (error) {
        console.error();
        throw error;
      }
    },
    saveRadioFormData: async (o, radioFormData) => {
      try {
        if (o && o.id) {
          await axios.post(API_URL.refs + '/radio/' + o.id, radioFormData);
        } else {
          await axios.post(API_URL.refs + '/radio/', radioFormData);
        }
      } catch (error) {
        console.error();
        throw error;
      }
    },
    savePointsPos: async (points) => {
      try {
        if (!Array.isArray(points) || points.length === 0) return;
        await axios.post(API_URL.refs + '/points-pos/', points);
      } catch (error) {
        console.error();
        throw error;
      }
    },

    savePoint: async (point) => {
      try {
        if (!point || !point.id) return;
        await axios.patch(API_URL.refs + '/points/' + point.id, point);
      } catch (error) {
        console.error();
        throw error;
      }
    },
    saveLine: async (line) => {
      try {
        if (!line) return;
        if (line.id) {
          await axios.patch(API_URL.refs + '/lines/' + line.id, line);
        } else {
          await axios.post(API_URL.refs + '/lines', line);
        }
      } catch (error) {
        console.error();
        throw error;
      }
    },
    saveAngleFormdata: async (angle, formdata) => {
      try {
        if (!angle || angle.id) {
          await axios.patch(API_URL.refs + '/angles-formdata/' + angle.id, formdata);
        } else {
          await axios.post(API_URL.refs + '/angles-formdata', formdata);
        }
      } catch (error) {
        console.error();
        throw error;
      }
    },
    saveAngle: async (angle) => {
      try {
        if (!angle || !angle.id) return;
        await axios.patch(API_URL.refs + '/angles/' + angle.id, angle);
      } catch (error) {
        console.error();
        throw error;
      }
    },
    saveDistanceFormdata: async (distance, formdata) => {
      try {
        if (!distance || distance.id) {
          await axios.patch(API_URL.refs + '/distances-formdata/' + distance.id, formdata);
        } else {
          await axios.post(API_URL.refs + '/distances-formdata', formdata);
        }
      } catch (error) {
        console.error();
        throw error;
      }
    },
    saveDistance: async (distance) => {
      try {
        if (!distance || !distance.id) return;
        await axios.patch(API_URL.refs + '/distances/' + distance.id, distance);
      } catch (error) {
        console.error();
        throw error;
      }
    },
    saveTracingsPos: async (tracings) => {
      try {
        if (!Array.isArray(tracings) || tracings.length === 0) return;
        await axios.post(API_URL.refs + '/tracings-pos/', tracings);
      } catch (error) {
        console.error();
        throw error;
      }
    },
    saveTracing: async (tracing) => {
      try {
        if (!tracing) return;
        if (tracing.id) {
          await axios.patch(API_URL.refs + '/tracings/' + tracing.id, tracing);
        } else {
          await axios.post(API_URL.refs + '/tracings', tracing);
        }
      } catch (error) {
        console.error();
        throw error;
      }
    },
    saveSuperPos: async (supers) => {
      try {
        if (!Array.isArray(supers) || supers.length === 0) return;
        await axios.post(API_URL.refs + '/superpositions-pos/', supers);
      } catch (error) {
        console.error();
        throw error;
      }
    },
    saveSuper: async (superpo) => {
      try {
        if (!superpo) return;
        if (superpo.id) {
          await axios.patch(API_URL.refs + '/superpositions/' + superpo.id, superpo);
        }
      } catch (error) {
        console.error();
        throw error;
      }
    },
    resetSuper: async (superpo) => {
      try {
        if (!superpo) return;
        if (superpo.id) {
          await axios.get(API_URL.refs + '/superpositions/reset/' + superpo.id);
        }
      } catch (error) {
        console.error();
        throw error;
      }
    },
    saveReportSection: async (reportSection) => {
      try {
        if (!reportSection) return;
        if (reportSection.id) {
          await axios.patch(API_URL.refs + '/reportSection/' + reportSection.id, reportSection);
        } else {
          await axios.post(API_URL.refs + '/reportSection', reportSection);
        }
      } catch (error) {
        console.error();
        throw error;
      }
    },
    deleteReportSection: async (id) => {
      try {
        if (!id) return;
        await axios.delete(API_URL.refs + '/reportSection/' + id);
      } catch (error) {
        console.error();
        throw error;
      }
    }
  };
}
