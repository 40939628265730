import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';

// ----------------------------------------------------------------------
export default function ImgBoxFromUrl({ image, sx }) {
  const [imageObj, setimageObj] = useState(null);
  useEffect(() => {
    return () => {
      setimageObj(null);
    };
  }, []);
  useEffect(() => {
    if (!image) return;
    const init = async () => {
      try {
        const response = await axios.get(`${image}`, {
          responseType: 'arraybuffer'
        });
        const blob = new Blob([response.data], {
          type: response.headers['content-type']
        });
        const imageDataUrl = URL.createObjectURL(blob);
        setimageObj(imageDataUrl);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [image]);
  if (!imageObj) return <></>;
  return (
    <>
      <Box component="img" alt="" src={imageObj} sx={{ ...sx }} />
    </>
  );
}
