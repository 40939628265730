import React, { useEffect, useState } from 'react';
import CropImg from 'components/crop/CropImg';
import usePointTracing from 'hooks/usePointTracing';
import useReferentiel from 'hooks/useReferentiel';
import { Box, Button } from '@mui/material';
import usePatient from 'hooks/usePatient';
import RadioEditor from './RadioEditor';

// ----------------------------------------------------------------------
export default function EditRadioStep({ editPoint }) {
  const [changeMode, setchangeMode] = useState(false);
  const [editRadio, seteditRadio] = useState(null);
  const { pointtracings, getRadioByPointTracing, uploadRadio } = usePointTracing();
  const { refs } = useReferentiel();
  const { patient } = usePatient();
  useEffect(() => {
    if (!patient) return;
    if (!editPoint || !editPoint.id) return;
    initRadio();
  }, [editPoint, patient]);

  const initRadio = async () => {
    try {
      const res = await getRadioByPointTracing(editPoint.id);
      if (res) {
        seteditRadio(res);
      } else {
        seteditRadio(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!pointtracings) return <></>;
  if (!refs) return <></>;
  if (!refs.refStates) return <></>;
  if (!editPoint) return <></>;

  const onValidBlobCrop = async (originImg, imgBlob) => {
    try {
      if (!patient) return;
      if (!editPoint || !editPoint.id) return;
      const refStateDebut = refs.refStates.find((it) => it.code === 2); //state début
      if (!refStateDebut) return;
      const formData = new FormData();
      formData.append('originImage', originImg);
      formData.append('image', imgBlob, originImg.name);
      formData.append('pointtracing', editPoint.id);
      formData.append('patient', patient.id);
      await uploadRadio(null, formData);
      await initRadio();
      setchangeMode(false);
    } catch (error) {
      console.error(error);
    }
  };
  const disabledField = patient && patient.id && patient.lockOff;
  return (
    <>
      {editRadio ? (
        <>
          {changeMode ? (
            <CropImg
              onValidBlobCrop={onValidBlobCrop}
              editRadio={editRadio}
              onCancel={() => setchangeMode(false)}
            />
          ) : (
            <Box display="flex" flexDirection="column">
              <Box flexGrow="1">
                <RadioEditor editRadio={editRadio} editPoint={editPoint} />
              </Box>
              <Button
                disabled={disabledField}
                sx={{ my: 3 }}
                color="error"
                variant="contained"
                onClick={() => setchangeMode(true)}
              >
                Modifier
              </Button>
            </Box>
          )}
        </>
      ) : (
        <CropImg onValidBlobCrop={onValidBlobCrop} />
      )}
    </>
  );
}
