// ----------------------------------------------------------------------
export const COMMANDS_TYPE = {
  CLOSE_PATH: 1,
  MOVE_TO: 2,
  HORIZ_LINE_TO: 4,
  VERT_LINE_TO: 8,
  LINE_TO: 16,
  CURVE_TO: 32,
  SMOOTH_CURVE_TO: 64,
  QUAD_TO: 128,
  SMOOTH_QUAD_TO: 256,
  ARC: 512
};
export const LABELS = [
  {
    type: COMMANDS_TYPE.CLOSE_PATH,
    label: 'CLOSE_PATH'
  },
  {
    type: COMMANDS_TYPE.MOVE_TO,
    label: 'MOVE_TO'
  },
  {
    type: COMMANDS_TYPE.HORIZ_LINE_TO,
    label: 'HORIZ_LINE_TO'
  },
  {
    type: COMMANDS_TYPE.VERT_LINE_TO,
    label: 'VERT_LINE_TO'
  },
  {
    type: COMMANDS_TYPE.LINE_TO,
    label: 'LINE_TO'
  },
  {
    type: COMMANDS_TYPE.CURVE_TO,
    label: 'CURVE_TO'
  },
  {
    type: COMMANDS_TYPE.SMOOTH_CURVE_TO,
    label: 'SMOOTH_CURVE_TO'
  },
  {
    type: COMMANDS_TYPE.QUAD_TO,
    label: 'QUAD_TO'
  },
  {
    type: COMMANDS_TYPE.SMOOTH_QUAD_TO,
    label: 'SMOOTH_QUAD_TO'
  },
  {
    type: COMMANDS_TYPE.ARC,
    label: 'ARC'
  }
];
