import React from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify-icons/eva/close-fill';
import { MIconButton } from 'theme/@material-extend/Buttons';
// material
import { Stack, TextField, MenuItem } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { LoadingButton } from '@mui/lab';
import 'dayjs/locale/fr';
import usePatient from 'hooks/usePatient';
import { PATH_APP } from 'config/paths';
// ----------------------------------------------------------------------
const CIVIL = ['Madame', 'Monsieur'];
const LOCALE_DEF = 'fr';
export default function FormPatient({ ...prorps }) {
  const navigate = useNavigate();
  const { patient, savePatient } = usePatient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const formSchema = Yup.object().shape({
    firstname: Yup.string().required('Veuillez renseigner le prénom'),
    lastname: Yup.string().required('Veuillez renseigner le nom'),
    sexe: Yup.string().required('Veuillez renseigner la civilité'),
    birth: Yup.string().required('Veuillez renseigner la date de naissance'),
    email: Yup.string().email(`Email n'est pas valide`),
    phone: Yup.string(),
    adress: Yup.string(),
    postalcode: Yup.string(),
    city: Yup.string()
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: patient && patient.firstname ? patient.firstname : '',
      lastname: patient && patient.lastname ? patient.lastname : '',
      sexe: patient && patient.sexe ? patient.sexe : '',
      birth: patient && patient.birth ? patient.birth : '',
      email: patient && patient.email ? patient.email : '',
      phone: patient && patient.phone ? patient.phone : '',
      adress: patient && patient.adress ? patient.adress : '',
      postalcode: patient && patient.postalcode ? patient.postalcode : '',
      city: patient && patient.city ? patient.city : ''
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      try {
        const data = await savePatient(values);
        enqueueSnackbar('Sauvegarde réussie', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (data) {
          navigate(`${PATH_APP.editPatient}/${data.id}`);
        }
      } catch (error) {
        console.error(error);
        let msg = '';
        if (error.response) {
          msg = error.response.data.message;
        } else {
          msg = 'Network error';
        }
        enqueueSnackbar(msg, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setFieldValue,
    handleSubmit,
    getFieldProps,
    handleChange
  } = formik;
  const disabledField = patient && patient.id && patient.lockOff;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={LOCALE_DEF}>
          <Stack spacing={3}>
            <TextField
              disabled={disabledField}
              fullWidth
              type="text"
              label="Nom"
              {...getFieldProps('lastname')}
              error={Boolean(touched.lastname && errors.lastname)}
              helperText={touched.lastname && errors.lastname}
            />
            <TextField
              disabled={disabledField}
              fullWidth
              type="text"
              label="Prénom"
              {...getFieldProps('firstname')}
              error={Boolean(touched.firstname && errors.firstname)}
              helperText={touched.firstname && errors.firstname}
            />

            <TextField
              disabled={disabledField}
              select
              label="Civilité"
              value={values.sexe}
              onChange={handleChange('sexe')}
              {...getFieldProps('sexe')}
              error={Boolean(touched.sexe && errors.sexe)}
              helperText={touched.sexe && errors.sexe}
            >
              {CIVIL.map((option, i) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <DesktopDatePicker
              onChange={(value) => setFieldValue('birth', value, true)}
              value={values.birth}
              renderInput={(params) => (
                <TextField
                  disabled={disabledField}
                  error={Boolean(touched.birth && errors.birth)}
                  helperText={touched.birth && errors.birth}
                  label="Date de naissance"
                  margin="normal"
                  name="birth"
                  variant="standard"
                  fullWidth
                  {...params}
                />
              )}
            />

            <TextField
              disabled={disabledField}
              fullWidth
              type="email"
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              disabled={disabledField}
              fullWidth
              type="text"
              label="Téléphone"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
            <TextField
              disabled={disabledField}
              fullWidth
              type="text"
              label="Adresse"
              {...getFieldProps('adress')}
              error={Boolean(touched.adress && errors.adress)}
              helperText={touched.adress && errors.adress}
            />
            <TextField
              disabled={disabledField}
              fullWidth
              type="text"
              label="Code postal"
              {...getFieldProps('postalcode')}
              error={Boolean(touched.postalcode && errors.postalcode)}
              helperText={touched.postalcode && errors.postalcode}
            />
            <TextField
              disabled={disabledField}
              fullWidth
              type="text"
              label="Ville"
              {...getFieldProps('city')}
              error={Boolean(touched.city && errors.city)}
              helperText={touched.city && errors.city}
            />
            <LoadingButton
              disabled={disabledField}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Valider
            </LoadingButton>
          </Stack>
        </LocalizationProvider>
      </Form>
    </FormikProvider>
  );
}
