import React, { useState, useEffect } from 'react';
import { SVGEDITOR_WIDTH, SVGEDITOR_HEIGHT, API_URL } from 'config/appConfig';
import axios from 'axios';
import useReferentiel from 'hooks/useReferentiel';
import { blobToBase64 } from 'utils/imageUtil';
import usePointTracing from 'hooks/usePointTracing';
import useNotification from 'hooks/useNotification';
import PointStepSvgEditor from './PointStepSvgEditor';
// ----------------------------------------------------------------------
export default function DialogPointStepEditor({
  editPoint,
  editPointStep,
  editRadio,
  handleClose,
  onComplete
}) {
  const [indicatorData, setindicatorData] = useState({ angles: null, distances: null });
  const [svgPoints, setsvgPoints] = useState(null);
  const [displayText, setdisplayText] = useState(true);
  const [displayLine, setdisplayLine] = useState(false);
  const [img64, setimg64] = useState(null);
  const { refs } = useReferentiel();
  const { notif } = useNotification();
  const { savePointStep } = usePointTracing();
  //--------------------------------------------------------------------
  useEffect(() => {
    try {
      if (!editPoint) return;
      let arr = [];
      if (editPointStep && Array.isArray(editPointStep.points) && editPointStep.points.length > 0) {
        arr = [...editPointStep.points];
        refs.refPoints
          .filter((it) => Number(it.code) > 2)
          .forEach((it) => {
            const existOnArr = arr.find((p) => Number(p.code) === Number(it.code));
            if (!existOnArr) {
              arr.push(it);
            }
          });
      } else if (refs && refs.refPoints) {
        /* for testing and to be removed filter */
        arr = refs.refPoints.filter((it) => Number(it.code) > 2);
      }
      setsvgPoints(arr);
      if (editPoint.indicatorData) {
        setindicatorData(editPoint.indicatorData);
      }
    } catch (error) {
      console.error(error);
      setsvgPoints(null);
    }
  }, [editPoint]);

  //--------------------------------------------------------------------
  useEffect(() => {
    if (!editRadio) return;
    if (!editRadio.imgFile) return;
    const init = async () => {
      try {
        const url = `${API_URL.file}/${editRadio.imgFile}`;
        const response = await axios.get(url, {
          responseType: 'arraybuffer'
        });
        const blob = new Blob([response.data], {
          type: response.headers['content-type']
        });
        // const imageDataUrl = URL.createObjectURL(blob);
        const data64 = await blobToBase64(blob);
        setimg64(data64);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [editRadio]);
  //--------------------------------------------------------------------

  const updatePoints = (newPoints) => {
    try {
      if (!newPoints) return;
      setsvgPoints(newPoints);
    } catch (error) {
      console.error(error);
    }
  };
  const handleCompletePoints = async (newPoints, xmlData) => {
    try {
      if (!editPoint) return;
      const obj = {
        ...editPointStep,
        pointtracing: editPoint.id,
        points: newPoints,
        xmlData,
        indicatorData
      };
      await savePointStep(obj);
      onComplete();
      notif('Sauvegarde réussie');
    } catch (error) {
      notif(error);
    }
  };
  const updateIndicatorData = (data) => {
    try {
      if (!editPoint) return;
      if (!data) return;
      setindicatorData(data);
    } catch (error) {
      notif(error);
    }
  };

  return (
    <>
      {img64 && svgPoints && (
        <PointStepSvgEditor
          handleClose={handleClose}
          width={SVGEDITOR_WIDTH}
          height={SVGEDITOR_HEIGHT}
          img64={img64}
          points={svgPoints}
          editPoint={editPoint}
          editPointStep={editPointStep}
          editRadio={editRadio}
          onComplete={handleCompletePoints}
          updateIndicatorData={updateIndicatorData}
          displayText={displayText}
          onChangeDisplayText={(display) => setdisplayText(display)}
          updatePoints={(newPoints) => updatePoints(newPoints)}
          displayLine={displayLine}
          onChangeDisplayLine={(display) => setdisplayLine(display)}
        />
      )}
    </>
  );
}
