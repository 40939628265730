import React, { useState, useRef } from 'react';
import { Box, Stack, Button, IconButton } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import AutoFixOffIcon from '@mui/icons-material/AutoFixOff';
import UploadSingleFile from 'components/file/UploadSingleFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import { SVGEDITOR_WIDTH, SVGEDITOR_HEIGHT, CROP_ASPECT } from 'config/appConfig';
import { canvasPreview, getCroppedImg } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';

import 'react-image-crop/dist/ReactCrop.css';
// ----------------------------------------------------------------------

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
export default function CropImg({ onValidBlobCrop, editRadio, onCancel }) {
  const [imgSrc, setImgSrc] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1 / Number(SVGEDITOR_HEIGHT / SVGEDITOR_WIDTH));
  function onSelectFile(e) {
    if (e) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgSrc({
          src: reader.result.toString() || '',
          file: e
        });
      });
      reader.readAsDataURL(e);
    }
  }
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const handleValid = async () => {
    const croppedImg = await canvasPreview(
      imgRef.current,
      previewCanvasRef.current,
      completedCrop,
      scale,
      rotate
    );
    onValidBlobCrop(imgSrc.file, croppedImg);
  };
  const handleClear = () => {
    setImgSrc(null);
  };
  const handleChangeEnableRatio = () => {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setAspect(CROP_ASPECT);
      setCrop(centerAspectCrop(width, height, CROP_ASPECT));
    }
  };
  return (
    <div className="App">
      {editRadio && (
        <Button sx={{ my: 2 }} onClick={onCancel} color="secondary">
          <ArrowBackIcon sx={{ mr: 2 }} /> Annuler la modification et retourner
        </Button>
      )}
      {imgSrc ? (
        <>
          <Box display="flex" justifyContent="space-between">
            <Box sx={{ flexGrow: 1 }}>
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc.src}
                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </Box>

            <Stack direction="column" spacing={2}>
              <IconButton color="primary" onClick={handleValid}>
                <DoneIcon />
              </IconButton>
              <IconButton onClick={handleClear}>
                <ClearIcon />
              </IconButton>
              <IconButton onClick={handleChangeEnableRatio} color={aspect ? 'error' : 'secondary'}>
                <AutoFixOffIcon />
              </IconButton>
            </Stack>
          </Box>
        </>
      ) : (
        <div className="Crop-Controls">
          {/* <input type="file" accept="image/*" onChange={onSelectFile} /> */}
          <UploadSingleFile
            onChange={(e) => onSelectFile(e)}
            caption="(Les formats *.jpeg and *.png sont acceptés)"
            sx={{ maxHeight: '150px' }}
          />
        </div>
      )}
      <div>
        {Boolean(completedCrop) && (
          <canvas
            ref={previewCanvasRef}
            style={{
              display: 'none',
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height
            }}
          />
        )}
      </div>
    </div>
  );
}
