import React, { useState, useEffect } from 'react';
import __ from 'components/fabric/utils/translation';
import saveInBrowser from 'components/fabric/utils/saveInBrowser';
import { undo, redo } from 'components/fabric/utils/undoRedo';
import { editorTips } from 'components/fabric/utils/editorTips';
import { handleDrawingModes } from 'components/fabric/utils/handleDrawingModes';
import { applyZoom, zoomWithKeys, zoomWithMouse, zoomOptions } from 'components/fabric/utils/zoom';
import { SVGEDITOR_WIDTH, SVGEDITOR_HEIGHT, API_URL } from 'config/appConfig';
import axios from 'axios';
import useReferentiel from 'hooks/useReferentiel';
import { blobToBase64 } from 'utils/imageUtil';
import RadioSvgEditor from './RadioSvgEditor';
// ----------------------------------------------------------------------
export default function DialogRadioEditor({ editPoint, editRadio, handleClose }) {
  const [img64, setimg64] = useState(null);
  // states
  const { refs } = useReferentiel();

  useEffect(() => {
    if (!editRadio) return;
    if (!editRadio.imgFile) return;
    const init = async () => {
      try {
        const url = `${API_URL.file}/${editRadio.imgFile}`;
        const response = await axios.get(url, {
          responseType: 'arraybuffer'
        });
        const blob = new Blob([response.data], {
          type: response.headers['content-type']
        });
        // const imageDataUrl = URL.createObjectURL(blob);
        const data64 = await blobToBase64(blob);
        setimg64(data64);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [editRadio]);
  //--------------------------------------------------------------------

  const points = () => {
    try {
      if (!editRadio) return;
      let arr = [];
      const { firstpoint, secondpoint, distance } = editRadio;
      if (firstpoint && secondpoint && distance) {
        arr.push(firstpoint);
        arr.push(secondpoint);
      } else if (refs && refs.refPoints) {
        arr = refs.refPoints.filter((it) => Number(it.code) < 3);
      }
      return arr;
    } catch (error) {
      return [];
    }
  };
  return (
    <>
      {img64 && (
        <RadioSvgEditor
          handleClose={handleClose}
          width={SVGEDITOR_WIDTH}
          height={SVGEDITOR_HEIGHT}
          img64={img64}
          points={points()}
          editRadio={editRadio}
          editPoint={editPoint}
        />
      )}
    </>
  );
}
