import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from 'config/appConfig';
import { startLoading, stopLoading, setError, setSuperpositions } from 'redux/slices/patientSlice';

// ----------------------------------------------------------------------
export default function useSuperposition() {
  const dispatch = useDispatch();
  const { isError, isLoading, patient, superpositions } = useSelector((state) => state.patient);
  return {
    isLoading,
    isError,
    superpositions,
    initSuperposition: async () => {
      try {
        if (!patient || !patient.id) return;
        dispatch(startLoading());
        const res = await axios.get(`${API_URL.superposition}/${patient.id}`);
        if (res && res.data) {
          dispatch(setSuperpositions({ superpositions: res.data }));
        }
        dispatch(stopLoading());
      } catch (error) {
        console.log(error);
        dispatch(stopLoading());
        throw error;
      }
    },
    initSuperFromPointTracing: async () => {
      try {
        if (!patient || !patient.id) return;
        const res = await axios.get(`${API_URL.superposition}/pointtracings/${patient.id}`);
        if (res && res.data) {
          return res.data;
        }
        return null;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    saveSuperposition: async (values) => {
      try {
        if (!patient) return;
        if (!values) return;
        dispatch(startLoading());
        if (values.id) {
          await axios.patch(`${API_URL.superposition}/${patient.id}`, values);
        } else {
          await axios.post(`${API_URL.superposition}/${patient.id}`, values);
        }

        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw error;
      }
    },
    combineSuperpositions: async (values) => {
      try {
        if (!patient) return;
        if (!values) return;
        dispatch(startLoading());
        await axios.post(`${API_URL.superposition}/combine`, values);

        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw error;
      }
    },
    activateSuperReport: async ( values) => {
      try {
        if (!patient) return;
        if (!values) return;
        dispatch(startLoading());
        await axios.post(`${API_URL.superposition}/activate`, values);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw error;
      }
    },
    deleteSuperposition: async (idSuper) => {
      try {
        if (!patient) return;
        if (!idSuper) return;
        dispatch(startLoading());
        await axios.delete(`${API_URL.superposition}/${patient.id}/${idSuper}`);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw error;
      }
    }
  };
}
