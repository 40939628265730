import React, { useState, useEffect } from 'react';
import { SVGEDITOR_WIDTH, SVGEDITOR_HEIGHT, API_URL } from 'config/appConfig';
import axios from 'axios';
import useReferentiel from 'hooks/useReferentiel';
import { blobToBase64 } from 'utils/imageUtil';
import TracingsSvgEditor from 'components/d3/TracingsSvgEditor';
import usePointTracing from 'hooks/usePointTracing';
import useNotification from 'hooks/useNotification';
// ----------------------------------------------------------------------
export default function DialogTracingStepEditor({
  editPoint,
  editTracingStep,
  editPointStep,
  editRadio,
  handleClose,
  onComplete
}) {
  const [svgTracings, setsvgTracings] = useState(null);
  const [svgPoints, setsvgPoints] = useState(null);
  const [img64, setimg64] = useState(null);
  const { refs } = useReferentiel();
  const { resetTracingStep, saveTracingStep } = usePointTracing();
  const { notif } = useNotification();
  //--------------------------------------------------------------------
  useEffect(() => {
    if (!editRadio) return;
    if (!editRadio.imgFile) return;
    const init = async () => {
      try {
        const url = `${API_URL.file}/${editRadio.imgFile}`;
        const response = await axios.get(url, {
          responseType: 'arraybuffer'
        });
        const blob = new Blob([response.data], {
          type: response.headers['content-type']
        });
        // const imageDataUrl = URL.createObjectURL(blob);
        const data64 = await blobToBase64(blob);
        setimg64(data64);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [editRadio]);
  //--------------------------------------------------------------------
  useEffect(() => {
    try {
      if (!editPoint) return;
      if (!editPointStep) return;
      if (!Array.isArray(editPointStep.points) || editPointStep.points.length === 0) return;
      setsvgPoints(editPointStep.points);
    } catch (error) {
      console.error(error);
      setsvgPoints(null);
    }
  }, [editPoint]);
  //--------------------------------------------------------------------
  useEffect(() => {
    try {
      if (!editPoint) return;
      let arr = [];
      if (
        editTracingStep &&
        Array.isArray(editTracingStep.tracings) &&
        editTracingStep.tracings.length > 0
      ) {
        arr = [...editTracingStep.tracings];
        refs.refTracings.forEach((it) => {
          const existOnArr = arr.find((p) => Number(p.code) === Number(it.code));
          if (!existOnArr) {
            arr.push(it);
          }
        });
      } else if (refs && refs.refTracings) {
        /* for testing and to be removed filter */
        arr = refs.refTracings.filter((it) => Number(it.code) > -1);
      }
      setsvgTracings(arr);
    } catch (error) {
      console.error(error);
      setsvgTracings(null);
    }
  }, [editPoint, editTracingStep]);
  //--------------------------------------------------------------------
  const handleCompleteTracing = async (newTracings, xmlData) => {
    try {
      if (!editPoint) return;
      const existTracings =
        editTracingStep && Array.isArray(editTracingStep.tracings) ? editTracingStep.tracings : [];
      const stepTracings = newTracings.map((it) => {
        const { code, imgpath, commands } = it;
        let newImgpath = imgpath;
        let newCommands = [...commands];
        let newIt = { ...it, imgpath: newImgpath, commands: [...newCommands] };
        const tracingFounded = existTracings.find((t) => Number(t.code) === Number(code));
        if (tracingFounded) {
          newIt = { ...tracingFounded, imgpath: newImgpath, commands: [...newCommands] };
        }
        return { ...newIt };
      });
      const obj = {
        ...editTracingStep,
        pointtracing: editPoint.id,
        tracings: stepTracings,
        xmlData
      };
      await saveTracingStep(obj);
      onComplete();
      notif('Sauvegarde réussie');
    } catch (error) {
      notif(error);
    }
  };
  //--------------------------------------------------------------------
  const onDeleteTracing = async () => {
    try {
      if (!editPoint) return;
      if (!editTracingStep) return;
      await resetTracingStep(editTracingStep);
      onComplete();
      notif('Réinitialisation réussie');

      /* const arr = refs.refTracings.filter((it) => Number(it.code) > -1);
      setsvgTracings(arr); */
    } catch (error) {
      notif(error);
    }
  };

  //--------------------------------------------------------------------

  return (
    <>
      {img64 && svgPoints && svgTracings && (
        <TracingsSvgEditor
          editPoint={editPoint}
          title="Modifier les tracés"
          handleClose={handleClose}
          width={SVGEDITOR_WIDTH}
          height={SVGEDITOR_HEIGHT}
          img64={img64}
          points={svgPoints}
          tracings={svgTracings}
          onComplete={handleCompleteTracing}
          editTracingStep={editTracingStep}
          onDeleteTracing={onDeleteTracing}
        />
      )}
    </>
  );
}
