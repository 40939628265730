import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from 'config/appConfig';
import { startLoading, stopLoading, setError, setReports } from 'redux/slices/patientSlice';
import useNotification from 'hooks/useNotification';

// ----------------------------------------------------------------------
export default function useReport() {
  const { notif } = useNotification();

  const dispatch = useDispatch();
  const { isError, isLoading, patient, reports } = useSelector((state) => state.patient);
  return {
    isLoading,
    isError,
    reports,
    initReports: async () => {
      try {
        if (!patient || !patient.id) return;
        dispatch(startLoading());
        const res = await axios.get(`${API_URL.report}/${patient.id}`);
        if (res && res.data) {
          dispatch(setReports({ reports: res.data }));
        }
        dispatch(stopLoading());
      } catch (error) {
        console.log(error);
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    getReportById: async (id) => {
      try {
        if (!patient || !patient.id) return;
        dispatch(startLoading());
        const res = await axios.get(`${API_URL.report}/${patient.id}/${id}`);
        dispatch(stopLoading());
        return res.data;
      } catch (error) {
        console.log(error);
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    saveReport: async (values) => {
      try {
        if (!patient) return;
        if (!values) return;
        dispatch(startLoading());
        const { id } = values;
        if (id) {
          await axios.patch(`${API_URL.report}/${patient.id}`, values);
        } else {
          await axios.post(`${API_URL.report}/${patient.id}`, values);
        }
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());

        notif(null, error);
        throw error;
      }
    },
    deleteReport: async (id) => {
      try {
        if (!patient || !patient.id) return;
        if (!id) return;
        dispatch(startLoading());
        await axios.delete(`${API_URL.report}/${patient.id}/${id}`);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    getPdfFromReportData: async (reportId, reportDataId, name) => {
      try {
        if (!patient || !patient.id) return;
        if (!reportId) return;
        if (!reportDataId) return;
        const res = await axios.get(`${API_URL.report}/${patient.id}/${reportId}/${reportDataId}`, {
          responseType: 'blob'
        });
        if (res && res.data) {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(res.data);
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        notif(null, error);
      }
    }
  };
}
