import React, { useRef, useEffect, useState } from 'react';
import usePointTracing from 'hooks/usePointTracing';
import useReferentiel from 'hooks/useReferentiel';
import usePatient from 'hooks/usePatient';
import { Box, Card, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';

import ImgFromSvg from 'components/media/ImgFromSvg';
import { fDateTimeSuffix } from 'utils/formatTime';
import DialogPointStepEditor from './DialogPointStepEditor';
import LoadPointsBtn from './LoadPointsBtn';
// ----------------------------------------------------------------------
export default function EditPointsStep({ editPoint }) {
  const [editRadio, seteditRadio] = useState(null);
  const [editPointStep, seteditPointStep] = useState(null);
  const { pointtracings, getPointStepByPointTracing, getRadioByPointTracing } = usePointTracing();
  const { refs } = useReferentiel();
  const { patient } = usePatient();

  useEffect(() => {
    if (!patient) return;
    if (!editPoint || !editPoint.id) return;
    initPointStep();
    initRadio();
  }, [editPoint, patient]);

  const initPointStep = async () => {
    try {
      const res = await getPointStepByPointTracing(editPoint.id);
      if (res) {
        seteditPointStep(res);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const initRadio = async () => {
    try {
      const res = await getRadioByPointTracing(editPoint.id);
      if (res) {
        seteditRadio(res);
      }
    } catch (error) {
      console.error(error);
    }
  };
  if (!pointtracings) return <></>;
  if (!refs) return <></>;
  if (!refs.refPoints) return <></>;
  if (!editPoint) return <></>;

  return (
    <>
      {editPointStep && editPointStep.id ? (
        <PointStepDisplay
          editPoint={editPoint}
          editPointStep={editPointStep}
          editRadio={editRadio}
          onComplete={initPointStep}
        />
      ) : (
        <BtnOpenDialog
          editPoint={editPoint}
          editPointStep={editPointStep}
          editRadio={editRadio}
          onComplete={initPointStep}
        />
      )}
    </>
  );
}

const PointStepDisplay = ({ editPoint, editPointStep, editRadio, onComplete }) => {
  if (!editPointStep) return <></>;
  return (
    <Card sx={{ display: 'flex' }}>
      {editPointStep && editPointStep.xmlData ? (
        <ImgFromSvg
          svgData64={editPointStep.xmlData}
          sx={{ width: 'auto', maxHeight: '600px', height: '450px' }}
        />
      ) : (
        <Typography variant="p" color="text.secondary" component="div">
          Image non disponible
        </Typography>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h6">
            Points tracés créés le {fDateTimeSuffix(editPointStep.createdAt)}
          </Typography>
          <Typography variant="p" color="text.secondary" component="div">
            Dernière mise à jour le {fDateTimeSuffix(editPointStep.updatedAt)}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
          <BtnOpenDialog
            editPoint={editPoint}
            editPointStep={editPointStep}
            editRadio={editRadio}
            onComplete={onComplete}
          />
        </Box>
      </Box>
    </Card>
  );
};

const BtnOpenDialog = ({ editPoint, editPointStep, editRadio, onComplete }) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {!editPointStep && (
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="p" color="text.secondary" component="div">
            Positionnement des points n'est pas encore réalisé.
          </Typography>
        </CardContent>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, mb: 5 }}>
        <LoadPointsBtn
          editPointStep={editPointStep}
          editPoint={editPoint}
          onComplete={onComplete}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
        <Button variant="contained" onClick={handleClickOpen}>
          Placer les points
        </Button>
        <Dialog fullScreen open={open} onClose={handleClose}>
          <DialogPointStepEditor
            editPoint={editPoint}
            editPointStep={editPointStep}
            editRadio={editRadio}
            handleClose={handleClose}
            onComplete={onComplete}
          />
        </Dialog>
      </Box>
    </Box>
  );
};
