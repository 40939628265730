import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SvgTo64 from 'components/media/SvgTo64';
import useEditReport from 'hooks/useEditReport';
import ReportEditText from './ReportEditText';
// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme, color }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: color ? color : theme.palette.text.secondary
}));

export default function ReportSuper() {
  const { editReport, updateEditReport } = useEditReport();
  const [nbColumns, setnbColumns] = useState(null);
  const [superpositionsData, setsuperpositionsData] = useState(null);
  useEffect(() => {
    if (!editReport) return;
    if (!editReport.rawData) return;
    const { superpositionsData } = editReport.rawData;
    if (!Array.isArray(superpositionsData)) return;
    setsuperpositionsData(superpositionsData);
  }, [editReport]);
  useEffect(() => {
    if (!superpositionsData) return;
    setnbColumns(Math.floor(12 / superpositionsData.length));
  }, [superpositionsData]);

  const onChange = (field, text, point) => {
    if (!superpositionsData) return;
    const newPoints = superpositionsData.map((it) => {
      if (String(it.id) === String(point.id)) {
        const obj = { ...it };
        obj[field] = text;
        return obj;
      } else {
        return { ...it };
      }
    });
    setsuperpositionsData(newPoints);

    const newReport = { ...editReport };
    const newRawData = { ...newReport.rawData };
    newRawData.superpositionsData = newPoints;
    newReport.rawData = newRawData;
    updateEditReport(newReport);
  };

  if (!nbColumns) return <></>;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {/* label */}
        {superpositionsData.map((it, i) => {
          const { label, date, color } = it;
          return (
            <Grid key={i} item xs={nbColumns}>
              <Item color={color}>
                <ReportEditText text={label} onChange={(val) => onChange('label', val, it)}>
                  <h3>{label}</h3>
                </ReportEditText>
                <ReportEditText text={date} onChange={(val) => onChange('date', val, it)}>
                  <p>{date}</p>
                </ReportEditText>
              </Item>
            </Grid>
          );
        })}
        {/* superposition svg data*/}
        {superpositionsData.map((it, i) => {
          const { xmlData } = it;
          return (
            <Grid key={i} item xs={nbColumns} className="gridItem">
              <Item className="item-grid">
                <SvgTo64 className="item-img" image={xmlData} />
              </Item>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
