import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { forwardRef } from 'react';
// material
import { Container, Stack, Typography } from '@mui/material';

// ----------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  minWidth: 300,
  margin: 'auto',
  display: 'flex',

  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(4, 0)
}));
const FormContainer = forwardRef(({ children, title = '', ...other }, ref) => (
  <Container ref={ref} {...other}>
    <ContentStyle>
      <Stack sx={{ mb: 3 }}>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
      </Stack>
      {children}
    </ContentStyle>
  </Container>
));

FormContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default FormContainer;
