import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import useNotification from 'hooks/useNotification';
import useReferentiel from 'hooks/useReferentiel';
import ColorPicker from 'components/color/ColorPicker';
import { TEXT_POS_OPTS } from 'config/appConfig';
import faker from 'faker';
// ----------------------------------------------------------------------

export default function EditLineBtn({ editLine }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-' + faker.datatype.uuid() : undefined;

  return (
    <>
      {editLine ? (
        <IconButton onClick={handleClick}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button onClick={handleClick}>
          <AddIcon /> Ajouter un plan
        </Button>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => console.log()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Paper sx={{ width: 320, maxWidth: '100%', px: 3 }}>
          <FormEdit editLine={editLine} onComplete={() => handleClose()} />
        </Paper>
      </Popover>
    </>
  );
}
const FormEdit = ({ editLine, onComplete }) => {
  const [color, setcolor] = useState(null);
  const { notif } = useNotification();
  const { refs, saveLine, initRefs } = useReferentiel();
  useEffect(() => {
    if (!editLine) return;
    if (!editLine.color) {
      setcolor('#000000');
    } else {
      setcolor(editLine.color);
    }
  }, [editLine]);

  const radioSchema = Yup.object().shape({
    code: Yup.number().required(`Veuillez renseigner le code`),
    color: Yup.string(),
    label: Yup.string().max(100).required(`Veuillez renseigner le libellé`),
    indication: Yup.string().max(300).required(`Veuillez renseigner l'indication`),
    startPoint: Yup.string().required(`Veuillez renseigner le point début`),
    endPoint: Yup.string().required(`Veuillez renseigner le point final`),
    endLarger: Yup.number(),
    startLarger: Yup.number()
  });
  const formik = useFormik({
    initialValues: {
      code: editLine && editLine.code ? editLine.code : '',
      color: editLine && editLine.color ? editLine.color : '',
      label: editLine && editLine.label ? editLine.label : '',
      indication: editLine && editLine.indication ? editLine.indication : '',
      startPoint: editLine && editLine.startPoint ? editLine.startPoint.id : '',
      endPoint: editLine && editLine.endPoint ? editLine.endPoint.id : '',
      startLarger: editLine && editLine.startLarger ? editLine.startLarger : '',
      endLarger: editLine && editLine.endLarger ? editLine.endLarger : ''
    },
    validationSchema: radioSchema,
    onSubmit: async () => {
      try {
        const obj = { ...editLine, ...values, color };
        await saveLine(obj);
        await initRefs();
        onComplete();
        notif('Sauvegarde réussie');
      } catch (error) {
        console.error(error);
        notif(null, error);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const { refPoints } = refs;
  return (
    <>
      <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-end">
        <IconButton onClick={onComplete} sx={{ my: 2 }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ mb: 5 }}>
            {color && <ColorPicker color={color} hanldeColor={(color) => setcolor(color)} />}
            <TextField
              fullWidth
              type="number"
              label="Code"
              {...getFieldProps('code')}
              error={Boolean(touched.code && errors.code)}
              helperText={touched.code && errors.code}
            />
            <TextField
              fullWidth
              type="text"
              label="Libellé"
              {...getFieldProps('label')}
              error={Boolean(touched.label && errors.label)}
              helperText={touched.label && errors.label}
            />
            <TextField
              fullWidth
              type="text"
              label="Indication"
              {...getFieldProps('indication')}
              error={Boolean(touched.indication && errors.indication)}
              helperText={touched.indication && errors.indication}
            />

            <TextField
              fullWidth
              select
              label="Point de début"
              {...getFieldProps('startPoint')}
              error={Boolean(touched.startPoint && errors.startPoint)}
              helperText={touched.startPoint && errors.startPoint}
            >
              {refPoints.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {`${option.label} (${option.code})`}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              select
              label="Point final"
              {...getFieldProps('endPoint')}
              error={Boolean(touched.endPoint && errors.endPoint)}
              helperText={touched.endPoint && errors.endPoint}
            >
              {refPoints.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {`${option.label} (${option.code})`}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              type="number"
              label="Prolongement du point de début"
              {...getFieldProps('startLarger')}
              error={Boolean(touched.startLarger && errors.startLarger)}
              helperText={touched.startLarger && errors.startLarger}
            />
            <TextField
              fullWidth
              type="number"
              label="Prolongement du point final"
              {...getFieldProps('endLarger')}
              error={Boolean(touched.endLarger && errors.endLarger)}
              helperText={touched.endLarger && errors.endLarger}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mb: 10 }}
          >
            Valider
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
};
