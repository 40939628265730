import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';

import { styled } from '@mui/material/styles';

import TransformScaleTracingGroup from 'components/d3/TransformScaleTracingGroup';
import TransformRotateTracingGroup from 'components/d3/TransformRotateTracingGroup';
// ----------------------------------------------------------------------
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));
const TransformSuper = React.forwardRef(
  (
    {
      svgRef,
      editTracingGroupRef,
      boxTransformGroupRef,
      hancleCloseSuperTransform,

      ...props
    },
    ref
  ) => {
    return (
      <Drawer variant="permanent" open={false} anchor="right">
        <DrawerHeader />
        <Divider />
        <List ref={boxTransformGroupRef} style={{ display: 'none' }} sx={{ mt: 4 }}>
          <ListItem disablePadding>
            <TransformRotateTracingGroup
              svgRef={svgRef}
              editTracingGroupRef={editTracingGroupRef}
              hancleCloseSuperTransform={hancleCloseSuperTransform}
            />
          </ListItem>
        </List>
      </Drawer>
    );
  }
);
export default TransformSuper;
