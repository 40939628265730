// ----------------------------------------------------------------------

export default function Table({ theme }) {
  const isRTL = theme.direction === 'rtl';
  const thLeft = {
    paddingLeft: 24,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`,
  };
  const thRight = {
    paddingRight: 24,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`,
  };

  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
        head: {
          color: theme.palette.text.secondary,
          //  backgroundColor: theme.palette.background.neutral,
          '&:first-of-type': isRTL ? thRight : thLeft,
          '&:last-of-type': isRTL ? thLeft : thRight,
        },
        stickyHeader: {
          // backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
        },
        body: {
          background: 'transparent',
          position: 'relative',
          ' & > * ': {
            position: 'relative',
            zIndex: 3,
          },

          '&::before': {
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: '100%',
            content: '" "',
            borderTop: `${theme.palette.divider} solid 1px`,
            borderBottom: `${theme.palette.divider} solid 1px`,
            pointerEvents: 'none',
          },

          '&:first-of-type': {
            paddingLeft: !isRTL && 24,
            paddingRight: isRTL && 24,
            '&::before': {
              borderTopLeftRadius: '0.75rem',
              borderBottomLeftRadius: '0.75rem',
              borderLeft: `${theme.palette.divider} solid 1px`,
            },
          },
          '&:last-of-type': {
            paddingRight: !isRTL && 24,
            paddingLeft: isRTL && 24,
            '&::before': {
              borderTopRightRadius: '0.75rem',
              borderBottomRightRadius: '0.75rem',
              borderRight: `${theme.palette.divider} solid 1px`,
            },
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`,
        },
        toolbar: {
          height: 64,
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius,
          },
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: 2,
        },
      },
    },
  };
}
