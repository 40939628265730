import React, { useRef, useEffect, useState } from 'react';
import CropImg from 'components/crop/CropImg';
import { blobToBase64 } from 'utils/imageUtil';
import usePointTracing from 'hooks/usePointTracing';
import useReferentiel from 'hooks/useReferentiel';
import { Box, Card, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';

import ImgFromSvg from 'components/media/ImgFromSvg';
import { fDateTimeSuffix } from 'utils/formatTime';
import usePatient from 'hooks/usePatient';
import 'components/svg/svg.css';
import DialogTracingStepEditor from './DialogTracingStepEditor';
import LoadTracingBtn from './LoadTracingBtn';
import LoadRefTracingBtn from './LoadRefTracingBtn';
// ----------------------------------------------------------------------
export default function EditTracingStep({ editPoint }) {
  const [editRadio, seteditRadio] = useState(null);
  const [editPointStep, seteditPointStep] = useState(null);
  const [editTracingStep, seteditTracingStep] = useState(null);
  const {
    getTracingStepByPointTraincing,
    pointtracings,
    getPointStepByPointTracing,
    getRadioByPointTracing
  } = usePointTracing();
  const { refs } = useReferentiel();
  const { patient } = usePatient();
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!patient) return;
    if (!editPoint || !editPoint.id) return;
    initPointStep();
    initRadio();
    initTracing();
  }, [editPoint, patient]);
  // ----------------------------------------------------------------------
  const initPointStep = async () => {
    try {
      const res = await getPointStepByPointTracing(editPoint.id);
      if (res) {
        seteditPointStep(res);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const initRadio = async () => {
    try {
      const res = await getRadioByPointTracing(editPoint.id);
      if (res) {
        seteditRadio(res);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const initTracing = async () => {
    try {
      const res = await getTracingStepByPointTraincing(editPoint.id);
      if (res) {
        seteditTracingStep(res);
      } else {
        seteditTracingStep(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ---------------------------------------------------------------------
  if (!pointtracings) return <></>;
  if (!refs) return <></>;
  if (!refs.refPoints) return <></>;
  if (!editPoint) return <></>;
  return (
    <>
      {editTracingStep && editTracingStep.id ? (
        <TracingStepDisplay
          editPoint={editPoint}
          editPointStep={editPointStep}
          editTracingStep={editTracingStep}
          editRadio={editRadio}
          onComplete={initTracing}
        />
      ) : (
        <BtnOpenDialog
          editPoint={editPoint}
          editRadio={editRadio}
          editPointStep={editPointStep}
          editTracingStep={editTracingStep}
          onComplete={initTracing}
        />
      )}
    </>
  );
}

const TracingStepDisplay = ({
  editPoint,
  editTracingStep,
  editPointStep,
  editRadio,
  onComplete
}) => {
  if (!editTracingStep) return <></>;
  return (
    <Card sx={{ display: 'flex' }}>
      {editTracingStep && editTracingStep.xmlData ? (
        <ImgFromSvg
          svgData64={editTracingStep.xmlData}
          sx={{ width: 'auto', maxHeight: '600px', height: '450px' }}
        />
      ) : (
        <Typography variant="p" color="text.secondary" component="div">
          Image non disponible
        </Typography>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h6">
            Points tracés créés le {fDateTimeSuffix(editTracingStep.createdAt)}
          </Typography>
          <Typography variant="p" color="text.secondary" component="div">
            Dernière mise à jour le {fDateTimeSuffix(editTracingStep.updatedAt)}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
          <BtnOpenDialog
            editPoint={editPoint}
            editPointStep={editPointStep}
            editTracingStep={editTracingStep}
            editRadio={editRadio}
            onComplete={onComplete}
          />
        </Box>
      </Box>
    </Card>
  );
};

const BtnOpenDialog = ({ editPoint, editTracingStep, editPointStep, editRadio, onComplete }) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {!editTracingStep && (
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="p" color="text.secondary" component="div">
            Aucun tracé n'est fait
          </Typography>
        </CardContent>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, mb: 5 }}>
        <LoadTracingBtn
          editTracingStep={editTracingStep}
          editPoint={editPoint}
          onComplete={onComplete}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, mb: 5 }}>
        <LoadRefTracingBtn
          editTracingStep={editTracingStep}
          editPoint={editPoint}
          onComplete={onComplete}
        />
      </Box>
      {editTracingStep && (
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
          <Button  variant="contained" onClick={handleClickOpen}>
            Editer les tracés
          </Button>

          <Dialog fullScreen open={open} onClose={handleClose}>
            <DialogTracingStepEditor
              editPoint={editPoint}
              editRadio={editRadio}
              editPointStep={editPointStep}
              editTracingStep={editTracingStep}
              handleClose={handleClose}
              onComplete={onComplete}
            />
          </Dialog>
        </Box>
      )}
    </Box>
  );
};
