import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from 'config/appConfig';
import { startLoading, stopLoading, setError, setPointTracing } from 'redux/slices/patientSlice';
import useNotification from 'hooks/useNotification';

// ----------------------------------------------------------------------
export default function usePointTracing() {
  const dispatch = useDispatch();
  const { notif } = useNotification();
  const { isError, isLoading, patient, pointtracings } = useSelector((state) => state.patient);
  return {
    isLoading,
    isError,
    pointtracings,
    initPointTracing: async () => {
      try {
        if (!patient || !patient.id) return;
        dispatch(startLoading());
        const res = await axios.get(`${API_URL.pointTracing}/${patient.id}`);
        if (res && res.data) {
          dispatch(setPointTracing({ pointtracings: res.data }));
        }
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    savePointTracing: async (values) => {
      try {
        if (!patient) return;
        if (!values) return;
        dispatch(startLoading());
        await axios.post(`${API_URL.pointTracing}/${patient.id}`, values);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        notif(null, error);
        throw error;
      }
    },
    deletePointTracing: async (point) => {
      try {
        if (!patient) return;
        if (!point || !point.id) return;
        dispatch(startLoading());
        await axios.delete(`${API_URL.pointTracing}/${patient.id}/${point.id}`);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        notif(null, error);
        throw error;
      }
    },
    saveRadioStep: async (radiostep) => {
      try {
        if (!patient) return;
        if (!radiostep) return;
        if (!radiostep.pointtracing) return;
        const id = radiostep.id ? radiostep.id : radiostep._id;
        const url = `${API_URL.radio}/${id}`;

        await axios.patch(`${url}`, radiostep);
      } catch (error) {
        console.error(error);
        notif(null, error);
        throw error;
      }
    },
    uploadRadio: async (o, radioFormData) => {
      try {
        if (!patient) return;
        if (!radioFormData) return;
        if (o && o.id) {
          await axios.patch(API_URL.radio + '/' + o.id, radioFormData);
        } else {
          await axios.post(API_URL.radio, radioFormData);
        }
      } catch (error) {
        console.error(error);
        notif(null, error);
        throw error;
      }
    },
    savePointStep: async (pointstep) => {
      try {
        if (!patient) return;
        if (!pointstep) return;
        const id = pointstep.id ? pointstep.id : pointstep._id ? pointstep._id : null;
        if (id) {
          await axios.patch(`${API_URL.pointStep}/${id}`, pointstep);
        } else {
          await axios.post(`${API_URL.pointStep}`, pointstep);
        }
      } catch (error) {
        console.error(error);
        notif(null, error);
        throw error;
      }
    },

    createPointsFromModel: async (editPointId, editPointStepId, modelPointStepId) => {
      try {
        if (!patient) return;
        if (!editPointId) return;
        if (!modelPointStepId) return;
        await axios.get(
          `${API_URL.pointStep}/createFromModel/${patient.id}/${editPointId}/${editPointStepId}/${modelPointStepId}`
        );
      } catch (error) {
        console.error(error);
        notif(null, error);
        throw error;
      }
    },
    saveTracingStep: async (tracingStep) => {
      try {
        if (!patient) return;
        if (!tracingStep) return;
        if (!tracingStep.tracings) return;
        const id = tracingStep.id ? tracingStep.id : tracingStep._id ? tracingStep._id : null;
        if (id) {
          await axios.patch(`${API_URL.tracingStep}/${id}`, tracingStep);
        } else {
          await axios.post(`${API_URL.tracingStep}`, tracingStep);
        }
      } catch (error) {
        console.error(error);
        notif(null, error);
        throw error;
      }
    },
    resetTracingStep: async (tracingStep) => {
      try {
        if (!patient) return;
        if (!tracingStep) return;

        const id = tracingStep.id ? tracingStep.id : tracingStep._id ? tracingStep._id : null;
        if (id) {
          await axios.delete(`${API_URL.tracingStep}/${id}`);
        }
      } catch (error) {
        console.error(error);
        notif(null, error);
        throw error;
      }
    },
    createTracingFromModel: async (editPointStepId, patientTracingStepId, modelTracingStepId) => {
      try {
        if (!patient) return;
        if (!editPointStepId) return;
        if (!modelTracingStepId) return;
        await axios.get(
          `${API_URL.tracingStep}/createFromModel/${patient.id}/${editPointStepId}/${patientTracingStepId}/${modelTracingStepId}`
        );
      } catch (error) {
        console.error(error);
        notif(null, error);
        throw error;
      }
    },
    createTracingFromRef: async (editPointStepId, patientTracingStepId) => {
      try {
        if (!patient) return;
        if (!editPointStepId) return;
        await axios.get(
          `${API_URL.tracingStep}/createFromRef/${patient.id}/${editPointStepId}/${patientTracingStepId}`
        );
      } catch (error) {
        console.error(error);
        notif(null, error);
        throw error;
      }
    },

    getRadioByPointTracing: async (idPoint) => {
      try {
        if (!patient || !patient.id) return;
        if (!idPoint) return;
        dispatch(startLoading());
        const res = await axios.get(`${API_URL.radio}/by-pointtracing/${idPoint}`);
        if (res && res.data) {
          return res.data;
        }
        dispatch(stopLoading());
        return null;
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    getPointStepByPointTracing: async (idPoint) => {
      try {
        if (!patient || !patient.id) return;
        if (!idPoint) return;
        dispatch(startLoading());
        const res = await axios.get(`${API_URL.pointStep}/by-pointtracing/${idPoint}`);
        if (res && res.data) {
          return res.data;
        }
        dispatch(stopLoading());
        return null;
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    getTracingStepByPointTraincing: async (idPoint) => {
      try {
        if (!patient || !patient.id) return;
        if (!idPoint) return;
        dispatch(startLoading());
        const res = await axios.get(`${API_URL.tracingStep}/by-pointtracing/${idPoint}`);
        if (res && res.data) {
          return res.data;
        }
        dispatch(stopLoading());
        return null;
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    getSuperStepByPointTracing: async (idPoint) => {
      try {
        if (!patient || !patient.id) return;
        if (!idPoint) return;
        dispatch(startLoading());
        const res = await axios.get(`${API_URL.superStep}/by-pointtracing/${idPoint}`);
        if (res && res.data) {
          return res.data;
        }
        dispatch(stopLoading());
        return null;
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    createSuperStepByPointTracing: async (idPoint) => {
      try {
        if (!patient || !patient.id) return;
        if (!idPoint) return;
        dispatch(startLoading());
        const res = await axios.get(`${API_URL.superStep}/create-by-pointtracing/${idPoint}`);
        if (res && res.data) {
          return res.data;
        }
        dispatch(stopLoading());
        return null;
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    saveSuperStep: async (superStep) => {
      try {
        if (!patient) return;
        const id = superStep.id;
        if (id) {
          await axios.patch(`${API_URL.superStep}/${id}`, superStep);
        }
      } catch (error) {
        console.error(error);
        notif(null, error);
        throw error;
      }
    },
    resetSuperStep: async (idPoint, currentSuper) => {
      try {
        if (!patient) return;
        const code = currentSuper.code;
        if (idPoint && code) {
          await axios.get(`${API_URL.superStep}/reset/${idPoint}/${code}`);
        }
      } catch (error) {
        console.error(error);
        notif(null, error);
        throw error;
      }
    }
  };
}
