import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// ----------------------------------------------------------------------

export default function MenuEditPointTracing({ steps, activeStep, onSelectStep }) {
  if (!Array.isArray(steps)) return <></>;
  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Réalisation en 3 phases
        </ListSubheader>
      }
    >
      {steps.map((it, i) => {
        return (
          <ListItemButton
            key={i}
            selected={activeStep === it.index}
            onClick={(e) => onSelectStep(it)}
          >
            <ListItemIcon>{it.icon}</ListItemIcon>
            <ListItemText primary={it.label} />
          </ListItemButton>
        );
      })}
    </List>
  );
}
