function path(root, sublink) {
  return `${root}${sublink}`;
}
const ROOTS = {
  auth: '',
  app: '/app'
};
export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    resetPassword: path(ROOTS.auth, '/reset-password')
  }
};
export const PATH_APP = {
  root: ROOTS.app,
  dashboard: path(ROOTS.app, '/dashboard'),
  addPatient: path(ROOTS.app, '/add-patient'),
  editPatient: path(ROOTS.app, '/edit-patient'),
  patients: path(ROOTS.app, '/patients'),
  radiosSettings: path(ROOTS.app, '/radios-settings'),
  pointsSettings: path(ROOTS.app, '/points-settings'),
  linesSettings: path(ROOTS.app, '/lines-settings'),
  anglesSettings: path(ROOTS.app, '/angles-settings'),
  distancesSettings: path(ROOTS.app, '/distances-settings'),
  tracingsSettings: path(ROOTS.app, '/tracings-settings'),
  reportSettings: path(ROOTS.app, '/report-settings'),
  superSettings: path(ROOTS.app, '/superposition-settings'),
  admin: path(ROOTS.app, '/admin')
};
