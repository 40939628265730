import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { API_URL } from 'config/appConfig';
import ImgBoxFromUrl from 'components/media/ImgBoxFromUrl';
import ImgFromSvg from 'components/media/ImgFromSvg';
import usePatient from 'hooks/usePatient';

import { fDateTimeSuffix } from 'utils/formatTime';
import DialogRadioEditor from './DialogRadioEditor';

// ----------------------------------------------------------------------
export default function RadioEditor({ editPoint, editRadio }) {
  if (!editRadio) return <></>;
  const imageUrl = `${API_URL.file}/${editRadio.imgFile}`;
  return (
    <Card sx={{ display: 'flex' }}>
      {editRadio && editRadio.xmlData ? (
        <ImgFromSvg
          svgData64={editRadio.xmlData}
          sx={{ width: 'auto', maxHeight: '600px', height: '450px' }}
        />
      ) : (
        <ImgBoxFromUrl
          image={imageUrl}
          sx={{ width: 'auto', maxHeight: '600px', height: '450px' }}
        />
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h6">
            Radiologie créée le {fDateTimeSuffix(editRadio.createdAt)}
          </Typography>
          <Typography variant="p" color="text.secondary" component="div">
            Dernière mise à jour le {fDateTimeSuffix(editRadio.updatedAt)}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
          <BtnOpenDialog editPoint={editPoint} editRadio={editRadio} />
        </Box>
      </Box>
    </Card>
  );
}
const BtnOpenDialog = ({ editPoint, editRadio }) => {
  const [open, setOpen] = React.useState(false);
  const { patient } = usePatient();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const disabledField = patient && patient.id && patient.lockOff;

  return (
    <div>
      <Button  variant="contained" onClick={handleClickOpen} disabled={disabledField}>
        Editer les points de repères
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <DialogRadioEditor editPoint={editPoint} editRadio={editRadio} handleClose={handleClose} />
      </Dialog>
    </div>
  );
};
