import React from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify-icons/eva/close-fill';
import { MIconButton } from 'theme/@material-extend/Buttons';
// material
import { Stack, TextField, MenuItem } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { LoadingButton } from '@mui/lab';
import 'dayjs/locale/fr';
import usePatient from 'hooks/usePatient';
import { PATH_APP } from 'config/paths';
// ----------------------------------------------------------------------
export default function FormMedinfo() {
  const { patient, saveMedInfo } = usePatient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const formSchema = Yup.object().shape({
    contactinfo: Yup.string(),
    allergies: Yup.string(),
    antecedents: Yup.string(),
    treatments: Yup.string(),
    healthprofessionals: Yup.string(),
    observations: Yup.string()
  });
  const medinfo = patient && patient.medinfo ? patient.medinfo : null;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      contactinfo: medinfo && medinfo.contactinfo ? medinfo.contactinfo : '',
      allergies: medinfo && medinfo.allergies ? medinfo.allergies : '',
      antecedents: medinfo && medinfo.antecedents ? medinfo.antecedents : '',
      treatments: medinfo && medinfo.treatments ? medinfo.treatments : '',
      healthprofessionals:
        medinfo && medinfo.healthprofessionals ? medinfo.healthprofessionals : '',
      observations: medinfo && medinfo.observations ? medinfo.observations : ''
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      try {
        await saveMedInfo(values);
        enqueueSnackbar('Sauvegarde réussie', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      } catch (error) {
        console.error(error);
        let msg = '';
        if (error.response) {
          msg = error.response.data.message;
        } else {
          msg = 'Network error';
        }
        enqueueSnackbar(msg, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setFieldValue,
    handleSubmit,
    getFieldProps,
    handleChange
  } = formik;
  if (!patient) return;
  const disabledField = patient && patient.id && patient.lockOff;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            disabled={disabledField}
            fullWidth
            type="text"
            multiline
            rows={3}
            label="Personne à contacter"
            {...getFieldProps('contactinfo')}
            error={Boolean(touched.contactinfo && errors.contactinfo)}
            helperText={touched.contactinfo && errors.contactinfo}
          />
          <TextField
            disabled={disabledField}
            fullWidth
            type="text"
            multiline
            rows={3}
            label="Allergies"
            {...getFieldProps('allergies')}
            error={Boolean(touched.allergies && errors.allergies)}
            helperText={touched.allergies && errors.allergies}
          />
          <TextField
            disabled={disabledField}
            fullWidth
            type="text"
            multiline
            rows={3}
            label="Antécédents"
            {...getFieldProps('antecedents')}
            error={Boolean(touched.antecedents && errors.antecedents)}
            helperText={touched.antecedents && errors.antecedents}
          />
          <TextField
            disabled={disabledField}
            fullWidth
            type="text"
            multiline
            rows={3}
            label="Taitements"
            {...getFieldProps('treatments')}
            error={Boolean(touched.treatments && errors.treatments)}
            helperText={touched.treatments && errors.treatments}
          />
          <TextField
            disabled={disabledField}
            fullWidth
            type="text"
            multiline
            rows={3}
            label="Professionnel de santé"
            {...getFieldProps('healthprofessionals')}
            error={Boolean(touched.healthprofessionals && errors.healthprofessionals)}
            helperText={touched.healthprofessionals && errors.healthprofessionals}
          />
          <TextField
            disabled={disabledField}
            fullWidth
            type="text"
            multiline
            rows={3}
            label="Observation"
            {...getFieldProps('observations')}
            error={Boolean(touched.observations && errors.observations)}
            helperText={touched.observations && errors.observations}
          />
          <LoadingButton
            disabled={disabledField}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Valider
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
