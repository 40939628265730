import React from 'react';
import {
  Popover,
  IconButton,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// ----------------------------------------------------------------------

export default function SvgEditorCloseBtn({ onClose, ...props }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = async () => {
    try {
      setAnchorEl(null);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
      <IconButton edge="start" color="inherit" onClick={handleClick} aria-label="close">
        <CloseIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Card
          sx={{
            minWidth: 275
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Fermer l'editeur d'image
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Veuillez vérifier l'enregistrement de votre espace de travail avant la fermeture.
            </Typography>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button color="error" onClick={handleConfirm}>
              Ok
            </Button>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </Box>
  );
}
