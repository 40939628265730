import React, { useEffect, useCallback, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Box } from '@mui/material';
import { invertColor } from 'utils/d3Util';
import useEditReport from 'hooks/useEditReport';
// ----------------------------------------------------------------------

const rootStyles = {
  width: '100%',
  marginTop: '50px',
  marginBottom: '50px',
  '& .title': {
    fontWeight: 550
  }
};
export default function ReportAnalyse() {
  const { editReport, updateEditReport } = useEditReport();
  const [rows, setrows] = useState(null);
  const [rowsUpdated, setrowsUpdated] = useState(null);
  const [rowEdited, setrowEdited] = useState(null);
  const [headerStyle, setheaderStyle] = useState(null);
  useEffect(() => {
    if (!editReport) return;
    if (!editReport.rawData) return;
    setrows(editReport.rawData.data);
    setheaderStyle(editReport.rawData.headerStyle);
  }, [editReport]);
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!rowEdited) return;
    const newRows = rows.map((it) => {
      if (String(it.id) === String(rowEdited.id)) {
        return { ...rowEdited };
      } else {
        return { ...it };
      }
    });
    setrowsUpdated(newRows);
    const newReport = { ...editReport };
    const newRawData = { ...newReport.rawData };
    newRawData.data = newRows;
    newReport.rawData = newRawData;
    updateEditReport(newReport);
  }, [rowEdited]);
  // ----------------------------------------------------------------------
  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = React.useCallback((newRow) => {
    setrowEdited(newRow);
    return newRow;
  }, []);

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);
  if (!rows || !headerStyle) return <></>;

  const columns = [
    {
      field: 'title',
      headerName: '',
      editable: true,
      minWidth: 140,
      cellClassName: (params) => {
        const { row } = params;
        if (row && row.type === 'title') {
          return 'title';
        }
        return '';
      },
      colSpan: ({ row, field, value }) => {
        const { type } = row;
        let colSpan = 1;
        if (type && type === 'title') {
          colSpan = 6;
        }
        return colSpan;
      }
    },
    {
      field: 'moyenne',
      headerClassName: 'moyenne',
      headerName: 'Moyenne',
      editable: true,
      minWidth: 140
    },
    {
      field: 'debut',
      sortable: false,
      filterable: false,
      renderHeader: (params) => {
        return (
          <div
            style={{
              padding: '8px',
              color: headerStyle.debut,
              minWidth: '140px'
            }}
          >
            <strong> Début</strong>
          </div>
        );
      },
      editable: true
    },
    {
      field: 'reev',
      sortable: false,
      filterable: false,
      renderHeader: (params) => {
        return (
          <div
            style={{
              padding: '8px',
              color: headerStyle.reev,
              minWidth: '140px'
            }}
          >
            <strong> Réév</strong>
          </div>
        );
      },
      editable: true
    },
    {
      field: 'fin',
      sortable: false,
      filterable: false,
      renderHeader: (params) => {
        return (
          <div
            style={{
              padding: '8px',
              color: headerStyle.fin,
              minWidth: '140px'
            }}
          >
            <strong> Fin</strong>
          </div>
        );
      },
      editable: true
    },
    {
      field: 'cont',
      sortable: false,
      filterable: false,
      renderHeader: (params) => {
        return (
          <div
            style={{
              padding: '8px',
              color: headerStyle.cont,
              minWidth: '140px'
            }}
          >
            <strong> Cont</strong>
          </div>
        );
      },
      editable: true
    }
  ];
  return (
    <Box sx={rootStyles}>
      <DataGrid
        autoHeight
        onCellClick={(params, event) => {
          event.stopPropagation();
        }}
        rows={rows}
        columns={columns}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
        disableColumnMenu
        disableExtendRowFullWidth
        disableSelectionOnClick
        hideFooter
        showCellRightBorder
        showColumnRightBorder
        disableColumnReorder
      />
    </Box>
  );
}
