import React, { useEffect, useRef, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled, useTheme } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import Checkbox from '@mui/material/Checkbox';

import OpacityIcon from '@mui/icons-material/Opacity';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import GradientIcon from '@mui/icons-material/Gradient';
import ContrastIcon from '@mui/icons-material/Contrast';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';

import TransformIcon from '@mui/icons-material/Transform';
import CropRotateIcon from '@mui/icons-material/CropRotate';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import Paper from '@mui/material/Paper';
import * as d3 from 'd3';
import useNotification from 'hooks/useNotification';
import { KingBed } from '@mui/icons-material';
import faker from 'faker';
import useEditPath from 'hooks/useEditPath';
import { getTransformPath } from 'utils/pathUtil';
import { ListItemText } from '@mui/material';
// ----------------------------------------------------------------------
const Input = styled(MuiInput)`
  width: 42px;
`;
// ----------------------------------------------------------------------

const ResetSuperBtn = React.forwardRef(({ svgRef, onReset }, ref) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-' + faker.datatype.uuid() : undefined;
  const handleReset = () => {
    if (!svgRef || !svgRef.current) return;
    const svg = d3.select(svgRef.current);
    if (!svg) return;
    onReset();
    handleClose();
  };

  return (
    <>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: 'initial',
          px: 2.5
        }}
        onClick={handleClick}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 3,
            justifyContent: 'center'
          }}
        >
          <RestartAltIcon />
        </ListItemIcon>
        <ListItemText primary="Réinitialiser" />
      </ListItemButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Paper sx={{ textAlign: 'center', width: 320, maxWidth: 'MAX_SCALE%', p: 3 }}>
          <h5>
            Voulez-vous supprimer la configuration en cours et rétablir sa position par défault?
          </h5>
          <Button onClick={handleReset} sx={{ mt: 3 }}>
            <RestartAltIcon /> Réinitialiser
          </Button>
        </Paper>
      </Popover>
    </>
  );
});
export default ResetSuperBtn;
// ----------------------------------------------------------------------
