import React from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify-icons/eva/close-fill';
import { MIconButton } from 'theme/@material-extend/Buttons';
// material
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  MenuItem,
  Box,
  Button
} from '@mui/material';
import FormContainer from 'components/FormContainer';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { LoadingButton } from '@mui/lab';
import 'dayjs/locale/fr';
import dayjs, { Dayjs } from 'dayjs';
import usePatient from 'hooks/usePatient';
import usePointTracing from 'hooks/usePointTracing';
import useReferentiel from 'hooks/useReferentiel';
import useNotification from 'hooks/useNotification';
// ----------------------------------------------------------------------
const LOCALE_DEF = 'fr';
export default function FormPointTracing({ onFinish }) {
  const [open, setOpen] = React.useState(false);
  const { patient } = usePatient();
  const disabledField = patient && patient.id && patient.lockOff;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleComplete = (refCode) => {
    setOpen(false);
    onFinish(refCode);
  };
  return (
    <div>
      <Button disabled={disabledField} variant="contained" onClick={handleClickOpen}>
        Ajouter une étape
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogContent>
          <FormPoint onComplete={handleComplete} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const FormPoint = ({ onComplete }) => {
  const { patient } = usePatient();
  const { refs } = useReferentiel();
  const { savePointTracing, initPointTracing } = usePointTracing();
  const { notif } = useNotification();
  const { refSteps } = refs;
  const formSchema = Yup.object().shape({
    refstep: Yup.string().required('Veuillez renseigner le type'),
    label: Yup.string().required(`Veuillez renseigner le nom d'étape`),
    executedat: Yup.string().required('Veuillez renseigner la date de réalisation')
  });

  const formik = useFormik({
    initialValues: {
      refstep: '',
      label: '',
      executedat: dayjs()
    },
    validationSchema: formSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (!patient) return;
        await savePointTracing({ ...values, patient: patient.id });
        await initPointTracing();
        const addRef = refSteps.find((it) => String(it.id) === values.refstep);
        onComplete(addRef.code);
        resetForm();
        notif('Sauvegarde réussie');
      } catch (error) {
        notif(null, error);
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setFieldValue,
    handleSubmit,
    getFieldProps,
    handleChange,
    resetForm
  } = formik;

  if (!refSteps) return <></>;
  if (!patient) return <></>;
  return (
    <FormContainer title="Nouvelle étape">
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={LOCALE_DEF}>
            <Stack spacing={3}>
              <TextField
                select
                label="Type"
                value={values.refstep}
                onChange={handleChange('refstep')}
                {...getFieldProps('refstep')}
                error={Boolean(touched.refstep && errors.refstep)}
                helperText={touched.refstep && errors.refstep}
              >
                {refSteps.map((option, i) => (
                  <MenuItem value={option.id} key={i}>
                    <Box display="flex" alignContent="center" justifyContent="center">
                      <Box sx={{ height: '100%' }}>
                        <Box
                          sx={{
                            width: '20px',
                            borderRadius: '50%',
                            mx: 2,
                            height: '20px',
                            background: option.color
                          }}
                        />
                      </Box>
                      <Box>{option.label}</Box>
                    </Box>
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                type="text"
                label="Nom"
                {...getFieldProps('label')}
                error={Boolean(touched.label && errors.label)}
                helperText={touched.label && errors.label}
              />
              <DesktopDatePicker
                onChange={(value) => setFieldValue('executedat', value, true)}
                value={values.executedat}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(touched.executedat && errors.executedat)}
                    helperText={touched.executedat && errors.executedat}
                    label="Date de réalisation"
                    margin="normal"
                    name="birth"
                    variant="standard"
                    fullWidth
                    {...params}
                  />
                )}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Valider
              </LoadingButton>
            </Stack>
          </LocalizationProvider>
        </Form>
      </FormikProvider>
    </FormContainer>
  );
};
