import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Page from 'components/Page';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { API_URL } from 'config/appConfig';
import { fDate } from 'utils/formatTime';
import { PATH_APP } from 'config/paths';
import { IconButton } from '@mui/material';
import Fab from '@mui/material/Fab';
import useNotification from 'hooks/useNotification';
import useReferentiel from 'hooks/useReferentiel';
import ImgBoxFromUrl from 'components/media/ImgBoxFromUrl';
import EditReportSectionBtn from './EditReportSectionBtn';
// ----------------------------------------------------------------------
export default function ReportSettings() {
  const { refs, initRefs } = useReferentiel();
  useEffect(() => {
    initRefs();
  }, []);
  if (!refs) return <></>;

  return (
    <Page
      title="Configuration des secitons du rapport | OCS - Orthodontie | Céphalométrie | Superposition"
      style={{ height: '100%' }}
    >
      <Container maxWidth="xl" style={{ height: '100%' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h4">Liste des sections</Typography>
          </Box>
          <EditReportSectionBtn />
        </Box>
        {refs.refReportSections && <Grid items={refs.refReportSections} />}
      </Container>
    </Page>
  );
}
const Grid = ({ items, ...props }) => {
  const { notif } = useNotification();
  const { initRefs, deleteReportSection } = useReferentiel();
  const handleDelete = async (id) => {
    try {
      await deleteReportSection(id);
      await initRefs();
      notif('Suppression réussie');
    } catch (error) {
      notif(null, error);
    }
  };
  const rows = items;
  const columns = [
    {
      field: 'code',
      headerName: 'Code',
      sortable: true,
      width: 150
    },
    {
      field: 'label',
      headerName: 'Libellé',
      sortable: true,
      width: 150
    },
    {
      field: 'title',
      headerName: 'Titre',
      sortable: true,
      width: 250
    },
    {
      field: 'content',
      headerName: 'Contenu texte',
      sortable: true,
      width: 250
    },
    /*  {
      field: 'imageField',
      headerName: 'Image',
      width: 150,
      renderCell: (params) => {
        try {
          const { row } = params;
          const imageUrl = row.image ? `${API_URL.file}/${row.image}` : null;
          return (
            <>
              {imageUrl && (
                <ImgBoxFromUrl
                  image={imageUrl}
                  sx={{
                    borderRadius: 1,
                    objectFit: 'cover',
                    width: '150px',
                    height: 'auto'
                  }}
                />
              )}{' '}
            </>
          );
        } catch (error) {
          return <></>;
        }
      }
    }, */
    {
      field: 'action',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        try {
          return (
            <Box display="flex" alignItems="center">
              <EditReportSectionBtn editReportSection={params.row} />
              <IconButton
                onClick={() => handleDelete(params.row.id)}
                sx={{ mx: 2 }}
                size="small"
                variant="outline"
                color="error"
              >
                <DeleteForeverIcon />
              </IconButton>
            </Box>
          );
        } catch (error) {
          return <></>;
        }
      }
    }
  ];
  if (!items) return <></>;
  return (
    <>
      <DataGrid
        rowHeight={150}
        getRowHeight={() => 'auto'}
        sx={{
          pt: 3,
          [`& .${gridClasses.cell}`]: {
            py: 1
          }
        }}
        rows={rows}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[25, 50]}
        disableSelectionOnClick
      />
    </>
  );
};
