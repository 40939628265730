import * as React from 'react';
import useAuth from 'hooks/useAuth';
import useReferentiel from 'hooks/useReferentiel';

const AuthContext = React.createContext();
const AuthProvider = ({ ...props }) => {
  const { initAuth, handleErrorAxios } = useAuth();
  const { initRefs } = useReferentiel();
  React.useEffect(() => {
    initAuth();
    initRefs();
    //handleErrorAxios();
  }, []);

  return <AuthContext.Provider value={{}}>{props.children}</AuthContext.Provider>;
};
export default AuthProvider;
