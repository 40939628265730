import { parseISO, format, formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
// ----------------------------------------------------------------------

export function fDate(date) {
  try {
    return format(new Date(date), 'dd MMMM yyyy', {
      awareOfUnicodeTokens: true,
      locale: fr
    });
  } catch (error) {
    return '';
  }
}

export function fDateTime(date) {
  try {
    return format(new Date(date), 'dd MMM yyyy HH:mm', {
      awareOfUnicodeTokens: true,
      locale: fr
    });
  } catch (error) {
    console.error(error);
    return '';
  }
}

export function fDateTimeSuffix(date) {
  try {
    return format(new Date(date), 'dd/MM/yyyy HH:mm');
  } catch (error) {
    console.error(error);
    return '';
  }
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function parseToDate(str) {
  try {
    return parseISO(str);
  } catch (error) {
    console.error(error);
    return new Date();
  }
}
