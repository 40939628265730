import React, { useCallback, useEffect, useRef, useState } from 'react';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import CropRotateIcon from '@mui/icons-material/CropRotate';
import Paper from '@mui/material/Paper';
import * as d3 from 'd3';
import faker from 'faker';
import useEditPath from 'hooks/useEditPath';
import { getBoundingBoxCenter, getTransformPath } from 'utils/pathUtil';
// ----------------------------------------------------------------------
const Input = styled(MuiInput)`
  width: 42px;
`;
// ----------------------------------------------------------------------

const TransformRotateTracingGroup = React.forwardRef(
  ({ svgRef, editTracingGroupRef, hancleCloseSuperTransform }, ref) => {
    const { handleUpdateSvgGroup } = useEditPath();
    const [resetAt, setresetAt] = useState(null);
    const [angle, setangle] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    // ----------------------------------------------------------------------

    const handleClose = () => {
      try {
        setAnchorEl(null);
        const { globalGroup, idGroup } = editTracingGroupRef.current;
        if (!idGroup) return;
        //reset
        const centerPoint = getBoundingBoxCenter(d3.select(`#${idGroup}`));
        editTracingGroupRef.current = {
          idGroup,
          currentNoTransform: {
            centerPoint,
            nodes: d3
              .select(`#${idGroup}`)
              .selectAll('path')
              .nodes()
              .map((it) => {
                const pathGroup = d3.select(it);
                return { id: pathGroup.attr('id'), imgpath: pathGroup.attr('d') };
              })
          }
        };
        /** update current editSvg and histories */
        handleUpdateSvgGroup(globalGroup);
        if (hancleCloseSuperTransform) {
          hancleCloseSuperTransform({
            idGroup,
            centerPoint: angle.centerPoint,
            angle: Number(angle.value / (180 / Math.PI))
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    // ----------------------------------------------------------------------

    const handleUpdateValue = (value, centerPoint) => {
      setangle({ value, centerPoint });
    };
    // ----------------------------------------------------------------------
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover-' + faker.datatype.uuid() : undefined;
    // ----------------------------------------------------------------------
    const handleReset = () => {
      if (!svgRef || !svgRef.current) return;
      const svg = d3.select(svgRef.current);
      if (!svg) return;
      setresetAt(new Date());
    };
    if (!editTracingGroupRef) return <></>;
    // ----------------------------------------------------------------------
    return (
      <>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: 'center',
            px: 2.5
          }}
          onClick={handleClick}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 'auto',
              justifyContent: 'center'
            }}
          >
            <Rotate90DegreesCcwIcon />
          </ListItemIcon>
        </ListItemButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Paper sx={{ width: 320, maxWidth: 'MAX_SCALE%', p: 3 }}>
            <InputSliderRotate
              svgRef={svgRef}
              editTracingGroupRef={editTracingGroupRef}
              resetAt={resetAt}
              handleUpdateValue={handleUpdateValue}
            />
            <Divider sx={{ my: 3 }} />
            <Button onClick={handleReset}>
              <RestartAltIcon /> Réinitialiser
            </Button>
          </Paper>
        </Popover>
      </>
    );
  }
);
export default TransformRotateTracingGroup;

// ----------------------------------------------------------------------
const MIN_ROTATE = -360;
const MAX_ROTATE = 360;
const STEP_ROTATE = 0.1;
const InputSliderRotate = React.forwardRef(
  ({ svgRef, editTracingGroupRef, resetAt, handleUpdateValue, ...props }, ref) => {
    const [value, setValue] = React.useState(0);
    const [beginAt, setbeginAt] = React.useState(null);
    const handleSliderChange = (event, newValue) => {
      setValue(newValue);
      if (!beginAt) {
        setbeginAt(new Date());
      }
    };
    const handleInputChange = (event) => {
      setValue(event.target.value === '' ? '' : Number(event.target.value));
      if (!beginAt) {
        setbeginAt(new Date());
      }
    };

    const handleBlur = () => {
      if (value < MIN_ROTATE) {
        setValue(MIN_ROTATE);
      } else if (value > MAX_ROTATE) {
        setValue(MAX_ROTATE);
      }
    };
    // ----------------------------------------------------------------------
    useEffect(() => {
      if (!svgRef || !svgRef.current) return;
      if (!resetAt) return;
      setValue(0);
    }, [svgRef, resetAt]);
    // ----------------------------------------------------------------------
    useEffect(() => {
      if (!svgRef || !svgRef.current) return;
      if (!editTracingGroupRef || !editTracingGroupRef.current) return;
      try {
        const { idGroup, currentNoTransform } = editTracingGroupRef.current;
        const g = d3.select(`#${idGroup}`);
        g.selectAll('path')
          .nodes()
          .forEach((node) => {
            const nodePath = d3.select(node);
            let encodePath = '';
            if (Number(value) === 0 && resetAt) {
              //reset action
              const resetNode = currentNoTransform.nodes.find((it) => {
                return nodePath.attr('id') === it.id;
              });
              encodePath = resetNode ? resetNode.imgpath : '';
            } else {
              const currentPath = nodePath.attr('d');
              encodePath = getTransformPath(
                currentPath,
                null,
                Number(value / (180 / Math.PI)),
                currentNoTransform.centerPoint
              );
            }

            nodePath.attr('d', encodePath);
          });
        g.attr('transform', null);
        handleUpdateValue(value, currentNoTransform.centerPoint);
      } catch (error) {
        console.error(error);
      }
    }, [value]);
    // ----------------------------------------------------------------------
    return (
      <>
        <Typography id="input-slider" gutterBottom>
          Rotation
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <CropRotateIcon />
          </Grid>
          <Grid item xs>
            <Slider
              step={STEP_ROTATE}
              min={MIN_ROTATE}
              max={MAX_ROTATE}
              value={typeof value === 'number' ? value : 0}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
            />
          </Grid>
          <Grid item>
            <Input
              value={value}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: STEP_ROTATE,
                min: MIN_ROTATE,
                max: MAX_ROTATE,

                type: 'number',
                'aria-labelledby': 'input-slider'
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }
);
