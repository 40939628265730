import React, { useRef, useEffect, useState } from 'react';
import {
  Popover,
  Dialog,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions
} from '@mui/material';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify-icons/eva/close-fill';
import { MIconButton } from 'theme/@material-extend/Buttons';
import * as d3 from 'd3';
import { zoom } from 'd3-zoom';
import { drag } from 'd3-drag';
import {
  SVGEDITOR_WIDTH,
  SVGEDITOR_HEIGHT,
  SUPER_CAT_MAXI,
  SUPER_CAT_MANDI,
  SUPER_GENERALE,
  SUPER_MAXI,
  SUPER_MANDI
} from 'config/appConfig';
import { blobToBase64 } from 'utils/imageUtil';
import DeleteIcon from '@mui/icons-material/Delete';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import { updatePathString } from 'utils/pathUtil';
import { TRANSLATE, getTransform, setTransform } from 'utils/d3Util';
import useSuperposition from 'hooks/useSuperposition';
import useNotification from 'hooks/useNotification';
import usePatient from 'hooks/usePatient';
import faker from 'faker';
import ImgFromSvg from 'components/media/ImgFromSvg';
import { fDateTimeSuffix } from 'utils/formatTime';
import DialogSuperEditor from './DialogSuperEditor';
import DialogSuperLocalEditor from './DialogSuperLocalEditor';
import DialogSuperCombineLocalEditor from './DialogSuperCombineLocalEditor';
import DialogSuperCombineEditor from './DialogSuperCombineEditor';
// ----------------------------------------------------------------------
export default function EditSuperposition({ editSuperposition, onDelete }) {
  const { initSuperposition, saveSuperposition, initSuperFromPointTracing } = useSuperposition();
  const [editTracingsSuperpo, seteditTracingsSuperpo] = useState(null);
  const { notif } = useNotification();
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!editSuperposition) return;
    const init = async () => {
      if (Array.isArray(editSuperposition.tracings) && editSuperposition.tracings.length > 0) {
        seteditTracingsSuperpo(editSuperposition.tracings);
      }
    };
    init();
  }, [editSuperposition]);
  // ----------------------------------------------------------------------
  const canCreateMaxiMandi = () => {
    if (!editSuperposition) return;
    const { pointTracings, tracings } = editSuperposition;
    for (let i = 0; i < tracings.length; i++) {
      const t = tracings[i]; //general , maxi , mandi
      if (t.tracings.length !== pointTracings.length) {
        return true;
      }
    }

    return false;
  };
  // ----------------------------------------------------------------------
  const handleComplete = async (editTracingSuper) => {
    if (!editSuperposition) return;
    if (!editTracingSuper) return;

    try {
      let newTracings = editSuperposition.tracings.map((it) => {
        if (String(it.code) === String(editTracingSuper.code)) {
          return { ...editTracingSuper };
        }
        return { ...it };
      });
      const size = newTracings.length;
      if (editSuperposition.combined) {
        //=> in case combined superposition save
        if (editTracingSuper.code === SUPER_GENERALE) {
          /** get the transfert guide of point tracings not exist and create a new one. */
          const superMaxiIdx = newTracings.findIndex((it) => it.code === SUPER_MAXI);

          if (superMaxiIdx > -1) {
            const superMaxi = newTracings[superMaxiIdx];
            const toAddMaxi = editTracingSuper.tracings
              .filter((it) => !it.fixed)
              .map((t) => {
                const newT = { ...t };
                const newTracings = t.tracings.map((trace) => {
                  const newTrace = { ...trace };
                  if (String(trace.refCategoryTracing.code) === String(SUPER_CAT_MAXI)) {
                    newTrace.display = true;
                  }
                  return { ...newTrace };
                });
                const { transfertGuide } = t;
                newTracings.push({
                  ...newTracings[0],
                  id: transfertGuide.id,
                  imgpath: transfertGuide.path,
                  display: true
                });
                newT.tracings = newTracings;
                return newT;
              });
            superMaxi.tracings = [...superMaxi.tracings, ...toAddMaxi];
            newTracings[superMaxiIdx] = superMaxi;
          }
          const superMandiIdx = newTracings.findIndex((it) => it.code === SUPER_MANDI);
          if (superMandiIdx > -1) {
            const superMandi = newTracings[superMandiIdx];
            const toAddMandi = editTracingSuper.tracings
              .filter((it) => !it.fixed)
              .map((t) => {
                const newT = { ...t };
                const newTracings = t.tracings.map((trace) => {
                  const newTrace = { ...trace };
                  if (String(trace.refCategoryTracing.code) === String(SUPER_MANDI)) {
                    newTrace.display = true;
                  }
                  return { ...newTrace };
                });
                const { transfertGuide } = t;
                newTracings.push({
                  ...newTracings[0],
                  id: transfertGuide.id,
                  imgpath: transfertGuide.path,
                  display: true
                });
                newT.tracings = newTracings;
                return newT;
              });
            superMandi.tracings = [...superMandi.tracings, ...toAddMandi];
            newTracings[superMandiIdx] = superMandi;
          }
        } else {
          /** if tracing is local, need to draw implan line in to general with dx dy of transfert guide */
          const superGenIdx = newTracings.findIndex((it) => {
            return it.code === SUPER_GENERALE;
          });

          if (superGenIdx > -1) {
            const superGen = { ...editSuperposition.tracings[superGenIdx] };
            const newPointTracings = superGen.tracings.map((pTracing) => {
              const newPTracing = { ...pTracing }; // debut, reeval, contention,fin
              if (!newPTracing.fixed) {
                //only point tracing not fixed
                const pointTracingFoundedIdx = editTracingSuper.tracings.findIndex(
                  (a) => String(a.id) === String(newPTracing.id)
                );
                if (pointTracingFoundedIdx > -1) {
                  const tracingFounded = editTracingSuper.tracings[pointTracingFoundedIdx];

                  const { distance, angle, centerPoint } = tracingFounded;
                  const field =
                    editTracingSuper.code === SUPER_MAXI ? 'implineMaxi' : 'implineMandi';
                  const originPath = editTracingSuper.implanLine.path;
                  const updatePath = updatePathString(originPath, distance.x, distance.y, {
                    angle,
                    centerPoint
                  });

                  newPTracing[field] = {
                    path: updatePath,
                    originPath,
                    distance,
                    angle
                  };
                  editTracingSuper.tracings[pointTracingFoundedIdx] = tracingFounded;
                }
              }

              return { ...newPTracing };
            });
            superGen.tracings = newPointTracings;
            newTracings[superGenIdx] = superGen;
          }
        }
      } else {
        //normal superposition
        console.log();
        /** if edit tracing is General, create 2 tracings locals */
        if (editTracingSuper.code === SUPER_GENERALE) {
          if (size < 3) {
            const superMaxi = {
              code: 2,
              label: 'Superposition maxilaire',
              createdAt: new Date(),
              tracings: editTracingSuper.tracings.map((t) => {
                const newT = { ...t };
                const newTracings = t.tracings.map((trace) => {
                  const newTrace = { ...trace };

                  if (String(trace.refCategoryTracing.code) === String(SUPER_CAT_MAXI)) {
                    newTrace.display = true;
                  }
                  return { ...newTrace };
                });
                const { transfertGuide } = t;
                newTracings.push({
                  ...newTracings[0],
                  id: transfertGuide.id,
                  imgpath: transfertGuide.path,
                  display: true
                });
                newT.tracings = newTracings;
                return newT;
              })
            };
            const superMandi = {
              code: 3,
              label: 'Superposition mandibulaire',
              createdAt: new Date(),
              tracings: editTracingSuper.tracings.map((t) => {
                const newT = { ...t };
                const newTracings = t.tracings.map((trace) => {
                  const newTrace = { ...trace };
                  if (String(trace.refCategoryTracing.code) === String(SUPER_CAT_MANDI)) {
                    newTrace.display = true;
                  }
                  return { ...newTrace };
                });
                const { transfertGuide } = t;
                newTracings.push({
                  ...newTracings[0],
                  id: transfertGuide.id,
                  imgpath: transfertGuide.path,
                  display: true
                });
                newT.tracings = newTracings;
                return newT;
              })
            };
            newTracings.push(superMaxi);
            newTracings.push(superMandi);
          }
        } else {
          /** if tracing is local, need to draw implan line in to general with dx dy of transfert guide */
          const superGenIdx = newTracings.findIndex((it) => {
            return it.code === SUPER_GENERALE;
          });

          if (superGenIdx > -1) {
            const superGen = { ...editSuperposition.tracings[superGenIdx] };
            const newPointTracings = superGen.tracings.map((pTracing) => {
              const newPTracing = { ...pTracing }; // debut, reeval, contention,fin

              const pointTracingFoundedIdx = editTracingSuper.tracings.findIndex(
                (a) => String(a.id) === String(newPTracing.id)
              );
              if (pointTracingFoundedIdx > -1) {
                const tracingFounded = editTracingSuper.tracings[pointTracingFoundedIdx];

                const { distance, angle, centerPoint } = tracingFounded;
                const field = editTracingSuper.code === SUPER_MAXI ? 'implineMaxi' : 'implineMandi';
                const originPath = editTracingSuper.implanLine.path;
                const updatePath = updatePathString(originPath, distance.x, distance.y, {
                  angle,
                  centerPoint
                });
                newPTracing[field] = {
                  path: updatePath,
                  originPath,
                  distance,
                  angle
                };
              }
              return { ...newPTracing };
            });
            superGen.tracings = newPointTracings;
            newTracings[superGenIdx] = superGen;
          }
        }
      }
      if (newTracings) {
        const toSave = { ...editSuperposition, tracings: newTracings };
        await saveSuperposition(toSave);
        await initSuperposition();
      }

      notif('Sauvegarde réussie');
    } catch (error) {
      console.error(error);
      notif(null, error);
    }
  };

  if (!editTracingsSuperpo) return <></>;
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
        <BtnDeleteSuper editSuperposition={editSuperposition} onDelete={onDelete} />
        <Box sx={{ mx: 1 }} />
        <BtnEnableReport editSuperposition={editSuperposition} />
      </Box>
      {editSuperposition &&
      Array.isArray(editSuperposition.tracings) &&
      editSuperposition.tracings.length > 0 ? (
        <>
          {editSuperposition.tracings.map((it, i) => {
            return (
              <React.Fragment key={i}>
                <SuperDisplay
                  editTracingsSuperpo={editTracingsSuperpo}
                  editSuperposition={it}
                  superposition={editSuperposition}
                  onComplete={handleComplete}
                />
              </React.Fragment>
            );
          })}
        </>
      ) : (
        <>
          <Typography variant="h4">Aucune superposition n'est trouvée</Typography>
        </>
      )}
    </>
  );
}
const SuperDisplay = ({ superposition, editTracingsSuperpo, editSuperposition, onComplete }) => {
  return (
    <Card sx={{ display: 'flex', mt: 2 }}>
      {editSuperposition && editSuperposition.xmlData ? (
        <ImgFromSvg
          svgData64={editSuperposition.xmlData}
          sx={{ width: 'auto', maxHeight: '600px', height: '450px' }}
        />
      ) : (
        <Typography variant="p" color="text.secondary" component="div">
          Image non disponible
        </Typography>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          {editSuperposition.createdAt && (
            <Typography component="div" variant="h6">
              {editSuperposition.label} créé le {fDateTimeSuffix(editSuperposition.createdAt)}
            </Typography>
          )}
          {editSuperposition.updatedAt && (
            <Typography variant="p" color="text.secondary" component="div">
              Dernière mise à jour le {fDateTimeSuffix(editSuperposition.updatedAt)}
            </Typography>
          )}
        </CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pl: 1,
            pb: 1
          }}
        >
          {editSuperposition.tracings && (
            <BtnOpenDialog
              editTracingsSuperpo={editTracingsSuperpo}
              editSuperposition={editSuperposition}
              onComplete={onComplete}
              superposition={superposition}
            />
          )}
        </Box>
      </Box>
    </Card>
  );
};
// ----------------------------------------------------------------------

const BtnEnableReport = ({ editSuperposition, ...props }) => {
  const { activateSuperReport, initSuperposition, saveSuperposition, initSuperFromPointTracing } =
    useSuperposition();
  const { notif } = useNotification();

  const handleClick = async (val) => {
    try {
      await activateSuperReport({ id: editSuperposition.id, value: val });
      await initSuperposition();
      notif('Sauvegarde réussie');
    } catch (error) {
      notif(null, error);
    }
  };
  if (!editSuperposition) return <></>;
  return (
    <>
      {editSuperposition.enabledReport ? (
        <>
          <Button variant="contained" color="info" onClick={() => handleClick(false)}>
            <DoNotDisturbAltIcon />
            Désactiver
          </Button>
        </>
      ) : (
        <>
          <Button variant="contained" color="warning" onClick={() => handleClick(true)}>
            <DoneAllIcon />
            Activer dans le rapport
          </Button>
        </>
      )}
    </>
  );
};
// ----------------------------------------------------------------------

const BtnDeleteSuper = ({ editSuperposition, onDelete, ...props }) => {
  const { initSuperposition, deleteSuperposition } = useSuperposition();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { patient } = usePatient();
  const disabledField = patient && patient.id && patient.lockOff;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = async () => {
    try {
      if (!editSuperposition) return;
      await deleteSuperposition(editSuperposition.id);
      await initSuperposition();
      setAnchorEl(null);
      onDelete();
    } catch (error) {
      console.error(error);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  if (!editSuperposition) return <></>;
  return (
    <>
      <Button disabled={disabledField} variant="contained" color="error" onClick={handleClick}>
        <DeleteIcon />
        Supprimer
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Card
          sx={{
            minWidth: 275
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Suppression de superposition
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Confirmer-vous la suppression de la superposition {editSuperposition.label} ?
            </Typography>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button color="error" onClick={handleConfirm}>
              Valider
            </Button>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};

const BtnOpenDialog = ({ superposition, editTracingsSuperpo, editSuperposition, onComplete }) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  if (!editSuperposition) return <></>;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1, mt: 2 }}>
        <Button variant="contained" onClick={handleClickOpen}>
          Editer
        </Button>
        <Dialog fullScreen open={open} onClose={handleClose}>
          {superposition.combined ? (
            <>
              {editSuperposition.code === 1 ? (
                <DialogSuperCombineEditor
                  editTracings={editSuperposition.tracings}
                  editSuperposition={editSuperposition}
                  handleClose={handleClose}
                  onComplete={onComplete}
                  superposition={superposition}
                />
              ) : (
                <DialogSuperCombineLocalEditor
                  editTracings={editSuperposition.tracings}
                  editSuperposition={editSuperposition}
                  handleClose={handleClose}
                  onComplete={onComplete}
                  superposition={superposition}
                />
              )}
            </>
          ) : (
            <>
              {editSuperposition.code === 1 ? (
                <DialogSuperEditor
                  editTracings={editSuperposition.tracings}
                  editSuperposition={editSuperposition}
                  handleClose={handleClose}
                  onComplete={onComplete}
                />
              ) : (
                <DialogSuperLocalEditor
                  editTracings={editSuperposition.tracings}
                  editSuperposition={editSuperposition}
                  handleClose={handleClose}
                  onComplete={onComplete}
                  editTracingsSuperpo={editTracingsSuperpo}
                />
              )}
            </>
          )}
        </Dialog>
      </Box>
    </Box>
  );
};
