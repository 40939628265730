import React, { useEffect, useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import useNotification from 'hooks/useNotification';
import useReferentiel from 'hooks/useReferentiel';
import ColorPicker from 'components/color/ColorPicker';
import { TEXT_POS_OPTS } from 'config/appConfig';
import faker from 'faker';
// ----------------------------------------------------------------------

export default function EditPointBtn({ editPoint }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-' + faker.datatype.uuid() : undefined;

  return (
    <>
      <IconButton onClick={handleClick}>
        <EditIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => console.log()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        
      >
        <Paper sx={{ width: 320, maxWidth: '100%', px: 3 }}>
          {editPoint && <FormEdit editPoint={editPoint} onComplete={() => handleClose()} />}
        </Paper>
      </Popover>
    </>
  );
}
const FormEdit = ({ editPoint, onComplete }) => {
  const [color, setcolor] = useState(null);
  const { notif } = useNotification();
  const { savePoint, initRefs } = useReferentiel();
  useEffect(() => {
    if (!editPoint) return;
    if (!editPoint.color) {
      setcolor('#000000');
    } else {
      setcolor(editPoint.color);
    }
  }, [editPoint]);

  const radioSchema = Yup.object().shape({
    color: Yup.string(),
    name: Yup.string().max(100).required(`Veuillez renseigner le nom`),
    label: Yup.string().max(100).required(`Veuillez renseigner le libellé`),
    indication: Yup.string().max(300).required(`Veuillez renseigner l'indication`),
    xposition: Yup.number().required(`Veuillez renseigner la x-position`),
    yposition: Yup.number().required(`Veuillez renseigner la y-position`),
    textPosition: Yup.string().max(100)
  });
  const formik = useFormik({
    initialValues: {
      color: editPoint && editPoint.color ? editPoint.color : '',
      name: editPoint && editPoint.name ? editPoint.name : '',
      label: editPoint && editPoint.label ? editPoint.label : '',
      indication: editPoint && editPoint.indication ? editPoint.indication : '',
      xposition: editPoint && editPoint.xposition ? editPoint.xposition : '',
      yposition: editPoint && editPoint.yposition ? editPoint.yposition : '',
      textPosition: editPoint && editPoint.textPosition ? editPoint.textPosition : ''
    },
    validationSchema: radioSchema,
    onSubmit: async () => {
      try {
        const obj = { ...editPoint, ...values, color };
        await savePoint(obj);
        await initRefs();
        onComplete();
        notif('Sauvegarde réussie');
      } catch (error) {
        console.error(error);
        notif(null, error);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <>
      <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-end">
        <IconButton onClick={onComplete} sx={{ my: 2 }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ mb: 5 }}>
            {color && <ColorPicker color={color} hanldeColor={(color) => setcolor(color)} />}
            <TextField
              fullWidth
              type="text"
              label="Nom"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              type="text"
              label="Libellé"
              {...getFieldProps('label')}
              error={Boolean(touched.label && errors.label)}
              helperText={touched.label && errors.label}
            />
            <TextField
              fullWidth
              type="text"
              label="Indication"
              {...getFieldProps('indication')}
              error={Boolean(touched.indication && errors.indication)}
              helperText={touched.indication && errors.indication}
            />
            <TextField
              fullWidth
              type="number"
              label="X-Position"
              {...getFieldProps('xposition')}
              error={Boolean(touched.xposition && errors.xposition)}
              helperText={touched.xposition && errors.xposition}
            />
            <TextField
              fullWidth
              type="number"
              label="Y-Position"
              {...getFieldProps('yposition')}
              error={Boolean(touched.yposition && errors.yposition)}
              helperText={touched.yposition && errors.yposition}
            />
            <TextField
              fullWidth
              select
              label="Position du text"
              {...getFieldProps('textPosition')}
              error={Boolean(touched.textPosition && errors.textPosition)}
              helperText={touched.textPosition && errors.textPosition}
            >
              {TEXT_POS_OPTS.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mb: 10 }}
          >
            Valider
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
};
