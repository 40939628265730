import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import useNotification from 'hooks/useNotification';
import useReferentiel from 'hooks/useReferentiel';
import ColorPicker from 'components/color/ColorPicker';
import { TEXT_POS_OPTS } from 'config/appConfig';
import faker from 'faker';
import { parseToCommands, getPathfromXml } from 'utils/pathUtil';
// ----------------------------------------------------------------------

export default function EditTracingBtn({ editTracing }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-' + faker.datatype.uuid() : undefined;

  return (
    <>
      {editTracing && editTracing.id ? (
        <IconButton onClick={handleClick}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button onClick={handleClick} variant="contained">
          <AddIcon /> Ajouter un tracé
        </Button>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => console.log()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Paper sx={{ width: 520, maxWidth: '100%', px: 3 }}>
          <FormEdit editTracing={editTracing} onComplete={() => handleClose()} />
        </Paper>
      </Popover>
    </>
  );
}
const FormEdit = ({ editTracing, onComplete }) => {
  const [color, setcolor] = useState(null);
  const { notif } = useNotification();
  const { refs, saveTracing, initRefs } = useReferentiel();
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!editTracing || !editTracing.color) {
      setcolor('#000000');
    } else {
      setcolor(editTracing.color);
    }
  }, [editTracing]);
  // ----------------------------------------------------------------------
  const radioSchema = Yup.object().shape({
    color: Yup.string(),
    code: Yup.number().required(`Veuillez renseigner le code`),
    label: Yup.string().max(100).required(`Veuillez renseigner le libellé`),
    imgpath: Yup.string().required(`Veuillez renseigner le path du tracé`),
    refCategoryTracing: Yup.string().required(`Veuillez renseigner la catégorie`)
  });
  const formik = useFormik({
    initialValues: {
      color: editTracing && editTracing.color ? editTracing.color : '',
      code: editTracing && editTracing.code ? editTracing.code : '',
      label: editTracing && editTracing.label ? editTracing.label : '',
      imgpath: editTracing && editTracing.imgpath ? editTracing.imgpath : '',
      refCategoryTracing:
        editTracing && editTracing.refCategoryTracing ? editTracing.refCategoryTracing.id : ''
    },
    validationSchema: radioSchema,
    onSubmit: async () => {
      try {
        const obj = { ...editTracing, ...values, color };
        let commandsGenerated = null;
        if (values.imgpath.includes('<') || values.imgpath.includes('/>')) {
          obj.imgpath = btoa(values.imgpath);
          const pathFormXml = getPathfromXml(values.imgpath);
          commandsGenerated = parseToCommands(pathFormXml);
        } else {
          commandsGenerated = parseToCommands(values.imgpath);
        }
        obj.commands = commandsGenerated;
        await saveTracing(obj);
        await initRefs();
        onComplete();
        notif('Sauvegarde réussie');
      } catch (error) {
        console.error(error);
        notif(null, error);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const { refCategoryTracing } = refs;
  return (
    <>
      <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-end">
        <IconButton onClick={onComplete} sx={{ my: 2 }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ mb: 5 }}>
            {color && <ColorPicker color={color} hanldeColor={(color) => setcolor(color)} />}
            <TextField
              fullWidth
              type="number"
              label="Code"
              {...getFieldProps('code')}
              error={Boolean(touched.code && errors.code)}
              helperText={touched.code && errors.code}
            />
            <TextField
              fullWidth
              type="text"
              label="Libellé"
              {...getFieldProps('label')}
              error={Boolean(touched.label && errors.label)}
              helperText={touched.label && errors.label}
            />
            <TextField
              fullWidth
              type="text"
              multiline
              rows={3}
              label="Path du tracé"
              {...getFieldProps('imgpath')}
              error={Boolean(touched.imgpath && errors.imgpath)}
              helperText={touched.imgpath && errors.imgpath}
            />
            <TextField
              fullWidth
              select
              label="Catégorie"
              {...getFieldProps('refCategoryTracing')}
              error={Boolean(touched.refCategoryTracing && errors.refCategoryTracing)}
              helperText={touched.refCategoryTracing && errors.refCategoryTracing}
            >
              {refCategoryTracing.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {`${option.label}`}
                </MenuItem>
              ))}
            </TextField>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mb: 10 }}
          >
            Valider
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
};
