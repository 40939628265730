import React from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import IconButton from '@mui/material/IconButton';
// ----------------------------------------------------------------------
export default function useNotification() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return {
    notif: (msg, response) => {
      try {
        let variant = 'success';
        let horizontal = 'right';
        if (!msg) {
          horizontal = 'left';
          variant = 'error';
          msg = response.message;

          if (response.response && response.response.data) {
            msg = response.response.data.message;
          } else if (typeof response.message !== 'string') {
            msg = 'Une erreur est survenue.';
          }
        }
        enqueueSnackbar(msg, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: horizontal
          },
          variant: variant,
          action: (key) => (
            <IconButton variant="outlined" size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </IconButton>
          )
        });
      } catch (error) {
        console.error(error);
      }
    }
  };
}
