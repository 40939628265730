import React, { useEffect, useRef, useState } from 'react';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import CropRotateIcon from '@mui/icons-material/CropRotate';
import Paper from '@mui/material/Paper';
import * as d3 from 'd3';
import faker from 'faker';
import useEditPath from 'hooks/useEditPath';
import { getTransformPath } from 'utils/pathUtil';
// ----------------------------------------------------------------------
const Input = styled(MuiInput)`
  width: 42px;
`;
// ----------------------------------------------------------------------

const TransformRotatePath = React.forwardRef(({ svgRef, editTracingGroupRef }, ref) => {
  const { handleUpdateSvgGroup } = useEditPath();
  const [resetAt, setresetAt] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  useEffect(() => {
    if (!editTracingGroupRef) return;
    if (!editTracingGroupRef.current) return;
  }, [editTracingGroupRef, anchorEl]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    const { globalGroup } = editTracingGroupRef.current;
    handleUpdateSvgGroup(globalGroup);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-' + faker.datatype.uuid() : undefined;
  const handleReset = () => {
    if (!svgRef || !svgRef.current) return;
    const svg = d3.select(svgRef.current);
    if (!svg) return;
    setresetAt(new Date());
  };
  return (
    <>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: 'center',
          px: 2.5
        }}
        onClick={handleClick}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 'auto',
            justifyContent: 'center'
          }}
        >
          <Rotate90DegreesCcwIcon />
        </ListItemIcon>
      </ListItemButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Paper sx={{ width: 320, maxWidth: 'MAX_SCALE%', p: 3 }}>
          <InputSliderRotate
            svgRef={svgRef}
            resetAt={resetAt}
            editTracingGroupRef={editTracingGroupRef}
          />
          <Divider sx={{ my: 3 }} />
          <Button onClick={handleReset}>
            <RestartAltIcon /> Réinitialiser
          </Button>
        </Paper>
      </Popover>
    </>
  );
});
export default TransformRotatePath;

// ----------------------------------------------------------------------
const MIN_ROTATE = -360;
const MAX_ROTATE = 360;
const STEP_ROTATE = 0.1;
const InputSliderRotate = React.forwardRef(
  ({ svgRef, resetAt, editTracingGroupRef, ...props }, ref) => {
    const { redrawPoints, editSvg } = useEditPath();
    const [value, setValue] = React.useState(0);
    const [beginAt, setbeginAt] = React.useState(null);
    const handleSliderChange = (event, newValue) => {
      setValue(newValue);
      setbeginAt(new Date());
    };

    const handleInputChange = (event) => {
      setValue(event.target.value === '' ? '' : Number(event.target.value));
      setbeginAt(new Date());
    };

    const handleBlur = () => {
      if (value < MIN_ROTATE) {
        setValue(MIN_ROTATE);
      } else if (value > MAX_ROTATE) {
        setValue(MAX_ROTATE);
      }
    };
    // ----------------------------------------------------------------------
    useEffect(() => {
      if (!svgRef || !svgRef.current) return;
      if (!resetAt) return;
      setValue(0);
    }, [svgRef, resetAt]);
    // ----------------------------------------------------------------------
    useEffect(() => {
      if (!svgRef || !svgRef.current) return;
      if (!beginAt) return;
      if (!editTracingGroupRef) return;
      if (!editTracingGroupRef.current) return;
      try {
        const { idPath, currentPath, centerPoint } = editTracingGroupRef.current.editPath;
        const pathFounded = d3.select(`#${idPath}`);
        if (!pathFounded) return;
        const idGroup = pathFounded.attr('idGroup');
        const idCenterPoint = `center_${idGroup}`;
        d3.select(`#${idGroup}`)
          .append('circle')
          .attr('id', idCenterPoint)
          .attr('cx', centerPoint.x)
          .attr('cy', centerPoint.y)
          .attr('r', 30)
          .attr('fill', 'blue');
        //const currentPath = pathFounded.attr('d');

        const encodePath = getTransformPath(currentPath, null, Number(value / 50), centerPoint);
        pathFounded.attr('d', encodePath);
        redrawPoints(idPath);
        d3.select(`#${idCenterPoint}`).remove();
      } catch (error) {
        console.error(error);
      }
    }, [editTracingGroupRef, value, beginAt]);
    // ----------------------------------------------------------------------
    return (
      <>
        <Typography id="input-slider" gutterBottom>
          Rotation
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <CropRotateIcon />
          </Grid>
          <Grid item xs>
            <Slider
              step={STEP_ROTATE}
              min={MIN_ROTATE}
              max={MAX_ROTATE}
              value={typeof value === 'number' ? value : 0}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
            />
          </Grid>
          <Grid item>
            <Input
              value={value}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: STEP_ROTATE,
                min: MIN_ROTATE,
                max: MAX_ROTATE,

                type: 'number',
                'aria-labelledby': 'input-slider'
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }
);
