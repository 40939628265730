import React, { useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Drawer,
  Button,
  Box,
  ListItemText,
  CssBaseline
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import {
  API_URL,
  SVGEDITOR_WIDTH,
  SVGEDITOR_HEIGHT,
  SVGEDITOR_TOOLBAR_WIDTH
} from 'config/appConfig';
import { htmlToBase64 } from 'utils/d3Util';
import { useReactToPrint } from 'react-to-print';
import useEditReport from 'hooks/useEditReport';
import ReportToPrint from './ReportToPrint';

// ----------------------------------------------------------------------
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));
export default function ReportEditor({ handleClose }) {
  const {
    mixins: { toolbar }
  } = useTheme();
  const { saveReportData, editReport } = useEditReport();
  const componentRef = useRef();
  const onBeforeGetContentResolve = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState('old boring text');
  const handleAfterPrint = React.useCallback(() => {
    console.log('`onAfterPrint` called'); // tslint:disable-line no-console
  }, []);
  const handleBeforePrint = React.useCallback(() => {
    console.log('`onBeforePrint` called'); // tslint:disable-line no-console
  }, []);
  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log('`onBeforeGetContent` called'); // tslint:disable-line no-console
    setLoading(true);
    setText('Loading new text...');

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        setLoading(false);
        setText('New, Updated Text!');
        resolve();
      }, 100);
    });
  }, [setLoading, setText]);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    pageStyle: `
    @media all {
      .page-break {
        display: none;
      }
      .gridItem{
        width:auto;
        height:8cm;
        overflow:hidden;
        padding:2mm;
        display:flex;
       justify-content:center;
      }
      .print-logo{
       display:flex;
       justify-content:center;
       align-items:center;
      }
      .hor-bar{
        width:10cm;
      }
    }
    @media print {
      .print-hidden {
        display:none;
      }
      .item-grid {
        width:100%;
        height:100%;
        overflow:hidden;
        position: relative;
        text-align: center;
      }
      .item-img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }
    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: always; /* page-break-after works, as well */
      }
    
    }
    @page {
      size: auto;
      margin: 20mm;
      }
    `,
    documentTitle: 'OCS - Orthodontie | Céphalométrie | Superposition',
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        try {
          const html = document.getElementsByTagName('html')[0];
          saveReportData({ htmlData: htmlToBase64(html.innerHTML) });
        } catch (error) {
          console.error(error);
        }
      }
      printIframe.contentWindow.print();
    }
  });
  React.useEffect(() => {
    if (text === 'New, Updated Text!' && typeof onBeforeGetContentResolve.current === 'function') {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);
  if (!editReport || !editReport.rawData) return <></>;
  const { metadata } = editReport.rawData;
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          ml: `${SVGEDITOR_TOOLBAR_WIDTH}px`,
          zIndex: 1300
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Edition de {editReport.label}
          </Typography>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: SVGEDITOR_TOOLBAR_WIDTH + 50,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: SVGEDITOR_TOOLBAR_WIDTH + 50,
            boxSizing: 'border-box'
          }
        }}
        variant="permanent"
        anchor="left"
      >
        <DrawerHeader />
        <Divider />
        <List sx={{ mt: 2 }}>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: 'initial',
                px: 2.5
              }}
              onClick={handlePrint}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: 'center'
                }}
              >
                <PrintIcon />
              </ListItemIcon>
              <ListItemText primary="Imprimer" />
            </ListItemButton>
          </ListItem>
        </List>
        {metadata && (
          <Box sx={{ my: 3, px: 2 }}>
            {Object.entries(metadata).map(([e, k]) => {
              return (
                <React.Fragment key={e}>
                  <Divider />
                  <Typography variant="h6" noWrap component="div" sx={{ mt: 2 }}>
                    {k.title}
                  </Typography>
                  <List>
                    {Object.entries(k).map(([a, b], i) => {
                      return (
                        <React.Fragment key={i}>
                          {b.label && b.updatedAt && (
                            <ListItemText primary={`${b.label}`} secondary={b.updatedAt} />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </List>
                </React.Fragment>
              );
            })}
          </Box>
        )}
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: `100%`,
          height: `calc(100vh - (${toolbar?.minHeight}px + ${15}px))`,
          marginTop: `${toolbar?.minHeight + 10}px`,
          overflow: 'hidden'
        }}
      >
        <div
          style={{
            margin: '0',
            padding: '20px',
            width: `100%`,
            overflow: 'auto',
            height: `calc(100vh - (${toolbar?.minHeight}px + ${35}px))`
          }}
        >
          <ReportToPrint ref={componentRef} />
        </div>
      </Box>
    </Box>
  );
}
