import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from 'config/appConfig';
import {
  startLoading,
  stopLoading,
  setError,
  setPatient,
  setPointTracing,
  setSuperpositions,
  resetPatient
} from 'redux/slices/patientSlice';
import useNotification from 'hooks/useNotification';
// ----------------------------------------------------------------------
export default function usePatient() {
  const { notif } = useNotification();
  const dispatch = useDispatch();
  const { isError, isLoading, patient } = useSelector((state) => state.patient);

  return {
    isLoading,
    isError,
    patient,
    resetPatientForm: () => {
      dispatch(setPatient({ patient: null }));
      dispatch(setPointTracing({ pointtracing: null }));
      dispatch(setSuperpositions({ superposition: null }));
    },
    initPatient: async (idPatient) => {
      try {
        dispatch(resetPatient());
        dispatch(setPatient({ patient: null }));
        dispatch(startLoading());
        const res = await axios.get(`${API_URL.patient}/${idPatient}`);
        if (res && res.data) {
          dispatch(setPatient({ patient: res.data }));
        }
        dispatch(stopLoading());
      } catch (error) {
        dispatch(setError());
        throw new Error('Patient not founded');
      }
    },
    savePatient: async (values) => {
      try {
        dispatch(startLoading());
        const isEditMode = patient && patient.id;
        const url = isEditMode ? `${API_URL.patient}/${patient.id}` : API_URL.patient;
        if (isEditMode) {
          const res = await axios.patch(url, { ...values });
          if (res && res.data) {
            dispatch(setPatient({ patient: res.data }));
            dispatch(stopLoading());
          }
        } else {
          const res = await axios.post(url, { ...values });
          if (res && res.data) {
            dispatch(setPatient({ patient: res.data }));
            dispatch(stopLoading());
            return res.data;
          }
          dispatch(stopLoading());
        }
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },
    delPatient: async (patientId) => {
      try {
        if (!patientId) return;
        dispatch(startLoading());
        const url = `${API_URL.patient}/${patientId}`;
        const res = await axios.delete(url);
        dispatch(setPatient({ patient: null }));
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    saveMedInfo: async (values) => {
      try {
        dispatch(startLoading());
        const isEditMode = patient && patient.medinfo;
        const url = `${API_URL.patient}/${patient.id}/medinfo`;
        if (isEditMode) {
          const res = await axios.patch(url, { ...values });
          if (res && res.data) {
            dispatch(setPatient({ patient: res.data }));
          }
        } else {
          const res = await axios.post(url, { ...values });
          if (res && res.data) {
            dispatch(setPatient({ patient: res.data }));
          }
        }
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    changeLock: async (patientId) => {
      try {
        if (!patientId) return;
        dispatch(startLoading());
        const url = `${API_URL.patient}/${patientId}/changeLock`;
        const res = await axios.get(url);

        if (res && res.data) {
          dispatch(setPatient({ patient: res.data }));
        }
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    }
  };
}
