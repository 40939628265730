import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from 'components/animate';
import { PATH_APP } from 'config/paths';

// ----------------------------------------------------------------------

export default function PatientNotFounded() {
  return (
    <Container>
      <MotionContainer initial="initial" open>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <motion.div variants={varBounceIn}>
            <Typography variant="h3" paragraph>
              Le dossier du patient demandé n'est pas trouvé
            </Typography>
          </motion.div>
          <Typography sx={{ color: 'text.secondary' }}>
            Veuillez vérifier l'identifiant du patient et relancez la recherche.
          </Typography>

          <motion.div variants={varBounceIn}>
            <Box
              component="img"
              src="/static/illustrations/illustration_404.svg"
              sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
            />
          </motion.div>

          <Button to={PATH_APP.patients} size="large" variant="contained" component={RouterLink}>
            Retourner à liste des patients
          </Button>
        </Box>
      </MotionContainer>
    </Container>
  );
}
