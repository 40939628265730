import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ClickAwayListener from '@mui/material/ClickAwayListener';

// ----------------------------------------------------------------------

export default function ReportEditText({ text, onChange, ...props }) {
  const [editMode, seteditMode] = useState(false);
  const [editingText, seteditingText] = useState(null);
  useEffect(() => {
    seteditingText(text);
  }, [text]);
  const onDoubleClick = () => {
    seteditMode(true);
  };
  const handleClickAway = () => {
    seteditMode(false);
    onChange(editingText);
  };
  return (
    <>
      {editMode ? (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            component="form"
            sx={{
              backgroundColor: 'rgb(240, 240, 240,0.8)',
              '& > :not(style)': { m: 1 }
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              fullWidth
              id="outlined-basic"
              variant="outlined"
              value={editingText}
              onChange={(event) => {
                seteditingText(event.target.value);
              }}
            />
          </Box>
        </ClickAwayListener>
      ) : (
        <div onDoubleClick={onDoubleClick}>{props.children}</div>
      )}
    </>
  );
}
