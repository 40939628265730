import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Page from 'components/Page';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { API_URL } from 'config/appConfig';
import { fDateTime, fDate } from 'utils/formatTime';
import { PATH_APP } from 'config/paths';
import { IconButton } from '@mui/material';
import usePatient from 'hooks/usePatient';
import ActionBtnPatient from './ActionBtnPatient';
// ----------------------------------------------------------------------
export default function PatientGrid() {
  const [patients, setpatients] = useState(null);
  const [updateIn, setupdateIn] = useState(null);

  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    if (!updateIn) return;
    init();
  }, [updateIn]);
  const init = async () => {
    try {
      const res = await axios.get(API_URL.patient);
      if (res && res.data) {
        setpatients(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page
      title="Liste des patients | OCS - Orthodontie | Céphalométrie | Superposition"
      style={{ height: '100%' }}
    >
      <Container maxWidth="xl" style={{ height: '100%' }}>
        <Box sx={{ pb: 5 }} display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h4">Liste des patients</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" sx={{ my: 2 }}>
            <Button
              to={PATH_APP.addPatient}
              variant="contained"
              color="primary"
              component={RouterLink}
              endIcon={<AddIcon />}
            >
              Ajouter
            </Button>
          </Box>
        </Box>

        {patients && <Grid result={patients} setupdateIn={setupdateIn} />}
      </Container>
    </Page>
  );
}
const Grid = ({ result, setupdateIn, ...props }) => {
  const { delPatient, changeLock } = usePatient();
  if (!result.results) return <></>;
  const handleDeletePatient = async (patient) => {
    try {
      await delPatient(patient.id);
      setupdateIn(Date.now());
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const handleChangeLockPatient = async (patient) => {
    try {
      await changeLock(patient.id);
      setupdateIn(Date.now());
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const rows = result.results;
  const columns = [
    {
      field: 'fullName',
      headerName: 'Nom',
      sortable: true,
      width: 160,
      valueGetter: (params) =>
        `${params.getValue(params.id, 'firstname') || ''} ${
          params.getValue(params.id, 'lastname') || ''
        }`
    },
    {
      field: 'birthdayFormatted',
      headerName: 'Date de naissance',
      width: 250,
      valueGetter: (params) => {
        const birthday = params.getValue(params.id, 'birth');
        return `${fDate(birthday) || ''}`;
      }
    },
    {
      field: 'adresse',
      headerName: 'Adresse',
      width: 350,
      valueGetter: (params) =>
        `${params.getValue(params.id, 'adress') || ''} ${
          params.getValue(params.id, 'postalcode') || ''
        } ${params.getValue(params.id, 'city') || ''}`
    },
    {
      field: 'createdAt',
      headerName: 'Créé le',
      width: 150,
      valueGetter: (params) => {
        const { row } = params;
        if (!row.createdAt) return '';
        try {
          return `${fDateTime(row.createdAt)}`;
        } catch (error) {
          return '';
        }
      }
    },
    {
      field: 'updatedAt',
      headerName: 'Mise à jour le',
      width: 150,
      valueGetter: (params) => {
        const { row } = params;
        if (!row.updatedAt) return '';
        try {
          return `${fDateTime(row.updatedAt)}`;
        } catch (error) {
          return '';
        }
      }
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 250,
      renderCell: (params) => {
        try {
          const { id, row } = params;
          return (
            <Box display="flex" alignItems="center">
              <IconButton
                size="small"
                to={`${PATH_APP.editPatient}/${id}`}
                variant="outline"
                color="primary"
                component={RouterLink}
              >
                <EditIcon />
              </IconButton>

              <ActionBtnPatient
                title={`Suppression du dossier du patient ${row.firstname}`}
                msg="Le dossier du patient sera supprimé. Confirmez-vous ?"
                notifMsg="Suppression avec succès."
                icon={<DeleteForeverIcon />}
                callback={() => handleDeletePatient(row)}
                color="error"
              />
              {row.lockOff ? (
                <ActionBtnPatient
                  title={`Réouverture du dossier du patient ${row.firstname}`}
                  msg="Le dossier du patient sera réactivé pour l'édition. Confirmez-vous ?"
                  notifMsg="Réactivation avec succès."
                  icon={<LockOpenIcon />}
                  callback={() => handleChangeLockPatient(row)}
                  color="primary"
                />
              ) : (
                <ActionBtnPatient
                  title={`Verrouillage du dossier du patient ${row.firstname}`}
                  msg="Le dossier du patient sera en lecture seul. Confirmez-vous ?"
                  notifMsg="Verrouillage avec succès."
                  icon={<LockIcon />}
                  color="warning"
                  callback={() => handleChangeLockPatient(row)}
                />
              )}
            </Box>
          );
        } catch (error) {
          return <></>;
        }
      }
    }
  ];

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
      />
    </>
  );
};
