import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';

// ----------------------------------------------------------------------
export default function ImgFromSvg({ svgData64, sx }) {
  const [imageObj, setimageObj] = useState(null);
  useEffect(() => {
    return () => {
      setimageObj(null);
    };
  }, []);
  useEffect(() => {
    if (!svgData64) return;
    const svgStr = atob(svgData64)
    const svg = new Blob([svgStr], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(svg);
     setimageObj(url);
  }, [svgData64]);
  if (!imageObj) return <></>;
  return (
    <>
      <Box component="img" alt="" src={imageObj} sx={{ ...sx }} />
    </>
  );
}
