import React, { useMemo, useState, useRef, useEffect, useCallback } from 'react';
import * as d3 from 'd3';
import { SVGEDITOR_POINT_RADIUS } from 'config/appConfig';
// ----------------------------------------------------------------------

const PointsDrawPath = React.forwardRef(({ thickness }, ref) => {
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!ref || !ref.current) return;
    const svg = d3.select(ref.current);
    const g = svg.select('#groupPointsDrawPath');
    g.attr('cursor', 'crosshair');
    g.on('click', (e) => drawCircle(e));
  }, [ref]);
  // ----------------------------------------------------------------------
  const drawCircle = (event) => {
    const [x, y] = d3.pointer(event);
    if (!ref || !ref.current) return;
    const svg = d3.select(ref.current);
    const g = svg.select('#groupPointsDrawPath');
    const allCircle = g.selectAll('circle');
    const lastId = allCircle.nodes().length;
    g.append('circle')
      .attr('id', `a_${Number(lastId) + 1}`)
      .attr('cx', x)
      .attr('cy', y)
      .attr('r', Number(SVGEDITOR_POINT_RADIUS) - 1)
      .attr('fill', 'transparent')
      .style('stroke', '#FF4933')
      .style('strokeWidth', 3);
  };
  return (
    <g id="groupPointsDrawPath">
      <rect x={0} y={0} width="100%" height="100%" style={{ fill: 'red' }} opacity={0} />
    </g>
  );
});
export default PointsDrawPath;
