import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Button, Checkbox, FormControlLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import useNotification from 'hooks/useNotification';
import useReferentiel from 'hooks/useReferentiel';
import ColorPicker from 'components/color/ColorPicker';
import { API_URL } from 'config/appConfig';
import SimpleUploadFile from 'components/file/SimpleUploadFile';
import ImgBoxFromUrl from 'components/media/ImgBoxFromUrl';
import faker from 'faker';
import AverageValuesForm from './AverageValuesForm';
// ----------------------------------------------------------------------

export default function EditAngleBtn({ editAngle }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-' + faker.datatype.uuid() : undefined;

  return (
    <>
      {editAngle ? (
        <IconButton onClick={handleClick}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button onClick={handleClick}>
          <AddIcon /> Ajouter un angle
        </Button>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => console.log()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Paper sx={{ width: 520, maxWidth: '100%', px: 3 }}>
          <FormEdit editAngle={editAngle} onComplete={() => handleClose()} />
        </Paper>
      </Popover>
    </>
  );
}
const FormEdit = ({ editAngle, onComplete }) => {
  const [color, setcolor] = useState(null);
  const { notif } = useNotification();
  const { refs, saveAngleFormdata, saveAngle, initRefs } = useReferentiel();
  useEffect(() => {
    if (!editAngle || !editAngle.color) {
      setcolor('#000000');
    } else {
      setcolor(editAngle.color);
    }
  }, [editAngle]);

  const radioSchema = Yup.object().shape({
    code: Yup.number().required(`Veuillez renseigner le code`),
    label: Yup.string().max(100).required(`Veuillez renseigner le libellé`),
    indication: Yup.string().max(300).required(`Veuillez renseigner l'indication`),
    lineOne: Yup.string().required(`Veuillez renseigner le plan 1`),
    isLineOneInverted: Yup.boolean(),
    lineTwo: Yup.string().required(`Veuillez renseigner le plan 2`),
    isLineTwoInverted: Yup.boolean(),
    isSuperior: Yup.boolean().required(
      `Veuillez renseigner si c'est l'angle est une valeur supérieure`
    )
  });
  const formik = useFormik({
    initialValues: {
      code: editAngle && editAngle.code ? editAngle.code : '',
      color: editAngle && editAngle.color ? editAngle.color : '',
      label: editAngle && editAngle.label ? editAngle.label : '',
      indication: editAngle && editAngle.indication ? editAngle.indication : '',
      lineOne: editAngle && editAngle.lineOne ? editAngle.lineOne.id : '',
      lineTwo: editAngle && editAngle.lineTwo ? editAngle.lineTwo.id : '',
      isSuperior: editAngle && editAngle.isSuperior ? true : false,
      isLineOneInverted: editAngle && editAngle.isLineOneInverted ? true : false,
      isLineTwoInverted: editAngle && editAngle.isLineTwoInverted ? true : false
    },
    validationSchema: radioSchema,
    onSubmit: async () => {
      try {
        if (String(values.lineOne) === String(values.lineTwo)) {
          throw new Error('Sauvegarde échoué car 2 plans sont identiques.');
        }
        const newData =
          editAngle && editAngle.id ? { ...editAngle, ...values, color } : { ...values, color };
        /* in case file upload is choiced we need to make formdata */
        const isFormdata = values.image;
        if (isFormdata) {
          const formData = new FormData();

          if (values.image) {
            delete newData.image;
            formData.append('image', values.image);
          }
          Object.entries(newData).forEach(([e, k]) => {
            formData.append(e, k);
          });

          await saveAngleFormdata(newData, formData);
        } else {
          await saveAngle({
            ...newData
          });
        }

        await initRefs();
        notif('Sauvegarde réussie');
        onComplete();
      } catch (error) {
        notif(null, error);
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange
  } = formik;
  const { refBuildingLine } = refs;
  const imageUrl = editAngle && editAngle.image ? `${API_URL.file}/${editAngle.image}` : null;
  return (
    <>
      <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-end">
        <IconButton onClick={onComplete} sx={{ my: 2 }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ mb: 5 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {color && <ColorPicker color={color} hanldeColor={(color) => setcolor(color)} />}
              <AverageValuesForm
                averageValue={editAngle && editAngle.averageValue ? editAngle.averageValue : []}
                onComplete={(values) => setFieldValue('averageValue', values)}
              />
            </Box>

            <TextField
              fullWidth
              type="number"
              label="Code"
              {...getFieldProps('code')}
              error={Boolean(touched.code && errors.code)}
              helperText={touched.code && errors.code}
            />
            <TextField
              fullWidth
              type="text"
              label="Libellé"
              {...getFieldProps('label')}
              error={Boolean(touched.label && errors.label)}
              helperText={touched.label && errors.label}
            />
            <TextField
              fullWidth
              type="text"
              label="Indication"
              {...getFieldProps('indication')}
              error={Boolean(touched.indication && errors.indication)}
              helperText={touched.indication && errors.indication}
            />

            <TextField
              fullWidth
              select
              label="Plan 1"
              {...getFieldProps('lineOne')}
              error={Boolean(touched.lineOne && errors.lineOne)}
              helperText={touched.lineOne && errors.lineOne}
            >
              {refBuildingLine.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <FormControlLabel
              control={<Checkbox checked={values.isLineOneInverted} color="primary" />}
              label="Inverser les points du plan 1 ?"
              name="isLineOneInverted"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              select
              label="Plan 2"
              {...getFieldProps('lineTwo')}
              error={Boolean(touched.lineTwo && errors.lineTwo)}
              helperText={touched.lineTwo && errors.lineTwo}
            >
              {refBuildingLine.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <FormControlLabel
              control={<Checkbox checked={values.isLineTwoInverted} color="primary" />}
              label="Inverser les points du plan 2 ?"
              name="isLineTwoInverted"
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Checkbox checked={values.isSuperior} color="primary" />}
              label="Prendre la valeur supérieure ?"
              name="isSuperior"
              onChange={handleChange}
            />
            <Stack direction="row" spacing={2}>
              {imageUrl && (
                <ImgBoxFromUrl
                  image={imageUrl}
                  sx={{
                    borderRadius: 1,
                    objectFit: 'cover',
                    width: '150px',
                    height: 'auto'
                  }}
                />
              )}
              <SimpleUploadFile
                value={values.image}
                label="Image d'illustration"
                onChange={(val) => setFieldValue('image', val)}
                caption="(Les formats *.jpeg and *.png sont acceptés)"
                error={Boolean(touched.image && errors.image)}
                sx={{ maxHeight: '120px' }}
                acceptFile={{
                  accept: {
                    'image/jpeg': ['.jpeg', '.png']
                  }
                }}
                mediaType="image"
              />
            </Stack>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mb: 10 }}
          >
            Valider
          </LoadingButton>
          <Box sx={{ width: '100%', height: '60px' }} />
        </Form>
      </FormikProvider>
    </>
  );
};
