import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Page from 'components/Page';
import useReferentiel from 'hooks/useReferentiel';
import EditRadioCard from './EditRadioCard';
import EditRadioBtn from './EditRadioBtn';
// ----------------------------------------------------------------------

export default function RadiologiesSettings() {
  const { refs, initRefs } = useReferentiel();
  useEffect(() => {
    initRefs();
  }, []);
  if (!refs) return <></>;
  const currentRadio =
    Array.isArray(refs.refRadiologies) && refs.refRadiologies.length > 0
      ? refs.refRadiologies[0]
      : null;
  return (
    <Page
      title="Configuration des points et leurs position par défault | OCS - Orthodontie | Céphalométrie | Superposition"
      style={{ height: '100%' }}
    >
      <Container maxWidth="xl" style={{ height: '100%' }}>
        <Box sx={{ pb: 5 }} display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h4">Les radiologies de référence</Typography>
          </Box>
          {!currentRadio && (
            <Box display="flex" justifyContent="flex-end" sx={{ my: 2 }}>
              <EditRadioBtn />
            </Box>
          )}
        </Box>
        {currentRadio ? (
          <EditRadioCard editRadio={currentRadio} />
        ) : (
          <Box display="flex" justifyContent="center" sx={{ my: 6, width: '100%' }}>
            <Typography color="error" variant="h4">
              Aucun référentiel de radiologie trouvé
            </Typography>
          </Box>
        )}
      </Container>
    </Page>
  );
}
