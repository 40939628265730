import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Page from 'components/Page';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { API_URL } from 'config/appConfig';
import { fDate } from 'utils/formatTime';
import { PATH_APP } from 'config/paths';
import { IconButton } from '@mui/material';
import Fab from '@mui/material/Fab';

import useReferentiel from 'hooks/useReferentiel';
import EditLineBtn from './EditLineBtn';
// ----------------------------------------------------------------------
export default function LinesSettings() {
  const { refs, initRefs } = useReferentiel();
  useEffect(() => {
    initRefs();
  }, []);
  if (!refs) return <></>;
  const currentRadio =
    Array.isArray(refs.refRadiologies) && refs.refRadiologies.length > 0
      ? refs.refRadiologies[0]
      : null;
  return (
    <Page
      title="Configuration des points et leurs position par défault | OCS - Orthodontie | Céphalométrie | Superposition"
      style={{ height: '100%' }}
    >
      <Container maxWidth="xl" style={{ height: '100%' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h4">Liste des plans</Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <EditLineBtn />
        </Box>
        {refs.refBuildingLine && <Grid items={refs.refBuildingLine} />}
      </Container>
    </Page>
  );
}
const Grid = ({ items, ...props }) => {
  if (!items) return <></>;
  const rows = items;
  const columns = [
    {
      field: 'codeField',
      headerName: 'Code',
      width: 80,
      sortable: true,
      renderCell: (params) => {
        try {
          const { row } = params;
          return (
            <Fab size="small" sx={{ backgroundColor: row.color }} aria-label="edit">
              {row.code}
            </Fab>
          );
        } catch (error) {
          return <></>;
        }
      }
    },
    {
      field: 'label',
      headerName: 'Libellé',
      sortable: true,
      width: 250
    },
    {
      field: 'indication',
      headerName: 'Indication',
      sortable: true,
      width: 250
    },
    {
      field: 'startPointField',
      headerName: 'Point début',
      width: 150,
      valueGetter: (params) => {
        try {
          const { row } = params;
          return `${row.startPoint.label} (${row.startPoint.code})`;
        } catch (error) {
          return '';
        }
      }
    },
    {
      field: 'endPointField',
      headerName: 'Point final',
      width: 200,
      valueGetter: (params) => {
        try {
          const { row } = params;
          return `${row.endPoint.label} (${row.endPoint.code})`;
        } catch (error) {
          return '';
        }
      }
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        try {
          return (
            <Box display="flex" alignItems="center">
              <EditLineBtn editLine={params.row} />
            </Box>
          );
        } catch (error) {
          return <></>;
        }
      }
    }
  ];

  return (
    <>
      <DataGrid
        sx={{ pt: 3 }}
        rows={rows}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[25, 50]}
        disableSelectionOnClick
      />
    </>
  );
};
