import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import LoadingScreen from 'components/LoadingScreen';
import RequireAuth from 'auth/RequireAuth';
import RequireGuest from 'auth/RequireGuest';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import DashboardLayout from 'layouts/dashboard';

import PatientGrid from 'pages/patient/PatientGrid';
import EditPatient from 'pages/patient/EditPatient';
import AddPatient from 'pages/patient/AddPatient';
import PatientInfoPage from 'pages/patient/PatientInfoPage';
import PointTracingPage from 'pages/patient/pointtracing/PointTracingPage';
import SuperpositionPage from 'pages/patient/superposition/SuperpositionPage';
import ReportPage from 'pages/patient/report/ReportPage';
import EditReportPage from 'pages/patient/report/EditReportPage';
import RadiologiesSettings from 'pages/settings/RadiologiesSettings';
import LinesSettings from 'pages/settings/LinesSettings';
import PointsSettings from 'pages/settings/PointsSettings';
import AnglesSettings from 'pages/settings/AnglesSettings';
import DistancesSettings from 'pages/settings/DistancesSettings';
import TracingsSettings from 'pages/settings/TracingsSettings';
import SuperSettings from 'pages/settings/SuperSettings';
import ReportSettings from 'pages/settings/ReportSettings';

import Admin from 'pages/Admin';
import Login from 'pages/Login';
import NotFound from 'pages/Page404';

// ----------------------------------------------------------------------
export default function Router() {
  const appRoutes = useRoutes([
    {
      path: '/app',
      element: (
        <RequireAuth>
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [
        { path: '', element: <PatientGrid /> },
        { path: 'dashboard', element: <PatientGrid /> },
        { path: 'patients', element: <PatientGrid /> },
        { path: 'add-patient', element: <AddPatient /> },
        {
          path: 'edit-patient/:idPatient',
          element: <EditPatient />,
          children: [
            { path: '', element: <PatientInfoPage /> },
            { path: 'info', element: <PatientInfoPage /> },
            { path: 'point-tracing', element: <PointTracingPage /> },
            { path: 'superposition', element: <SuperpositionPage /> },
            { path: 'report', element: <ReportPage /> },
            { path: 'edit-report/:idReport', element: <EditReportPage /> }
          ]
        },
        { path: 'radios-settings', element: <RadiologiesSettings /> },
        { path: 'points-settings', element: <PointsSettings /> },
        { path: 'lines-settings', element: <LinesSettings /> },
        { path: 'angles-settings', element: <AnglesSettings /> },
        { path: 'distances-settings', element: <DistancesSettings /> },
        { path: 'tracings-settings', element: <TracingsSettings /> },
        /*{ path: 'superposition-settings', element: <SuperSettings /> },*/
        { path: 'report-settings', element: <ReportSettings /> },
        { path: 'admin', element: <Admin /> }
      ]
    },
    /* general */
    {
      path: '/',
      element: (
        <RequireGuest>
          <LogoOnlyLayout />
        </RequireGuest>
      ),
      children: [
        { path: '', element: <Login /> },
        { path: 'login', element: <Login /> }
      ]
    },
    { path: '404', element: <NotFound /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
  return <React.Suspense fallback={<LoadingScreen />}>{appRoutes}</React.Suspense>;
}
