import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import { fDateTimeSuffix } from 'utils/formatTime';

import {
  API_URL,
  SVGEDITOR_WIDTH,
  SVGEDITOR_HEIGHT,
  SVGEDITOR_TOOLBAR_WIDTH
} from 'config/appConfig';
import SupersSvgEditor from 'components/d3/SupersSvgEditor';
import axios from 'axios';
import { blobToBase64 } from 'utils/imageUtil';
import useNotification from 'hooks/useNotification';
import useReferentiel from 'hooks/useReferentiel';
import { drawSuperLineMaxi, drawSuperLineMand } from 'utils/pathUtil';
// ----------------------------------------------------------------------
export default function DialogSuperSettings({ superpositions, tracings, points, radio }) {
  const [editSupers, seteditSupers] = useState(null);
  const [img64, setimg64] = useState(null);
  const [open, setOpen] = React.useState(false);
  const { notif } = useNotification();
  const { saveSuperPos, initRefs, resetSuper } = useReferentiel();
  const [displayPoints, setdisplayPoints] = useState(true);
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!superpositions) return;
    if (!tracings) return;
    if (!points) return;
    if (!radio) return;
    try {
      const arr = [];
      superpositions.forEach((it) => {
        const newIt = { ...it };
        if (!Array.isArray(newIt.points) || newIt.points.length === 0) {
          newIt.points = [...points];
        }
        if (!newIt.radio) {
          newIt.radio = { ...radio };
        }
        if (!Array.isArray(newIt.tracings) || newIt.tracings.length === 0) {
          const code = Number(newIt.code);
          if (code === 1) {
            /* superposition general */
            newIt.tracings = [...tracings];
          } else if (code === 2) {
            /* superposition maxillaire */
            const arrTracing = tracings.filter((t) => Number(t.code) >= 3 && Number(t.code) <= 5);
            const tracingMaxi = tracings.find((t) => Number(t.code) === 2);
            arrTracing.push(tracingMaxi);
            /* add ligne implantaire */
            const implanLinePath = drawSuperLineMaxi(tracingMaxi);
            const implanLine = {
              id: 'implanLine',
              code: 101,
              label: 'Ligne implantaire',
              color: 'green',
              imgpath: implanLinePath,
              movable: true
            };
            arrTracing.push(implanLine);

            newIt.tracings = [...arrTracing];
          } else if (code === 3) {
            /* superposition mandibulaire */
            const arrTracing = tracings.filter((t) => Number(t.code) >= 6 && Number(t.code) <= 9);
            const pointPogGn = points.filter((p) => Number(p.code) === 17 || Number(p.code) === 19);
            const from = { x: pointPogGn[0].xposition, y: pointPogGn[0].yposition };
            const to = { x: pointPogGn[1].xposition, y: pointPogGn[1].yposition };
            /* add ligne implantaire */
            const implanLinePath = drawSuperLineMand(from, to);
            const implanLine = {
              id: 'implanLine',
              code: 102,
              label: 'Ligne implantaire',
              color: 'green',
              imgpath: implanLinePath,
              movable: true
            };
            arrTracing.push(implanLine);

            newIt.tracings = [...arrTracing];
          }
        }
        arr.push(newIt);
      });
      seteditSupers(arr);
    } catch (error) {
      console.error(error);
      seteditSupers(null);
    }
  }, [superpositions, tracings, points, radio]);

  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!radio) return;
    if (!radio.imgFile) return;
    const init = async () => {
      try {
        const url = `${API_URL.file}/${radio.imgFile}`;
        const response = await axios.get(url, {
          responseType: 'arraybuffer'
        });
        const blob = new Blob([response.data], {
          type: response.headers['content-type']
        });
        // const imageDataUrl = URL.createObjectURL(blob);
        const data64 = await blobToBase64(blob);
        setimg64(data64);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [radio]);
  // ----------------------------------------------------------------------
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handeComplete = async (newEditSuper) => {
    await saveSuperPos(newEditSuper);
    await initRefs();
    notif('Sauvegarde réussie');
  };
  const handleReset = async (currentSuper) => {
    if (!currentSuper) return;
    await resetSuper(currentSuper);
    await initRefs();
  };
  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        <PictureInPictureAltIcon sx={{ ml: 2 }} />
        Modifier les superpositions
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            ml: `${SVGEDITOR_TOOLBAR_WIDTH}px`,
            zIndex: 1300
          }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              Modifier les superpositions
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {img64 && editSupers && (
          <SupersSvgEditor
            title="Modifier les superpositions"
            handleClose={handleClose}
            width={SVGEDITOR_WIDTH}
            height={SVGEDITOR_HEIGHT}
            img64={img64}
            superpositions={editSupers}
            onComplete={handeComplete}
            onReset={handleReset}
            points={points}
            displayPoints={displayPoints}
            onChangeDisplayPoints={(display) => setdisplayPoints(display)}
          />
        )}
      </Dialog>
    </>
  );
}
