import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { initEditSvg, updateEditSvg, resetEditSvg } from 'redux/slices/svgSlice';
import * as d3 from 'd3';
import { getMovePoint, getBoundingBoxCenter, getControlPointFromPath } from 'utils/pathUtil';
// ----------------------------------------------------------------------
export default function useEditPath() {
  const dispatch = useDispatch();
  const { editSvg, updatedEditAt, histories } = useSelector((state) => state.editSvg);
  const editGroupRef = useRef({ editGroup: null, createdAt: null, histoGroup: [] });
  return {
    editSvg,
    updatedEditAt,
    histories,
    initEditSvgGroup: (editGroup) => {
      if (!editGroup) return;
      const arr = getStateEditSvg(editGroup);
      const dt = new Date();
      const obj = { id: editGroup, createdAt: dt, groups: arr };
      editGroupRef.current = {
        editGroup: obj,
        createdAt: dt,
        histoGroup: [obj]
      };
    },
    getEditGroupRef: () => {
      return editGroupRef.current;
    },
    getHistories: () => {
      return editGroupRef.current.histoGroup;
    },
    handleUpdateSvgGroup: (editGroup) => {
      if (!editGroup) return;
      const dt = new Date();
      console.log('update svgGroup', editGroup);
      const newEditGroup = { ...editGroupRef.current.editGroup };
      const arr = getStateEditSvg(editGroup);
      newEditGroup.groups = arr;
      newEditGroup.createdAt = dt;
      const neHisto = Array.isArray(histories) ? [...editGroupRef.current.histoGroup] : [];
      neHisto.push({
        ...newEditGroup
      });
      editGroupRef.current = {
        editGroup: { ...newEditGroup },
        createdAt: dt,
        histoGroup: [...neHisto]
      };
      /*
      dispatch(
        updateEditSvg({
          editSvg: newEditSvg,
          updatedEditAt,
          histories: neHisto
        })
      );*/
    },
    restoreToStep: (editGroupObj) => {
      if (!editGroupObj) return;
      const { id, createdAt, groups } = editGroupObj;
      editGroupRef.current = {
        editGroup: { ...editGroupObj },
        createdAt: createdAt,
        histoGroup: [...editGroupRef.current.histoGroup]
      };
      d3.select(`#${id}`)
        .selectAll('g')
        .nodes()
        .forEach((group) => {
          const selGroup = d3.select(group);
          const idGroup = selGroup.attr('id');
          const paths = [];
          selGroup
            .selectAll('path')
            .nodes()
            .forEach((path) => {
              const selPath = d3.select(path);
              const idPath = selPath.attr('id');
              const idUse = selPath.attr('idUse');
              const pathFoundedFromObj = findPathByIdFromGroup(groups, idGroup, idPath);
              const pointMove = getMovePoint(pathFoundedFromObj.d);
              const { x, y } = pointMove;
              selPath.attr('d', pathFoundedFromObj.d);
              d3.select(`#${idUse}`).attr('x', x).attr('y', y);
            });
        });
    },
    redrawUsesForGroup: (idGroup) => {
      if (!idGroup) return;
      d3.select(`#${idGroup}`)
        .selectAll('path')
        .nodes()
        .forEach((path) => {
          const selPath = d3.select(path);
          const idUse = selPath.attr('idUse');
          const pointMove = getMovePoint(selPath.attr('d'));
          const { x, y } = pointMove;
          d3.select(`#${idUse}`).attr('x', x).attr('y', y);
        });
    },

    initEditPath: (idPath, currentPath, centerPoint) => {
      if (!idPath) return;
      dispatch(
        initEditSvg({
          idPath,
          currentPath,
          centerPoint
        })
      );
    },
    resetEditPath: () => {
      dispatch(resetEditSvg());
    },
    handleUpdateChangePath: (idPathUpdated) => {
      if (!editSvg) return;
      const { idPath } = editSvg;
      if (!idPath) return;
      const currentPath = d3.select(`#${idPath}`).attr('d');
      const centerPoint = getBoundingBoxCenter(d3.select(`#${idPath}`));
      const content = d3.select(`#${idPath}`).attr('d');
      const newEditSvg = { ...editSvg, currentPath, centerPoint };
      const updatedEditAt = new Date();
      const neHisto = Array.isArray(histories) ? [...histories] : [];
      neHisto.push({ editSvg, updatedEditAt, content });
      dispatch(
        updateEditSvg({
          editSvg: newEditSvg,
          updatedEditAt,
          histories: neHisto
        })
      );
    },
    redrawPoints: (idPath) => {
      try {
        if (!idPath) return;
        const pathFounded = d3.select(`#${idPath}`);
        const circles = getControlPointFromPath(idPath);
        if (!circles) return;
        const idGroup = pathFounded.attr('idGroup');
        const gGroup = d3.select(`#${idGroup}`);
        const g = gGroup.select('#controlGroup');
        if (!g) return;
        circles.forEach((d) => {
          d3.select(`#${d.id}`).attr('cx', d.cx).attr('cy', d.cy);
        });
      } catch (error) {
        console.error(error);
      }
    }
  };
}
const getStateEditSvg = (editGroup) => {
  if (!editGroup) return;
  const arr = [];
  d3.select(`#${editGroup}`)
    .selectAll('g')
    .nodes()
    .forEach((group) => {
      const selGroup = d3.select(group);
      const idGroup = selGroup.attr('id');
      const paths = [];
      selGroup
        .selectAll('path')
        .nodes()
        .forEach((path) => {
          const selPath = d3.select(path);
          const idPath = selPath.attr('id');
          const d = selPath.attr('d');
          const idUse = selPath.attr('idUse');
          const centerPointPath = getBoundingBoxCenter(selPath);
          paths.push({ id: idPath, idUse, d, centerPoint: centerPointPath });
        });
      const centerPointGroup = getBoundingBoxCenter(selGroup);
      arr.push({ id: idGroup, paths, centerPoint: centerPointGroup });
    });
  return arr;
};

const findPathByIdFromGroup = (groups, idGroup, idPath) => {
  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];
    if (group.id === idGroup) {
      for (let j = 0; j < group.paths.length; j++) {
        const path = group.paths[j];
        if (path.id === idPath) {
          return path;
        }
      }
    }
  }
  return null;
};
