import React, { useEffect } from 'react';
import { Tabs, Tab, Box, Container, Typography } from '@mui/material';
import useSuperposition from 'hooks/useSuperposition';
import usePointTracing from 'hooks/usePointTracing';
import usePatient from 'hooks/usePatient';
import { fDateTime } from 'utils/formatTime';

import FormSuperposition from './FormSuperposition';
import EditSuperposition from './EditSuperposition';
import FormCombineSuperposition from './FormCombineSuperposition';
// ----------------------------------------------------------------------
export default function SuperpositionPage() {
  const { superpositions, initSuperposition } = useSuperposition();
  const { initPointTracing } = usePointTracing();
  const { patient } = usePatient();
  useEffect(() => {
    if (!patient) return;
    initSuperposition();
    initPointTracing();
  }, [patient]);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getTabs = () => {
    try {
      const tabs = [];
      if (superpositions) {
        superpositions.forEach((it, i) => {
          tabs.push({ code: i, item: it });
        });
      }
      return tabs;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  const getTabPanel = () => {
    try {
      if (!Array.isArray(superpositions) || superpositions.length === 0)
        return (
          <>
            <Typography variant="h4" style={{ textAlign: 'center' }}>
              Aucune superposition n'est effectuée.
            </Typography>
          </>
        );

      const editSuperposition = superpositions.find((it, i) => i === value);
      if (!editSuperposition) return <>Aucune superposition n'est trouvée</>;
      return (
        <TabPanel value={value} index={value}>
          <EditSuperposition editSuperposition={editSuperposition} onDelete={() => setValue(0)} />
        </TabPanel>
      );
    } catch (error) {
      console.error(error);
      return <></>;
    }
  };
  /**  */
  const onFinish = (idx) => {
    setTimeout(() => {
      if (idx) {
        setValue(idx);
      } else {
        setValue(superpositions.length);
      }
    }, 200);
  };
  const superpositionTabs = getTabs();
  return (
    <>
      <Box display="flex" justifyContent="flex-end">
       {/*  <FormCombineSuperposition onFinish={onFinish} />
        <Box sx={{ mx: 1 }} /> */}
        <FormSuperposition onFinish={onFinish} />
      </Box>
      {Array.isArray(superpositions) && (
        <>
          <Tabs value={value} onChange={handleChange} aria-label="Superposition tab">
            {superpositionTabs.map((it, i) => {
              return <Tab key={i} label={it.item.label} value={i} />;
            })}
          </Tabs>
        </>
      )}

      {getTabPanel()}
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
