import React, { useRef, useEffect, useState } from 'react';
import {
  Popover,
  Dialog,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  IconButton
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import useNotification from 'hooks/useNotification';

// ----------------------------------------------------------------------
export default function ActionBtnPatient({
  title,
  msg,
  label,
  notifMsg,
  icon,
  color,
  variant,
  callback,
  ...props
}) {
  const { notif } = useNotification();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = async () => {
    try {
      await callback();
      setAnchorEl(null);
      if (notifMsg) {
        notif(notifMsg);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  if (!msg) return <></>;
  const btnColor = color ? color : 'info';
  const btnVariant = variant ? variant : 'outline';
  return (
    <>
      {label ? (
        <Button variant={btnVariant} color={btnColor} onClick={handleClick}>
          {icon}
          {label}
        </Button>
      ) : (
        <IconButton
          onClick={handleClick}
          sx={{ mx: 2 }}
          size="small"
          variant={btnVariant}
          color={btnColor}
        >
          {icon}
        </IconButton>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Card
          sx={{
            minWidth: 275
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {msg}
            </Typography>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button color="error" onClick={handleConfirm}>
              Valider
            </Button>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
}
