import React, { useEffect, useRef, useState } from 'react';
import {
  OutlinedInput,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel,
  ListItemText,
  Select
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import useNotification from 'hooks/useNotification';
import useReferentiel from 'hooks/useReferentiel';
import ColorPicker from 'components/color/ColorPicker';
import { API_URL } from 'config/appConfig';
import SimpleUploadFile from 'components/file/SimpleUploadFile';
import ImgBoxFromUrl from 'components/media/ImgBoxFromUrl';
import faker from 'faker';
// ----------------------------------------------------------------------

export default function EditReportSectionBtn({ editReportSection }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-' + faker.datatype.uuid() : undefined;

  return (
    <>
      {editReportSection ? (
        <IconButton onClick={handleClick}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button onClick={handleClick}>
          <AddIcon /> Ajouter une section de rapport
        </Button>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => console.log()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Paper sx={{ width: 520, maxWidth: '100%', px: 3 }}>
          <FormEdit editReportSection={editReportSection} onComplete={() => handleClose()} />
        </Paper>
      </Popover>
    </>
  );
}
const FormEdit = ({ editReportSection, onComplete }) => {
  const { notif } = useNotification();
  const { refs, saveReportSection, initRefs } = useReferentiel();

  const reportSectionSchema = Yup.object().shape({
    code: Yup.number().required(`Veuillez renseigner le code`),
    label: Yup.string()
      .max(100, `100 charactères maximum`)
      .required(`Veuillez renseigner le libellé`),
    title: Yup.string()
      .max(100, `100 charactères maximum`)
      .required(`Veuillez renseigner le titre`),
    content: Yup.string().max(200, `200 charactères maximum`)
  });
  const formik = useFormik({
    initialValues: {
      code: editReportSection && editReportSection.code ? editReportSection.code : '',
      label: editReportSection && editReportSection.label ? editReportSection.label : '',
      title: editReportSection && editReportSection.title ? editReportSection.title : '',
      content: editReportSection && editReportSection.content ? editReportSection.content : ''
    },
    validationSchema: reportSectionSchema,
    onSubmit: async () => {
      try {
        const newData =
          editReportSection && editReportSection.id
            ? { ...editReportSection, ...values }
            : { ...values };
        await saveReportSection({
          ...newData
        });
        await initRefs();
        notif('Sauvegarde réussie');
        onComplete();
      } catch (error) {
        notif(null, error);
      }
    }
  });
  const onChangeIndicators = (indicators) => {
    setFieldValue('indicators', indicators);
  };
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange
  } = formik;
  const { refBuildingLine, refAngles, refDistances } = refs;
  return (
    <>
      <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-end">
        <IconButton onClick={onComplete} sx={{ my: 2 }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ mb: 5 }}>
            <TextField
              fullWidth
              type="number"
              label="Code"
              {...getFieldProps('code')}
              error={Boolean(touched.code && errors.code)}
              helperText={touched.code && errors.code}
            />
            <TextField
              fullWidth
              type="text"
              label="Libellé"
              {...getFieldProps('label')}
              error={Boolean(touched.label && errors.label)}
              helperText={touched.label && errors.label}
            />
            <TextField
              fullWidth
              type="text"
              label="Titre"
              {...getFieldProps('title')}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
            />

            <TextField
              fullWidth
              type="text"
              label="Contenu texte"
              multiline
              rows={3}
              {...getFieldProps('content')}
              error={Boolean(touched.content && errors.content)}
              helperText={touched.content && errors.content}
            />
            <SelectIndicator
              onChangeIndicators={onChangeIndicators}
              editReportSection={editReportSection}
              refBuildingLine={refBuildingLine}
              refAngles={refAngles}
              refDistances={refDistances}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mb: 10 }}
          >
            Valider
          </LoadingButton>
          <Box sx={{ width: '100%', height: '60px' }} />
        </Form>
      </FormikProvider>
    </>
  );
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const SelectIndicator = ({
  editReportSection,
  refBuildingLine,
  refAngles,
  refDistances,
  onChangeIndicators
}) => {
  const [indicators, setindicators] = useState([]);
  const [refData, setrefData] = useState(null);
  useEffect(() => {
    if (!refData) return;
    const arr = refData.filter((it) => indicators.includes(it.id));
    onChangeIndicators(arr);
  }, [indicators, refData]);

  useEffect(() => {
    if (!refBuildingLine) return;
    if (!refAngles) return;
    if (!refDistances) return;
    const arr = [];
    refBuildingLine.forEach((it) => {
      const { code, id, label } = it;
      arr.push({ code, id, label, type: 'distances' });
    });
    refDistances.forEach((it) => {
      const { code, id, label } = it;
      arr.push({ code, id, label, type: 'distances' });
    });
    refAngles.forEach((it) => {
      const { code, id, label } = it;
      arr.push({ code, id, label, type: 'angles' });
    });

    setrefData(arr);
  }, [refBuildingLine, refAngles, refDistances]);

  useEffect(() => {
    if (!editReportSection) return;
    if (!editReportSection.indicators) return;
    const arr = editReportSection.indicators.map((it) => it.id);
    setindicators(arr);
  }, [editReportSection]);

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setindicators(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  if (!refData) return <></>;
  return (
    <Select
      fullWidth
      multiple
      label="Sélectionner les indicateurs"
      value={indicators}
      onChange={handleChange}
      input={<OutlinedInput label="Tag" />}
      renderValue={(selected) => {
        const selectedFromData = refData
          .filter((it) => {
            return selected.includes(it.id);
          })
          .map((it) => {
            return it.label;
          });
        return selectedFromData.join(', ');
      }}
      MenuProps={MenuProps}
    >
      {refData.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          <Checkbox checked={indicators.indexOf(option.id) > -1} />
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </Select>
  );
};
