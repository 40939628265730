import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Container, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import usePointTracing from 'hooks/usePointTracing';
import usePatient from 'hooks/usePatient';
import FormPointTracing from './FormPointTracing';
import EditPointTracing from './EditPointTracing';
// ----------------------------------------------------------------------
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme, bgColor }) => ({
  '&.Mui-selected': {
    color: bgColor ? 'white' : '',
    backgroundColor: bgColor
  }
}));

// ----------------------------------------------------------------------
export default function PointTracingPage() {
  const [pointsTabs, setpointsTabs] = useState(null);
  const { pointtracings, initPointTracing } = usePointTracing();
  const { patient } = usePatient();
  useEffect(() => {
    if (!patient || !patient.id) return;
    initPointTracing();
  }, [patient]);

  useEffect(() => {
    if (!pointtracings) return;
    try {
      const tabs = [];
      if (pointtracings) {
        pointtracings.forEach((it) => {
          tabs.push({ code: it.refstep.code, item: it });
        });
      }
      tabs.sort((a, b) => Number(a.code) - Number(b.code));
      if (tabs.length > 0) {
        setValue(tabs[0].code);
      }
      setpointsTabs(tabs);
    } catch (error) {
      return [];
    }
  }, [pointtracings]);
  const [value, setValue] = React.useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTabPanel = () => {
    try {
      if (!Array.isArray(pointtracings) || pointtracings.length === 0)
        return (
          <>
            <Typography variant="h4" style={{ textAlign: 'center' }}>
              Aucune étape n'est renseignée
            </Typography>
          </>
        );
      const editPoint = pointtracings.find((it) => it.refstep.code === value);
      if (!editPoint) return <></>;
      return (
        <TabPanel value={value} index={value}>
          <EditPointTracing editPoint={editPoint} onDelete={() => setValue(1)} />
        </TabPanel>
      );
    } catch (error) {
      console.error(error);
      return <></>;
    }
  };
  /**  */
  const onFinish = (idx) => {
    setTimeout(() => {
      console.log('finish', idx);
      if (idx) {
        setValue(idx);
      } else {
        setValue(pointtracings.length);
      }
    }, 200);
  };

  if (!patient) return <></>;
  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <FormPointTracing onFinish={onFinish} />
      </Box>
      {pointsTabs && value && (
        <>
          <Tabs value={value} onChange={handleChange} aria-label="Point tracing tab">
            {pointsTabs.map((it) => {
              const { item } = it;
              const selected = value === it.code;
              return (
                <StyledTab
                  key={it.code}
                  label={it.item.label}
                  value={it.code}
                  sx={{
                    px: 2
                  }}
                  bgColor={selected ? item.refstep.color : null}
                />
              );
            })}
          </Tabs>
          {getTabPanel()}
        </>
      )}
    </>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: '100%', marginTop: '20px' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
