import { SVGPathData } from 'svg-pathdata';
import * as d3 from 'd3';
import faker from 'faker';
import { TRANSFERT_GUIDE_DIMENSIONS } from 'config/appConfig';
import { COMMANDS_TYPE, LABELS } from './svgPathData';
import {
  distanceBetweenPoints,
  getPerpendLine,
  findProjectingPointOnLine,
  extendVector,
  extendVerticalVector,
  findPointOnLine,
  getPerpendLineVertical
} from './d3Util';

// ----------------------------------------------------------------------
export const drawPathFromCommands = (commands) => {
  return new SVGPathData(SVGPathData.encode(commands)).toAbs().encode();
};
export const getFirsPointMoveOfPath = (currentPath) => {
  const encodeToAbs = new SVGPathData(currentPath).toAbs().encode();
  const commands = SVGPathData.parse(encodeToAbs);
  return commands[0];
};
export const getAbsPoinsOfPath = (currentPath) => {
  const encodeToAbs = new SVGPathData(currentPath).toAbs().encode();
  const commands = SVGPathData.parse(encodeToAbs);
  return commands;
};
export const encodeToAbs = (currentPath) => {
  const encodeToAbs = new SVGPathData(currentPath).toAbs().encode();
  return encodeToAbs;
};
export const displayCommands = (p) => {
  try {
    let commands = p;
    if (typeof p === 'string') {
      commands = SVGPathData.parse(p);
    }
    commands.map((it) => {
      const labelFounded = LABELS.find((a) => Number(it.type) === Number(a.type));
      if (labelFounded) {
        it.label = labelFounded.label;
      }
      return { ...it };
    });
  } catch (error) {
    console.error(error);
  }
};
// ----------------------------------------------------------------------
export const updatePath = (idPath, d) => {
  try {
    const currentPath = d3.select(`#${idPath}`).attr('d');
    const encodeToAbs = new SVGPathData(currentPath).toAbs().encode();
    const commands = SVGPathData.parse(encodeToAbs);
    const newCommands = commands.map((it, i) => {
      const arrSplit = d.id.split('_');
      if (Number(arrSplit[1]) === i) {
        if (arrSplit.length === 2) {
          it.x = d.x;
          it.y = d.y;
          it.relative = false;
        } else if (arrSplit.includes('ctrl1')) {
          it.x1 = d.x;
          it.y1 = d.y;
          it.relative = false;
        } else if (arrSplit.includes('ctrl2')) {
          it.x2 = d.x;
          it.y2 = d.y;
          it.relative = false;
        } else if (arrSplit.includes('ctrl3')) {
          it.cX = d.x;
          it.cY = d.y;
          it.relative = false;
        }
      }

      return { ...it };
    });
    const newPath = SVGPathData.encode(newCommands);
    d3.select(`#${idPath}`).attr('d', newPath);
  } catch (error) {
    console.error(error);
  }
};
// ----------------------------------------------------------------------
export const updatePathString = (currentPath, dx, dy, rotate) => {
  try {
    let encodeToAbs = new SVGPathData(currentPath);
    if (rotate) {
      const { angle, centerPoint } = rotate;
      encodeToAbs = encodeToAbs.rotate(Number(angle), centerPoint.x, centerPoint.y);
    }
    encodeToAbs = encodeToAbs.toAbs().encode();
    const commands = SVGPathData.parse(encodeToAbs);
    const newCommands = commands.map((it, i) => {
      const newIt = { ...it };
      const { x, y } = newIt;
      newIt.x = x + dx;
      newIt.y = y + dy;

      return { ...newIt };
    });
    encodeToAbs = SVGPathData.encode(newCommands);
    return new SVGPathData(encodeToAbs).toRel().encode();
  } catch (error) {
    console.error(error);
  }
  return null;
};
// ----------------------------------------------------------------------
export const delPointAndUpdatePath = (idPath, idCircle, color) => {
  try {
    const currentPath = d3.select(`#${idPath}`).attr('d');
    const encodeToAbs = new SVGPathData(currentPath).toAbs().encode();
    const commands = SVGPathData.parse(encodeToAbs);
    const toBeDeletedCirle = d3.select(`#id_${idCircle}`);
    if (!toBeDeletedCirle) {
      throw new Error('Point not founded');
    }

    const c = commands.find((it, i) => {
      return (
        i > 0 &&
        Number(it.x) === Number(toBeDeletedCirle.attr('cx')) &&
        Number(it.y) === Number(toBeDeletedCirle.attr('cy'))
      );
    });
    if (!c) {
      throw new Error('Point not founded');
    }
    const newCommands = commands.filter((it) => {
      return Number(it.x) !== Number(c.x) && Number(it.y) !== Number(c.y);
    });
    if (newCommands.length <= 1) throw new Error('Minimum point on array is 1');
    const newPath = SVGPathData.encode(newCommands);
    d3.select(`#${idPath}`).attr('d', newPath);
    delCtrlCircle(idCircle);
    return constructCirclesFromCommands(idPath, newCommands, color);
  } catch (error) {
    console.error();
    throw error;
  }
};
const delCtrlCircle = (idCircle) => {
  try {
    d3.select(`#id_${idCircle}`).remove();
    d3.select(`#id_${idCircle}_ctrl1`).remove();
    d3.select(`#id_${idCircle}_ctrl2`).remove();
    d3.select(`#id_${idCircle}_ctrl3`).remove();
  } catch (error) {
    console.error(error);
  }
};
// ----------------------------------------------------------------------
export const getControlPointFromPath = (idPath) => {
  try {
    const pathFounded = d3.select(`#${idPath}`);
    if (!pathFounded) return;
    const imgpath = pathFounded.attr('d');
    const color = pathFounded.attr('color');
    let encodeToAbs = imgpath;
    encodeToAbs = new SVGPathData(encodeToAbs).toAbs().encode();
    const commands = SVGPathData.parse(encodeToAbs);
    return constructCircles(idPath, commands, color);
  } catch (error) {
    console.error(error);
  }
};
const constructCircles = (idPath, commands, color) => {
  const circles = [];
  commands
    .filter((it, i) => i > -1)
    .forEach((it, i) => {
      const {
        relative,
        type,
        x,
        y,
        x1,
        y1,
        x2,
        y2,
        cX,
        cY,
        phi1,
        phi2,
        rX,
        rY,
        xRot,
        sweepFlag,
        lArcFlag
      } = it;

      const labelItem = LABELS.find((myLabel) => Number(type) === Number(myLabel.type));
      let label = labelItem.label;
      let id = `id_${i}`;
      let idText = `id_text_${id}`;
      if (x && y && labelItem) {
        let label = `${labelItem.label}_${i}(${x},${y})`;
        circles.push({
          id,
          idText,
          color,
          cx: x,
          cy: y,
          label,
          isOnPath: true,
          idPath
        });
      }
      switch (type) {
        case COMMANDS_TYPE.CURVE_TO:
        case COMMANDS_TYPE.SMOOTH_CURVE_TO:
        case COMMANDS_TYPE.QUAD_TO:
        case COMMANDS_TYPE.SMOOTH_QUAD_TO:
          if (x1 && y1) {
            label = `${labelItem.label}_${i}_Ctrl-1`;
            id = `id_${i}_ctrl1`;
            circles.push({
              id,
              idText,
              color,
              cx: x1,
              cy: y1,
              label,
              idPath
            });
          }
          if (x2 && y2) {
            label = `${labelItem.label}_${i}_Ctrl-2`;
            id = `id_${i}_ctrl2`;
            circles.push({
              id,
              idText,
              color,
              cx: x2,
              cy: y2,
              label,
              idPath
            });
          }
          break;
        case COMMANDS_TYPE.ARC:
          if (cX && cY) {
            label = `${labelItem.label}_${i}_Ctrl-Center`;
            id = `id_${i}_ctrl3`;
            circles.push({
              id,
              idText,
              color,
              cx: cX,
              cy: cY,
              label,
              idPath
            });
          }
          break;
        default:
          break;
      }
    });
  return circles;
};
// ----------------------------------------------------------------------
export const getControlPointFromTracing = (tracing) => {
  try {
    const { imgpath, color, id } = tracing;

    let encodeToAbs = null;
    const pathFounded = `${id}`;
    const gPath = d3.select(`#${pathFounded}`);
    if (gPath && gPath.attr('d')) {
      encodeToAbs = gPath.attr('d');
    } else {
      encodeToAbs = imgpath;
    }
    encodeToAbs = new SVGPathData(encodeToAbs).toAbs().encode();
    const commands = SVGPathData.parse(encodeToAbs);
    return constructCirclesFromCommands(pathFounded, commands, color);
  } catch (error) {
    console.error(error);
  }
};
export const getPointsFromPath = (imgpath) => {
  try {
    const encodeToAbs = new SVGPathData(imgpath).toAbs().encode();
    const commands = SVGPathData.parse(encodeToAbs);
    return commands;
  } catch (error) {
    console.error(error);
  }
};

const constructCirclesFromCommands = (idPath, commands, color) => {
  const circles = [];
  commands
    .filter((it, i) => i > -1)
    .forEach((it, i) => {
      const { type, x, y, x1, y1, x2, y2, cX, cY } = it;

      const labelItem = LABELS.find((myLabel) => Number(type) === Number(myLabel.type));
      let label = labelItem.label;
      const uid = faker.datatype.number();
      let id = `id_${i}`;
      let idText = `id_text_${id}`;
      if (x && y && labelItem) {
        let label = `${labelItem.label}_${i}(${x},${y})`;
        circles.push({
          id,
          uid,
          idText,
          color,
          cx: x,
          cy: y,
          label,
          isOnPath: true,
          idPath: idPath
        });
      }
      switch (type) {
        case COMMANDS_TYPE.CURVE_TO:
        case COMMANDS_TYPE.SMOOTH_CURVE_TO:
        case COMMANDS_TYPE.QUAD_TO:
        case COMMANDS_TYPE.SMOOTH_QUAD_TO:
          if (x1 && y1) {
            label = `${labelItem.label}_${i}_Ctrl-1`;
            id = `id_${i}_ctrl1`;
            circles.push({
              id,
              uid,
              idText,
              color,
              cx: x1,
              cy: y1,
              label,
              idPath: idPath
            });
          }
          if (x2 && y2) {
            label = `${labelItem.label}_${i}_Ctrl-2`;
            id = `id_${i}_ctrl2`;
            circles.push({
              id,
              uid,
              idText,
              color,
              cx: x2,
              cy: y2,
              label,
              idPath: idPath
            });
          }
          break;
        case COMMANDS_TYPE.ARC:
          if (cX && cY) {
            label = `${labelItem.label}_${i}_Ctrl-Center`;
            id = `id_${i}_ctrl3`;
            circles.push({
              id,
              uid,
              idText,
              color,
              cx: cX,
              cy: cY,
              label,
              idPath: idPath
            });
          }
          break;
        default:
          break;
      }
    });
  return circles;
};
// ----------------------------------------------------------------------
export const getMovePoint = (imgpath) => {
  let encodeToAbs = imgpath;
  encodeToAbs = new SVGPathData(encodeToAbs).toAbs().encode();
  const commands = SVGPathData.parse(encodeToAbs);
  return commands[0];
};
export const setMovePoint = (idPath, movePoint) => {
  if (!idPath || !movePoint) return;
  const { x, y } = movePoint;

  const currentPath = d3.select(`#${idPath}`).attr('d');
  const encodeToAbs = new SVGPathData(currentPath).toRel().encode();
  const commands = SVGPathData.parse(encodeToAbs);
  const newCommands = commands.map((it, i) => {
    if (i === 0) {
      it.x = x;
      it.y = y;
    }

    return { ...it };
  });
  const newPath = SVGPathData.encode(newCommands);
  d3.select(`#${idPath}`).attr('d', newPath);
};

export const getTransformPath = (imgPath, scale, rotate, centerPoint, translate) => {
  const commandsFirst = SVGPathData.parse(imgPath)[0];
  let isNoRotate = true;
  let encodeToAbs = new SVGPathData(imgPath);
  if (translate) {
    const translateX = translate ? Number(translate.x) : 1;
    const translateXY = translate ? Number(translate.y) : 1;
    encodeToAbs = encodeToAbs.translate(translateX, translateXY);
  }
  if (scale) {
    const scaleX = scale ? Number(scale.x) : 1;
    const scaleY = scale ? Number(scale.y) : 1;
    encodeToAbs = encodeToAbs.scale(scaleX, scaleY);
  }
  if (rotate && centerPoint) {
    isNoRotate = false;
    const rotateDeg = rotate ? Number(rotate) : 0;
    encodeToAbs = encodeToAbs.rotate(rotateDeg, centerPoint.x, centerPoint.y);
  }
  encodeToAbs = encodeToAbs.toAbs().encode();

  const encodeToRel = new SVGPathData(encodeToAbs).toRel().encode();
  const commands = SVGPathData.parse(encodeToRel);
  const newCommands = commands.map((it, i) => {
    if (i === 0 && isNoRotate) {
      const { x, y } = commandsFirst;
      it.x = x;
      it.y = y;
    }

    return { ...it };
  });
  const newPath = SVGPathData.encode(newCommands);
  return newPath;
};
export const applyTranformToGroupToPath = (imgPath, scale, translate) => {
  let encodeToAbs = new SVGPathData(imgPath);
  if (translate) {
    const translateX = translate ? Number(translate.x) : 1;
    const translateXY = translate ? Number(translate.y) : 1;

    encodeToAbs = encodeToAbs.translate(translateX, translateXY);
  }
  if (scale) {
    const scaleX = scale ? Number(scale.x) : 1;
    const scaleY = scale ? Number(scale.y) : 1;
    encodeToAbs = encodeToAbs.scale(scaleX, scaleY);
  }
  encodeToAbs = encodeToAbs.toAbs().encode();
  const encodeToRel = new SVGPathData(encodeToAbs).toRel().encode();
  const commands = SVGPathData.parse(encodeToRel);
  const newPath = SVGPathData.encode(commands);
  return newPath;
};
export const getBoundingBoxCenter = (selection) => {
  if (!selection) return;
  const element = selection.node();
  if (!element) return;
  const bbox = element.getBBox();
  return { x: bbox.x + bbox.width / 2, y: bbox.y + bbox.height / 2 };
};
export const toRel = (path) => {
  const encodeToRel = new SVGPathData(path).toRel().encode();
  return encodeToRel;
};
export const parseToCommands = (imgpath) => {
  try {
    const encodeToRel = new SVGPathData(imgpath).toRel().encode();
    const commands = SVGPathData.parse(encodeToRel);
    return commands;
  } catch (error) {
    return [];
  }
};

export const getPathfromXml = (xml) => {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, 'text/xml');
    const myPath = xmlDoc.querySelectorAll('path')[0];
    return myPath.getAttribute('d');
  } catch (error) {
    return null;
  }
};
export const drawTransfertGuide = (points, radio) => {
  try {
    const sPoint = points.find((it) => Number(it.code) === 3);
    const francfortPo = points.find((it) => Number(it.code) === 28);
    const francfortOr = points.find((it) => Number(it.code) === 6);
    if (!sPoint) {
      throw new Error('Point S non trouvé');
    }
    if (!francfortPo) {
      throw new Error('Point Po non trouvé');
    }
    if (!francfortOr) {
      throw new Error('Point Or non trouvé');
    }
    const projectSToFrancfort = findProjectingPointOnLine(
      {
        x: sPoint.xposition,
        y: sPoint.yposition
      },
      { x: francfortPo.xposition, y: francfortPo.yposition },
      { x: francfortOr.xposition, y: francfortOr.yposition }
    );
    const { distancePixel, distance } = radio;
    const distanceVertialInPix = (20 * distancePixel) / distance;
    const distanceHorizontalInPix = (60 * distancePixel) / distance;
    const distancePixSAndProjected = distanceBetweenPoints(
      [projectSToFrancfort.x, projectSToFrancfort.y],
      [sPoint.xposition, sPoint.yposition]
    );
    const pointExtendHor = extendVector(
      projectSToFrancfort,
      {
        x: francfortPo.xposition,
        y: francfortPo.yposition
      },
      distanceHorizontalInPix
    );
    const pointsPerpendsHoz = getPerpendLine(projectSToFrancfort, pointExtendHor, 1);
    const { from, to } = pointsPerpendsHoz;
    const pointOnSLine = findPointOnLine(
      projectSToFrancfort,
      {
        x: sPoint.xposition,
        y: sPoint.yposition
      },
      distanceVertialInPix
    );
    const imgPath = `M ${pointOnSLine.x} ${pointOnSLine.y} L ${projectSToFrancfort.x} ${projectSToFrancfort.y} L ${to.x} ${to.y}`;
    /* translate */
    const commandsFirst = SVGPathData.parse(imgPath)[0];
    let encodeToAbs = new SVGPathData(imgPath);
    encodeToAbs.translate(0, -distancePixSAndProjected);
    encodeToAbs = encodeToAbs.toAbs().encode();
    const encodeToRel = new SVGPathData(encodeToAbs).toRel().encode();
    return encodeToRel;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const drawSuperLineMaxi = (tracing) => {
  try {
    let encodeToAbs = tracing.imgpath;
    encodeToAbs = new SVGPathData(encodeToAbs).toAbs().encode();
    const commands = SVGPathData.parse(encodeToAbs);
    /* draw a line from point 4 to 5 */
    const from = commands[4];
    const to = commands[5];
    const middlePoint = { x: (from.x + to.x) / 2, y: (from.y + to.y) / 2 };
    const pointsPerpendsHoz = getPerpendLineVertical(from, to, 50);
    // const imgPath = `M ${from.x} ${from.y} L ${middlePoint.x} ${middlePoint.y} L ${pointsPerpendsHoz.to.x} ${pointsPerpendsHoz.to.y} L ${pointsPerpendsHoz.from.x} ${pointsPerpendsHoz.from.y} L ${to.x} ${to.y}`;
    const imgPath = `M ${from.x} ${from.y} L ${to.x} ${to.y} M ${pointsPerpendsHoz.from.x} ${pointsPerpendsHoz.from.y} L ${pointsPerpendsHoz.to.x} ${pointsPerpendsHoz.to.y}`;
    return imgPath;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
/** draw new Maxillaire method */
export const drawLineMaxi = (from, to, width) => {
  try {
    const pointsPerpendsHoz = getPerpendLineVertical(from, to, width);
    // const imgPath = `M ${from.x} ${from.y} L ${middlePoint.x} ${middlePoint.y} L ${pointsPerpendsHoz.to.x} ${pointsPerpendsHoz.to.y} L ${pointsPerpendsHoz.from.x} ${pointsPerpendsHoz.from.y} L ${to.x} ${to.y}`;
    const imgPath = `M ${from.x} ${from.y} L ${to.x} ${to.y} M ${pointsPerpendsHoz.from.x} ${pointsPerpendsHoz.from.y} L ${pointsPerpendsHoz.to.x} ${pointsPerpendsHoz.to.y}`;
    return imgPath;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getPerpendLineVerticalPath = (from, to, width) => {
  try {
    const pointsPerpendsHoz = getPerpendLineVertical(from, to, width);
    // const imgPath = `M ${from.x} ${from.y} L ${middlePoint.x} ${middlePoint.y} L ${pointsPerpendsHoz.to.x} ${pointsPerpendsHoz.to.y} L ${pointsPerpendsHoz.from.x} ${pointsPerpendsHoz.from.y} L ${to.x} ${to.y}`;
    const imgPath = `M ${pointsPerpendsHoz.from.x} ${pointsPerpendsHoz.from.y} L ${pointsPerpendsHoz.to.x} ${pointsPerpendsHoz.to.y} M ${from.x} ${from.y} L ${to.x} ${to.y}`;
    return imgPath;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const drawSuperLineMand = (from, to) => {
  try {
    if (!from || !to) return;
    const middlePoint = { x: (from.x + to.x) / 2, y: (from.y + to.y) / 2 };
    const pointsPerpendsHoz = getPerpendLineVertical(from, to, 250);
    // const imgPath = `M ${from.x} ${from.y} L ${middlePoint.x} ${middlePoint.y} L ${pointsPerpendsHoz.to.x} ${pointsPerpendsHoz.to.y} L ${pointsPerpendsHoz.from.x} ${pointsPerpendsHoz.from.y} L ${to.x} ${to.y}`;
    const imgPath = `M ${middlePoint.x} ${middlePoint.y} L ${pointsPerpendsHoz.to.x} ${pointsPerpendsHoz.to.y}`;
    return imgPath;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
/* function to find the properties of the opposed-line: */
// Properties of a line
// I:  - pointA (array) [x,y]: coordinates
//     - pointB (array) [x,y]: coordinates
// O:  - (object) { length: l, angle: a }: properties of the line
const line = (pointA, pointB) => {
  const lengthX = pointB[0] - pointA[0];
  const lengthY = pointB[1] - pointA[1];
  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX)
  };
};
// Position of a control point
// I:  - current (array) [x, y]: current point coordinates
//     - previous (array) [x, y]: previous point coordinates
//     - next (array) [x, y]: next point coordinates
//     - reverse (boolean, optional): sets the direction
// O:  - (array) [x,y]: a tuple of coordinates
const controlPoint = (current, previous, next, reverse) => {
  // When 'current' is the first or last point of the array
  // 'previous' or 'next' don't exist.
  // Replace with 'current'
  const p = previous || current;
  const n = next || current;
  // The smoothing ratio
  const smoothing = 0.2;
  // Properties of the opposed-line
  const o = line(p, n);
  // If is end-control-point, add PI to the angle to go backward
  const angle = o.angle + (reverse ? Math.PI : 0);
  const length = o.length * smoothing;
  // The control point position is relative to the current point
  const x = current[0] + Math.cos(angle) * length;
  const y = current[1] + Math.sin(angle) * length;
  return [x, y];
};

// Create the bezier curve command
// I:  - point (array) [x,y]: current point coordinates
//     - i (integer): index of 'point' in the array 'a'
//     - a (array): complete array of points coordinates
// O:  - (string) 'C x2,y2 x1,y1 x,y': SVG cubic bezier C command
export const bezierCommand = (point, i, a) => {
  // start control point
  const [cpsX, cpsY] = controlPoint(a[i - 1], a[i - 2], point);
  // end control point
  const [cpeX, cpeY] = controlPoint(point, a[i - 1], a[i + 1], true);
  return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point[0]},${point[1]}`;
};
export const svgPath = (points, command) => {
  // build the d attributes by looping over the points
  const d = points.reduce(
    (acc, point, i, a) =>
      i === 0
        ? // if first point
          `M ${point[0]},${point[1]}`
        : // else
          `${acc} ${command(point, i, a)}`,
    ''
  );
  return d;
};
/** 
 * 1px = 0.264583 mm;

 */
const PIXEL_MM = 0.264583;
export const drawTransfertPath = (distanceMm, distancePixel) => {
  const heightPixel = (TRANSFERT_GUIDE_DIMENSIONS.height * distancePixel) / distanceMm;
  const widthPixel = (TRANSFERT_GUIDE_DIMENSIONS.width * distancePixel) / distanceMm;
  const startPointVertical = {
    x: TRANSFERT_GUIDE_DIMENSIONS.start.x,
    y: TRANSFERT_GUIDE_DIMENSIONS.start.y - heightPixel
  };
  const endPointVertical = {
    x: TRANSFERT_GUIDE_DIMENSIONS.start.x,
    y: TRANSFERT_GUIDE_DIMENSIONS.start.y
  };
  const res = getPerpendLine(startPointVertical, endPointVertical, -widthPixel);
  const { from, to } = res;
  const imgPath = `M ${startPointVertical.x} ${startPointVertical.y} L ${endPointVertical.x} ${endPointVertical.y} L ${to.x} ${to.y}`;
  return imgPath;
};
//--------------------------------------------------------------------
//--------------------------------------------------------------------
export const drawTransfertPathFromModel = (transfertModelPath, distanceMm, distancePixel) => {
  const points = getAbsPoinsOfPath(transfertModelPath);
  const heightPixel = (TRANSFERT_GUIDE_DIMENSIONS.height * distancePixel) / distanceMm;
  const widthPixel = (TRANSFERT_GUIDE_DIMENSIONS.width * distancePixel) / distanceMm;
  const perpendPoint = { x: points[1].x, y: points[1].y };
  const startPoint = { x: points[1].x, y: points[1].y - heightPixel };
  const endPoint = { x: points[1].x + widthPixel, y: points[1].y };
  const imgPath = `M ${startPoint.x} ${startPoint.y} L ${perpendPoint.x} ${perpendPoint.y} L ${endPoint.x} ${endPoint.y}`;
  const encodeToAbs = new SVGPathData(imgPath).toRel().encode();
  return encodeToAbs;
};
