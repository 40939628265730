import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Avatar from '@mui/material/Avatar';
import * as d3 from 'd3';
import {
  distanceTwoPoints,
  findProjectingPointOnLine,
  distancePointToLine,
  getCoordsInvertedLine,
  invertColor,
  calculateAngles
} from 'utils/d3Util';
import useReferentiel from 'hooks/useReferentiel';
import { AO, BO, LIE_PO, UIE_PO } from 'config/appConfig';
// ----------------------------------------------------------------------
const BuildingIndicators = React.forwardRef(
  (
    { svgRef, editRadio, updateLinesAt, updateDisplayIndicators, updateIndicatorData, ...props },
    ref
  ) => {
    const [indicatorData, setindicatorData] = useState(null);
    const [displayAngles, setdisplayAngles] = useState([]);
    const [displayDistances, setdisplayDistances] = useState([]);
    const [angles, setangles] = useState(null);
    const [distances, setdistances] = useState(null);
    const [points, setpoints] = useState(null);
    const { refs } = useReferentiel();
    const [gBoxHeight, setgBoxHeight] = useState(null);
    const gBoxRef = useRef();
    useEffect(() => {
      if (!indicatorData) return;
      updateIndicatorData(indicatorData);
    }, [indicatorData]);

    // ----------------------------------------------------------------------
    useEffect(() => {
      const newIndi = { ...indicatorData };
      newIndi.distances = distances;
      newIndi.angles = angles;
      newIndi.updatedAt = new Date();
      setindicatorData(newIndi);
    }, [distances, angles]);

    // ----------------------------------------------------------------------
    useEffect(() => {
      if (!gBoxRef.current) return;
      try {
        setgBoxHeight(gBoxRef.current.offsetTop + 60);
      } catch (error) {
        console.error();
      }
    }, [gBoxRef.current]);
    // ----------------------------------------------------------------------
    useEffect(() => {
      if (!svgRef || !svgRef.current) return;
      if (!updateLinesAt) return;
      const timeout = setTimeout(() => {
        updateAngles();
        updateDistances();
      }, 200);
      return () => {
        clearTimeout(timeout);
      };
    }, [svgRef, updateLinesAt]);

    // ----------------------------------------------------------------------
    useEffect(() => {
      updateDisplayIndicators(displayAngles, displayDistances);
    }, [displayAngles, displayDistances]);
    // ----------------------------------------------------------------------
    /* update distances  */
    const updateDistances = () => {
      try {
        if (!refs) return;
        if (!Array.isArray(refs.refDistances) || refs.refDistances.length === 0) return;
        const arrLine = [];
        const svg = d3.select(svgRef.current);
        svg
          .select('#tracingGroup')
          .selectAll('line')
          .filter((it) => {
            arrLine.push(it);
            return true;
          });
        if (arrLine.length < 1) return;
        const arrPoint = [];
        svg
          .select('#pointGroup')
          .selectAll('circle')
          .filter((it) => {
            arrPoint.push(it);
            return true;
          });
        if (arrPoint.length < 1) return;
        const newDistances = refs.refDistances
          .filter((it, i) => i > -1)
          .map((it) => {
            const newIt = { ...it };
            const { code, line, point, negativeXValueAccept, negativeYValueAccept } = newIt;
            const lineRef = arrLine.find((li) => Number(li.code) === Number(line.code));

            if (lineRef) {
              const { startPoint, endPoint } = lineRef;
              const startPointRef = arrPoint.find(
                (p) => Number(p.code) === Number(startPoint.code)
              );
              const endPointRef = arrPoint.find((p) => Number(p.code) === Number(endPoint.code));
              const pointRef = point
                ? arrPoint.find((p) => Number(p.code) === Number(point.code))
                : null;
              if (startPointRef && endPointRef && pointRef) {
                let distanceCalculate = distancePointToLine(
                  { x: pointRef.x, y: pointRef.y },
                  { x: startPointRef.x, y: startPointRef.y },
                  { x: endPointRef.x, y: endPointRef.y },
                  { x: negativeXValueAccept, y: negativeYValueAccept }
                );
                distanceCalculate =
                  (distanceCalculate * editRadio.distance) / editRadio.distancePixel;
                newIt.distance = distanceCalculate;
              } else if (startPointRef && endPointRef) {
                let distanceCalculate = distanceTwoPoints(
                  { x: startPointRef.x, y: startPointRef.y },
                  { x: endPointRef.x, y: endPointRef.y }
                );
                distanceCalculate =
                  (distanceCalculate * editRadio.distance) / editRadio.distancePixel;
                newIt.distance = distanceCalculate;
              }
            }
            return { ...newIt };
          });
        setdistances(newDistances);
      } catch (error) {
        console.error(error);
      }
    };
    /* update line in live mode when points is change on svg */
    const updateAngles = () => {
      try {
        if (!refs) return;
        if (!Array.isArray(refs.refAngles) || refs.refAngles.length === 0) return;
        const svg = d3.select(svgRef.current);
        const arr = [];
        svg
          .select('#tracingGroup')
          .selectAll('line')
          .filter((it) => {
            arr.push(it);
            return true;
          });
        if (arr.length < 1) return;

        const newAngles = refs.refAngles
          .filter((it, i) => i > -1)
          .map((it) => {
            const newIt = { ...it };
            const { code, lineOne, lineTwo, isSuperior, isLineOneInverted, isLineTwoInverted } =
              newIt;
            const lineOneSvg = arr.find((line) => Number(line.code) === Number(lineOne.code));
            const lineTwoSvg = arr.find((line) => Number(line.code) === Number(lineTwo.code));
            if (lineOneSvg && lineTwoSvg) {
              let coordsLineOne = lineOneSvg.coords;
              let coordsLineTwo = lineTwoSvg.coords;
              if (isLineOneInverted) {
                coordsLineOne = getCoordsInvertedLine(coordsLineOne);
              }
              if (isLineTwoInverted) {
                coordsLineTwo = getCoordsInvertedLine(coordsLineTwo);
              }
              const angle = calculateAngles(coordsLineOne, coordsLineTwo, isSuperior);
              newIt.angle = Number(angle).toFixed(1);
            }
            return { ...newIt };
          });
        setangles(newAngles);
      } catch (error) {
        console.error(error);
      }
    };
    /*  */
    const onChangeDisplayAngle = (display) => {
      setdisplayAngles(display);
    };
    const onChangeDisplayDistances = (display) => {
      setdisplayDistances(display);
    };
    /*  */
    return (
      <Box
        ref={gBoxRef}
        sx={{
          flexGrow: 1,
          height: gBoxHeight ? `calc(100vh - ${gBoxHeight}px)` : 'auto',
          my: 2
        }}
      >
        <Divider />
        {displayAngles && (
          <ListAngle
            angles={angles}
            displayAngles={displayAngles}
            onChangeDisplayAngle={onChangeDisplayAngle}
          />
        )}
        <Divider />
        {displayDistances && (
          <>
            <ListDistance
              items={distances}
              displayDistances={displayDistances}
              onChangeDisplayDistances={onChangeDisplayDistances}
            />
            {distances && editRadio && (
              <SpecificsDistance distances={distances} editRadio={editRadio} />
            )}
          </>
        )}

        <Box sx={{ width: '100%', height: 25 }} />
      </Box>
    );
  }
);
export default BuildingIndicators;

const ListAngle = ({ angles, displayAngles, onChangeDisplayAngle }) => {
  const [displayAll, setdisplayAll] = useState(false);
  const handleToggle = (value) => () => {
    const currentIndex = displayAngles.indexOf(value);
    const newChecked = [...displayAngles];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setdisplayAll(false);
    onChangeDisplayAngle(newChecked);
  };
  const onChange = (event) => {
    const checkDisplay = event.target.checked;
    setdisplayAll(checkDisplay);
    if (checkDisplay) {
      const arr = angles.map((it) => it.code);
      onChangeDisplayAngle(arr);
    } else {
      onChangeDisplayAngle([]);
    }
  };
  if (!angles) return <></>;
  if (!displayAngles) return <></>;
  return (
    <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem
        secondaryAction={
          <Checkbox
            edge="end"
            onChange={onChange}
            checked={displayAll}
            inputProps={{ 'aria-labelledby': 'display-all' }}
          />
        }
        disablePadding
      >
        <ListItemButton>
          <ListItemText id="display-all" primary="Afficher tous" />
        </ListItemButton>
      </ListItem>
      {angles.map((it, i) => {
        const { code, color, label, angle } = it;
        const labelId = `checkbox-list-secondary-label-${code}`;
        const constrastColor = invertColor(color, true);
        return (
          <ListItem
            key={i}
            secondaryAction={
              <Checkbox
                edge="end"
                onChange={handleToggle(code)}
                checked={displayAngles.indexOf(code) !== -1}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            }
            disablePadding
          >
            <ListItemButton>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: color, width: 30, height: 30 }} alt={label}>
                  <Typography
                    variant="body1"
                    style={{ color: constrastColor }}
                    color={constrastColor}
                  >
                    {code}
                  </Typography>
                </Avatar>
              </ListItemAvatar>
              <ListItemText id={labelId} primary={`${label}: ${angle} °`} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};
const ListDistance = ({ items, displayDistances, onChangeDisplayDistances }) => {
  const [displayAll, setdisplayAll] = useState(false);

  const handleToggle = (value) => () => {
    const currentIndex = displayDistances.indexOf(value);
    const newChecked = [...displayDistances];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setdisplayAll(false);
    onChangeDisplayDistances(newChecked);
  };
  const onChange = (event) => {
    const checkDisplay = event.target.checked;
    setdisplayAll(checkDisplay);
    if (checkDisplay) {
      const arr = items.map((it) => it.code);
      onChangeDisplayDistances(arr);
    } else {
      onChangeDisplayDistances([]);
    }
  };
  if (!displayDistances) return <></>;
  if (!items) return <></>;
  return (
    <List dense sx={{ width: '100%', maxWidth: 360 }}>
      <ListItem
        key={`i_${Math.random()}`}
        secondaryAction={
          <Checkbox
            edge="end"
            onChange={onChange}
            checked={displayAll}
            inputProps={{ 'aria-labelledby': 'display-all' }}
          />
        }
        disablePadding
      >
        <ListItemButton>
          <ListItemText id="display-all" primary="Afficher tous" />
        </ListItemButton>
      </ListItem>
      {items.map((it, i) => {
        const { code, color, label, distance, hidden } = it;
        const labelId = `checkbox-list-secondary-label-${code}`;
        const constrastColor = invertColor(color, true);
        return (
          <React.Fragment key={i}>
            {hidden ? (
              <></>
            ) : (
              <ListItem
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={handleToggle(code)}
                    checked={displayDistances.indexOf(code) !== -1}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                }
                disablePadding
              >
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: color, width: 30, height: 30 }} alt={label}>
                      <Typography
                        variant="body1"
                        style={{ color: constrastColor }}
                        color={constrastColor}
                      >
                        {code}
                      </Typography>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={`${label}: ${Number(distance).toFixed(1)} mm`}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </React.Fragment>
        );
      })}
    </List>
  );
};
const SpecificsDistance = ({ distances, editRadio }) => {
  const { refs } = useReferentiel();
  const [abPo, setabPo] = useState(null);
  useEffect(() => {
    if (!refs) return;
    if (!refs.refBuildingLine) return;
    const arr = [];
    [
      { code: 50, color: '#000000', label: 'Ao-Bo', data: [AO, BO], type: 'minus' },
      { code: 60, color: '#000000', label: 'Obverbite', data: [UIE_PO, LIE_PO], type: 'plus' }
    ].forEach((it) => {
      const { code, color, label, data, type } = it;
      const tmp =
        code === 60
          ? distances.filter((it) => data.includes(Number(it.code)))
          : refs.refBuildingLine.filter((it) => data.includes(Number(it.code)));
      try {
        if (tmp.length === 2) {
          if (code === 50) {
            const distA = distanceOfLine(tmp[0]);
            const distB = distanceOfLine(tmp[1]);
            const distance = distA - distB;
            const obj = {
              code,
              color,
              label,
              distance
            };
            arr.push(obj);
          } else if (code === 60) {
            const distance =
              type === 'minus'
                ? tmp[0].distance - tmp[1].distance
                : Math.abs(tmp[0].distance + tmp[1].distance);
            const obj = {
              code,
              color,
              label,
              distance
            };
            arr.push(obj);
          }
        }
      } catch (error) {
        console.error(error);
      }
    });

    setabPo(arr);
  }, [distances, refs]);
  const distanceOfLine = (line) => {
    try {
      const { id } = line;
      const lineEl = d3.selectAll(`line[id='${id}']`).nodes()[0];
      const lineRef = d3.select(lineEl);
      const startPoint = { x: lineRef.attr('x1'), y: lineRef.attr('y1') };
      const endPoint = { x: lineRef.attr('x2'), y: lineRef.attr('y2') };
      const distance = distanceTwoPoints(startPoint, endPoint, { x: true, y: true }); //negativeValueAccept =true
      const distanceCalculate = (distance * editRadio.distance) / editRadio.distancePixel;
      return distanceCalculate;
    } catch (error) {
      console.error(error);
    }
    return null;
  };
  if (!abPo) return <></>;
  return (
    <List dense sx={{ width: '100%', maxWidth: 360 }}>
      {abPo.map((it, i) => {
        const { code, color, label, distance } = it;
        const labelId = `checkbox-list-secondary-label-${code}`;
        const constrastColor = invertColor(color, true);
        return (
          <ListItem key={i} disablePadding>
            <ListItemButton>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: color, width: 30, height: 30 }} alt={label}>
                  <Typography
                    variant="body1"
                    style={{ color: constrastColor }}
                    color={constrastColor}
                  >
                    {code}
                  </Typography>
                </Avatar>
              </ListItemAvatar>
              <ListItemText id={labelId} primary={`${label}: ${Number(distance).toFixed(1)} mm`} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};
