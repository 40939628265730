// @ts-nocheck
/* eslint-disable */
export const ROTATE = 'rotate';
export const TRANSLATE = 'translate';
export const SCALE = 'scale';

export const getTransform = (transformStr, attr) => {
  if (!transformStr) return null;
  try {
    const arrTransform = transformStr.split(' ');
    const transformElm = arrTransform.find((it) => it.includes(attr));
    if (!transformElm) return null;
    return transformElm
      .substring(transformElm.indexOf('(') + 1, transformElm.indexOf(')'))
      .split(',');
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const setTransform = (transformStr, attr, value) => {
  try {
    if (!transformStr) return value;
    const arrTransform = transformStr.split(' ');
    const transformElmIdx = arrTransform.findIndex((it) => {
      return it.includes(attr);
    });
    if (transformElmIdx < 0) {
      arrTransform.push(value);
      return arrTransform.join(' ');
    }
    arrTransform[transformElmIdx] = value;
    return arrTransform.join(' ');
  } catch (error) {
    console.error(error);
    return null;
  }
};
/*  */
export function toDegrees(rad) {
  return rad * (180 / Math.PI);
}
export function angleBetweenPoints(p1, p2) {
  if (p1[0] === p2[0] && p1[1] === p2[1]) return Math.PI / 2;
  else return Math.atan2(p2[1] - p1[1], p2[0] - p1[0]);
}

export function distanceBetweenPoints(p1, p2) {
  return Math.sqrt(Math.pow(p2[1] - p1[1], 2) + Math.pow(p2[0] - p1[0], 2));
}
export function distance(p1, p2) {
  return Math.pow(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2), 0.5);
}
export function xmlToBase64(xmlStr) {
  try {
    const parser = new DOMParser();
    const xmlz = parser.parseFromString(xmlStr, 'application/xml');
    return window.btoa(new XMLSerializer().serializeToString(xmlz));
  } catch (error) {
    return null;
  }
}
export function htmlToBase64(htmlStr) {
  try {
    const parser = new DOMParser();
    const xmlz = parser.parseFromString(htmlStr, 'text/html');
    // return window.btoa(new XMLSerializer().serializeToString(xmlz));
    return window.btoa(unescape(encodeURIComponent(new XMLSerializer().serializeToString(xmlz))));
  } catch (error) {
    console.error(error);
    return null;
  }
}
export function toSvgData(svg) {
  try {
    //get svg source.
    const serializer = new XMLSerializer();
    let source = serializer.serializeToString(svg);

    //add name spaces.
    if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
    }
    if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
    }

    //add xml declaration
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

    return source;
  } catch (error) {
    console.error(error);
    return null;
  }
}
/* https://www.geeksforgeeks.org/how-to-find-the-angle-between-two-vectors/
https://stackoverflow.com/questions/56147279/how-to-find-angle-between-two-vectors-on-canvas
https://stackoverflow.com/questions/21605732/how-to-draw-angle-in-between-two-lines-in-javascript
 */
export function angle2Vectors(ps) {
  const v1 = { x: ps[1].x - ps[0].x, y: ps[1].y - ps[0].y };
  const v2 = { x: ps[3].x - ps[2].x, y: ps[3].y - ps[2].y };
  const l1_l2 = len(ps[1], ps[0]) * len(ps[3], ps[2]);
  const v1_v2 = v1.x * v2.x + v1.y * v2.y;
  const cos = v1_v2 / (len(ps[1], ps[0]) * len(ps[3], ps[2]));
  const angCos = Math.acos(cos) * (180 / Math.PI);
  const _v1_v2_ = v1.x * v2.y - v2.x * v1.y;
  const sin = _v1_v2_ / l1_l2;
  const angSin = Math.asin(sin) * (180 / Math.PI);
  return angCos * Math.sign(angSin);
}
/* extends a vector https://math.stackexchange.com/questions/3206040/extending-vector-and-getting-its-x-and-y-end-coordinates */
/* from startPoint and endPoint with d is a distance to extend */
export function extendVector(a, b, d) {
  try {
    const dist = b.x < a.x ? b.x - a.x : a.x - b.x;
    const k = d / dist;
    const pointC = {
      x: k * b.x + (1 - k) * a.x,
      y: k * b.y + (1 - k) * a.y
    };
    return pointC;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export function extendVerticalVector(a, b, d) {
  try {
    const dist = b.y < a.y ? b.y - a.y : a.y - b.y;
    const k = d / dist;
    const pointC = {
      x: k * b.x + (1 - k) * a.x,
      y: k * b.y + (1 - k) * a.y
    };
    return pointC;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export const findPointOnLine = (from, to, d) => {
  const lenLine = Math.sqrt(Math.pow(from.x - to.x, 2) + Math.pow(from.y - to.y, 2));
  const deltaDist = d / lenLine;
  const point = {};
  point.x = (1 - deltaDist) * from.x + deltaDist * to.x;
  point.y = (1 - deltaDist) * from.y + deltaDist * to.y;
  return point;
};
const dot = (p1, p2) => p1.x * p2.x + p1.y * p2.y;
const magSq = ({ x, y }) => Math.pow(x, 2) + Math.pow(y, 2);
const angleBetweenVector = (p1, p2) => {
  return Math.acos(dot(p1, p2) / Math.sqrt(magSq(p1) * magSq(p2)));
};
const radToDeg = (rad) => {
  return rad * (180.0 / Math.PI);
};
/* line is
coords is an array of 4 item p1(x,y) and p2(x,y)
497.6225280761719
361.2386054676033
60.18093872070313
349.8059377749449
*/
const angleVector = (p1, p2) => {
  return (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
};
/* https://stackoverflow.com/questions/9614109/how-to-calculate-an-angle-from-points */
export function angleBetweenLines(l1, l2) {
  const angle1 = angle(l1[0], l1[1], l1[2], l1[3]);
  const angle2 = angle(l2[0], l2[1], l2[2], l2[3]);
  return Number(angle2) - Number(angle1);
}
function angle(originX, originY, targetX, targetY) {
  const dx = originX - targetX;
  const dy = originY - targetY;

  let theta = Math.atan2(dy, dx); // [0, Ⲡ] then [-Ⲡ, 0]; clockwise; 0° = east

  /*   if (theta < 0) {
    theta = Math.abs(theta);
  } else {
    theta = 2 * Math.PI - theta;
  }
 */
  /*   theta *= 180 / Math.PI; // [0, 180] then [-180, 0]; clockwise; 0° = east
  if (theta < 0) theta += 360; // [0, 360]; clockwise; 0° = east

  return theta; */
  return radToDeg(theta);
}
export function invertColor(hex, bw) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // https://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
}

/* export function calculateAngles(l1, l2, isSuperior) {
  const dAx = l1[2] - l1[0];
  const dAy = l1[3] - l1[1];
  const dBx = l2[2] - l2[0];
  const dBy = l2[3] - l2[1];

  let angle = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy);
  if (angle < 0) {
    angle = angle * -1;
  }
  const res = angle * (180 / Math.PI);
  return res; // isSuperior ? res : 180 - res;
} */

export function calculateAngles(l1, l2, isSuperior) {
  const angle1 = getAngleDegrees(l1);
  const angle2 = getAngleDegrees(l2);

  let angleCalc = 180 - (180 - angle1 + angle2);
  if (isSuperior && angleCalc < 90) {
    angleCalc = 180 - angleCalc;
  } else if (angleCalc > 180) {
    angleCalc = angleCalc - 180;
  }
  let res = Math.abs(angleCalc);
  if (res > 360) {
    res = res - 360;
  }
  return res; // isSuperior ? res : 180 - res;
}

function getAngleDegrees(line) {
  const fromX = line[0];
  const fromY = line[1];
  const toX = line[2];
  const toY = line[3];
  const deltaX = fromX - toX;
  const deltaY = fromY - toY;
  let radians = Math.atan2(deltaY, deltaX);
  if (radians < 0) {
    // we don't want negative angles
    radians += Math.PI * 2;
    // make negative angles positive by adding 360 degrees
  }

  return radians * (180 / Math.PI);
}
/*
export function calculateAngles(l1, l2, isSuperior) {
  const angle1 = calculateTheta(l1);
  const angle2 = calculateTheta(l2);
  console.log(angle1, angle2);
  const res = isSuperior ? 180 - Math.abs(angle2 - angle1) : Math.abs(angle2 - angle1);
  return Math.abs(angle2 - angle1); // isSuperior ? res : 180 - res;
}
const calculateTheta = (line) => {
  const v1 = { x: line[0], y: line[1] };
  const v2 = { x: line[2], y: line[3] };
  const angleRad = Math.acos(
    (v1.x * v2.x + v1.y * v2.y) /
      (Math.sqrt(v1.x * v1.x + v1.y * v1.y) * Math.sqrt(v2.x * v2.x + v2.y * v2.y))
  );

  return radToDeg(angleRad);
};
*/
export const getCoordsInvertedLine = (coords) => {
  return [coords[2], coords[3], coords[0], coords[1]];
};

export const findCenterBetweenPoints = (a, b) => {
  if (!a || !b) return;
  const xSmaller = a.x < b.x ? a.x : b.x;
  const ySmaller = a.y < b.y ? a.y : b.y;
  return { x: xSmaller + Math.abs((b.x - a.x) / 2), y: ySmaller + Math.abs((b.y - a.y) / 2) };
};
/* https://stackoverflow.com/questions/64330618/finding-the-projection-of-a-point-onto-a-line
D.X=A.X+(B.X-A.X)*CF
D.Y=A.Y+(B.Y-A.Y)*CF
*/
export const findProjectingPointOnLine = (point, lineA, lineB) => {
  try {
    /* CF=((B.X-A.X)*(C.X-A.X)+(B.Y-A.Y)*(C.Y-A.Y))/((B.X-A.X)^2+(B.Y-A.Y)^2) */
    const distPointToLine =
      ((lineB.x - lineA.x) * (point.x - lineA.x) + (lineB.y - lineA.y) * (point.y - lineA.y)) /
      (Math.pow(lineB.x - lineA.x, 2) + Math.pow(lineB.y - lineA.y, 2));
    const p = {
      x: lineA.x + (lineB.x - lineA.x) * distPointToLine,
      y: lineA.y + (lineB.y - lineA.y) * distPointToLine
    };
    return p;
  } catch (error) {
    console.error(error);
  }
};
export const distancePointToLine = (point, lineA, lineB, negativeValueAccept) => {
  try {
    const pointProjected = findProjectingPointOnLine(point, lineA, lineB);
    return distanceTwoPoints(point, pointProjected, negativeValueAccept);
  } catch (error) {
    console.error(error);
  }
};
export const distanceTwoPoints = (a, b, negativeValueAccept) => {
  const dx = b.x - a.x;
  const dy = b.y - a.y;

  let res = Math.sqrt(dx * dx + dy * dy);
  if (negativeValueAccept && negativeValueAccept.x && dx > 0) {
    res = -res;
  }
  if (negativeValueAccept && negativeValueAccept.y && dy > 0) {
    res = -res;
  }
  return res;
};
// Base64 RegExp
const base64RegExp =
  /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/;

export const isBase64 = (str) => {
  return base64RegExp.test(str);
};

export const getPerpendLine = (a, b, dist) => {
  const dx = b.x - a.x;
  const dy = b.y - a.y;
  const len = Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2));
  const udx = dx / len;
  const udy = dy / len;
  const from = { x: a.x - udy * dist, y: a.y + udx * dist };
  const to = { x: from.x + dx, y: from.y + dy };
  return { from, to };
};
/* https://math.stackexchange.com/questions/995659/given-two-points-find-another-point-a-perpendicular-distance-away-from-the-midp */
export const getPerpendLineVertical = (a, b, dist) => {
  const middlePoint = { x: (a.x + b.x) / 2, y: (a.y + b.y) / 2 };
  const dx = a.x - b.x;
  const dy = a.y - b.y;
  const perpendicularVector = { x: a.y - b.y, y: b.x - a.x };
  const len = Math.sqrt(Math.pow(a.y - b.y, 2) + Math.pow(b.x - a.x, 2));
  const fromX = middlePoint.x - (dist / len) * perpendicularVector.x;
  const fromY = middlePoint.y - (dist / len) * perpendicularVector.y;
  const toX = middlePoint.x + (dist / len) * perpendicularVector.x;
  const toY = middlePoint.y + (dist / len) * perpendicularVector.y;
  return { from: { x: fromX, y: fromY }, to: { x: toX, y: toY } }; // return the normal  rotated 90 deg
};

export const hexToRGB = (hex, alpha = 1) => {
  let parseString = hex;
  if (hex.startsWith('#')) {
    parseString = hex.slice(1, 7);
  }
  if (parseString.length !== 6) {
    return null;
  }
  const r = parseInt(parseString.slice(0, 2), 16);
  const g = parseInt(parseString.slice(2, 4), 16);
  const b = parseInt(parseString.slice(4, 6), 16);
  if (isNaN(r) || isNaN(g) || isNaN(b)) {
    return null;
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
