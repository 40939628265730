import React, { useEffect, useState } from 'react';
import { API_URL } from 'config/appConfig';
import { Button, Card, Popover, CardActions, CardContent, Typography } from '@mui/material';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import axios from 'axios';
import usePatient from 'hooks/usePatient';
import usePointTracing from 'hooks/usePointTracing';
import useNotification from 'hooks/useNotification';
// ----------------------------------------------------------------------
export default function LoadRefTracingBtn({ editTracingStep, editPoint, onComplete }) {
  const { notif } = useNotification();
  const [submitting, setsubmitting] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { createTracingFromRef, getTracingStepByPointTraincing } = usePointTracing();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleValidate = async () => {
    try {
      const editPointStepId = editPoint.id;
      const patientTracingStepId = editTracingStep ? editTracingStep.id : 0;
      await createTracingFromRef(editPointStepId, patientTracingStepId);
      await getTracingStepByPointTraincing(editPointStepId);
      onComplete();
      notif('Téléchargement réussie');
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <Button
        variant="contained"
        aria-label="details"
        size="small"
        onClick={handleClick}
        color="warning"
        sx={{ alignItems: 'center' }}
      >
        <CloudSyncIcon sx={{ mr: 1 }} /> Charger les tracés référentiels
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Card
          sx={{
            minWidth: 275,
            backgroundColor: '#1A2027'
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Les tracés référentiels seront téléchargés et remplacés ceux en cours d'utilisation.
              <br />
              Confirmez-vous ?
            </Typography>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleValidate}>Valider</Button>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </div>
  );
}
