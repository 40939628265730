import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isLoaded: false,
  refs: {}
};
const refSlice = createSlice({
  name: 'ref',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.isLoaded = false;
    },
    setInitialize(state, action) {
      state.isLoading = false;
      state.refs = action.payload.refs;
      state.isLoaded = true;
    }
  }
});
export const { startLoading, setInitialize } = refSlice.actions;
const refReducer = refSlice.reducer;

export default refReducer;
