export const themeMode = 'light';
export const themeDirection = 'ltr';

const HOST = process.env.REACT_APP_HOST ? process.env.REACT_APP_HOST : 'http://localhost:3001/v1';
export const API_URL = {
  login: `${HOST}/auth/login`,
  logout: `${HOST}/auth/logout`,
  authenticateByToken: `${HOST}/auth/me`,
  svgOptim: `${HOST}/svg/optim`,
  svgFlattenMultiPath: `${HOST}/svg/flatten-multi-path`,
  patient: `${HOST}/patient`,
  refs: `${HOST}/ref`,
  pointTracing: `${HOST}/point-tracing`,
  superposition: `${HOST}/superposition`,
  report: `${HOST}/report`,
  radio: `${HOST}/radio`,
  pointStep: `${HOST}/pointStep`,
  tracingStep: `${HOST}/tracingStep`,
  superStep: `${HOST}/superStep`,
  file: `${HOST}/file`
};
export const UNIT_MESURING = 'mm';
export const SVGEDITOR_WIDTH = 1950;
export const SVGEDITOR_HEIGHT = 2362;
export const CROP_ASPECT = 16 / 9;

export const SVGEDITOR_POINT_TEXTFONTSIZE = '22px';
export const SVGEDITOR_POINT_RADIUS = 6;
export const SVGEDITOR_LABEL_SPACING = 8;
export const SVGEDITOR_TOOLBAR_WIDTH = 240;
export const SVGEDITOR_CIRCLE_STROKEWIDTH = 2;
export const SVGEDITOR_TRACING_STROKEWIDTH = 4;
export const TEXT_POS_OPTS = [
  {
    code: 'topLeft',
    label: 'Top-Left'
  },
  {
    code: 'topRight',
    label: 'Top-Right'
  },
  {
    code: 'bottomLeft',
    label: 'Bottom-Left'
  },
  {
    code: 'bottomRight',
    label: 'Bottom-Right'
  }
];

export const UIE = 10;
export const UMT = 12;
export const LIE = 13;
export const LMT = 15;
export const A = 9;
export const B = 16;

export const O1 = 29;
export const O2 = 30;
export const A_PRIME = 31;
export const B_PRIME = 32;
export const UIE_PRIME = 33;
export const LIE_PRIME = 34;
export const AO = 23;
export const BO = 24;

export const UIE_PO = 21;
export const LIE_PO = 22;

export const SUPER_GENERALE = 1;
export const SUPER_MAXI = 2;
export const SUPER_MANDI = 3;
export const SUPER_COLOR__FILL = 'rgba(255, 99, 71, 0.2)';

export const TRANSFERT_GUIDE = 'transfertGuide';
export const TRANSFERT_GUIDE_IDPATH = 'transfertGuidePath';

export const IMPLAN_LINE = 'implanLine';
export const IMPLAN_LINE_IDPATH = 'implanLinePath';
export const IMPLAN_LINE_MAXI = 'implanLineMaxiPath';
export const IMPLAN_LINE_MANDI = 'implanLineMandiPath';
export const IMPLAN = 'implan';
export const SUPER_CAT_MAXI = 2;
export const SUPER_CAT_MANDI = 3;

export const TRACING_TYPE_PROFIL = 1;
export const TRACING_TYPE_MAXI = 2;
export const TRACING_TYPE_MANDI = 3;
export const TRACING_TYPE_BASECRANE = 4;

export const TRANCING_GROUP_ID = 'tracingGroup';

export const TRANSFERT_GUIDE_DIMENSIONS = {
  start: { x: 300, y: 600 },
  width: 50,
  height: 20
};
export const IMPLANLINE_MAXI_DIMENSIONS = {
  start: { x: 600, y: 600 },
  end: { x: 800, y: 800 },
  width: 80,
  height: 20
};
export const IMPLANLINE_MANDI_DIMENSIONS = {
  start: { x: 800, y: 600 },
  width: 70,
  height: 20
};
export const TRACING_OPACITY_HIDDEN=0.2