import React, { useEffect, useRef, useState } from 'react';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import Checkbox from '@mui/material/Checkbox';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import Paper from '@mui/material/Paper';
import * as d3 from 'd3';
import faker from 'faker';
import useEditPath from 'hooks/useEditPath';
import { getTransformPath } from 'utils/pathUtil';
// ----------------------------------------------------------------------
const Input = styled(MuiInput)`
  width: 42px;
`;
// ----------------------------------------------------------------------

const TransformScalePath = React.forwardRef(({ svgRef, editTracingGroupRef }, ref) => {
  const { handleUpdateSvgGroup } = useEditPath();
  const [resetAt, setresetAt] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    const { globalGroup } = editTracingGroupRef.current;
    handleUpdateSvgGroup(globalGroup);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-' + faker.datatype.uuid() : undefined;
  const handleReset = () => {
    if (!svgRef || !svgRef.current) return;
    const svg = d3.select(svgRef.current);
    if (!svg) return;
    setresetAt(new Date());
  };
  if (!editTracingGroupRef || !editTracingGroupRef.current) return <></>;
  return (
    <>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: 'center',
          px: 2.5
        }}
        onClick={handleClick}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 'auto',
            justifyContent: 'center'
          }}
        >
          <AspectRatioIcon />
        </ListItemIcon>
      </ListItemButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Paper sx={{ width: 320, maxWidth: 'MAX_SCALE%', p: 3 }}>
          <InputSliderScale
            svgRef={svgRef}
            resetAt={resetAt}
            editTracingGroupRef={editTracingGroupRef}
          />
          <Divider sx={{ my: 3 }} />
          <Button onClick={handleReset}>
            <RestartAltIcon /> Réinitialiser
          </Button>
        </Paper>
      </Popover>
    </>
  );
});
export default TransformScalePath;
// ----------------------------------------------------------------------
const MIN_SCALE = -5;
const MAX_SCALE = 5;
const STEP_SCALE = 0.1;
const InputSliderScale = React.forwardRef(
  ({ svgRef, resetAt, editTracingGroupRef, ...props }, ref) => {
    const { redrawPoints } = useEditPath();
    const [checked, setChecked] = React.useState(true);
    const [value, setValue] = React.useState({ x: 1, y: 1 });
    const [beginAt, setbeginAt] = React.useState(null);
    // ----------------------------------------------------------------------
    useEffect(() => {
      if (!svgRef || !svgRef.current) return;
      if (!resetAt) return;
      setValue({ x: 1, y: 1 });
    }, [svgRef, resetAt]);
    // ----------------------------------------------------------------------
    const handleSliderChange = (event, newValue, axe) => {
      const newVal = { ...value };
      if (checked) {
        newVal.x = newValue;
        newVal.y = newValue;
      } else {
        newVal[axe] = newValue;
      }

      setValue(newVal);
      setbeginAt(new Date());
    };

    const handleInputChange = (event, axe) => {
      const myVal = event.target.value === '' ? '' : Number(event.target.value);
      const newVal = { ...value };
      if (checked) {
        newVal.x = myVal;
        newVal.y = myVal;
      } else {
        newVal[axe] = myVal;
      }

      setValue(newVal);
      setbeginAt(new Date());
    };

    const handleBlur = () => {
      const newVal = { ...value };
      if (newVal.x > MAX_SCALE) {
        newVal.x = MAX_SCALE;
      } else if (newVal.x < MIN_SCALE) {
        newVal.x = MIN_SCALE;
      }
      if (newVal.y > MAX_SCALE) {
        newVal.y = MAX_SCALE;
      } else if (newVal.y < MIN_SCALE) {
        newVal.y = MIN_SCALE;
      }
      setValue(newVal);
      setbeginAt(new Date());
    };
    const handleChange = (event) => {
      setChecked(event.target.checked);
      setbeginAt(new Date());
    };

    useEffect(() => {
      if (!svgRef || !svgRef.current) return;
      if (!editTracingGroupRef || !editTracingGroupRef.current) return;
      if (!beginAt) return;
      try {
        const { idPath, currentPath } = editTracingGroupRef.current.editPath;
        if (!idPath || !currentPath) return;
        const pathFounded = d3.select(`#${idPath}`);
        if (!pathFounded) return;
        const encodePath = getTransformPath(currentPath, value);
        pathFounded.attr('d', encodePath);
        redrawPoints(idPath);
      } catch (error) {
        console.error(error);
      }
    }, [beginAt, value, checked]);

    return (
      <>
        <Typography id="input-slider" gutterBottom>
          Mise à l'échelle (x,y)
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <FormControlLabel
            value="Ratio"
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Conserver le ratio"
            labelPlacement="start"
          />
        </Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <AlignVerticalTopIcon />
          </Grid>
          <Grid item xs>
            <Slider
              step={STEP_SCALE}
              min={MIN_SCALE}
              max={MAX_SCALE}
              value={typeof value.x === 'number' ? value.x : 0}
              onChange={(e, val) => handleSliderChange(e, val, 'x')}
              aria-labelledby="input-slider"
            />
          </Grid>
          <Grid item>
            <Input
              value={value.x}
              size="small"
              onChange={(e) => handleInputChange(e, 'x')}
              onBlur={handleBlur}
              inputProps={{
                step: STEP_SCALE,
                min: MIN_SCALE,
                max: MAX_SCALE,
                type: 'number',
                'aria-labelledby': 'input-slider'
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <AlignHorizontalLeftIcon />
          </Grid>
          <Grid item xs>
            <Slider
              step={STEP_SCALE}
              min={MIN_SCALE}
              max={MAX_SCALE}
              value={typeof value.y === 'number' ? value.y : 0}
              onChange={(e, val) => handleSliderChange(e, val, 'y')}
              aria-labelledby="input-slider"
            />
          </Grid>
          <Grid item>
            <Input
              value={value.y}
              size="small"
              onChange={(e) => handleInputChange(e, 'y')}
              onBlur={handleBlur}
              inputProps={{
                step: STEP_SCALE,
                min: MIN_SCALE,
                max: MAX_SCALE,
                type: 'number',
                'aria-labelledby': 'input-slider'
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }
);
