// ----------------------------------------------------------------------

export default function Container({ theme }) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
        // backgroundImage: 'url("https://unblast.com/wp-content/uploads/2018/10/Sky-Stars-Pattern.jpg")'

        }
      }
    }
  };
}
