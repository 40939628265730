import React, { useEffect, useRef, useState } from 'react';
import { Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import useNotification from 'hooks/useNotification';
import useReferentiel from 'hooks/useReferentiel';
import { TEXT_POS_OPTS } from 'config/appConfig';
import faker from 'faker';
import useReport from 'hooks/useReport';
// ----------------------------------------------------------------------

export default function EditReportBtn({ editReport }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-' + faker.datatype.uuid() : undefined;

  return (
    <>
      {editReport ? (
        <IconButton onClick={handleClick}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button onClick={handleClick}>Ajouter</Button>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => console.log()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Paper sx={{ width: 320, maxWidth: '100%', px: 3 }}>
          <FormEdit editReport={editReport} onComplete={() => handleClose()} />
        </Paper>
      </Popover>
    </>
  );
}
const FormEdit = ({ editReport, onComplete }) => {
  const { notif } = useNotification();
  const { initReports, saveReport } = useReport();

  const reportSchema = Yup.object().shape({
    label: Yup.string().max(100).required(`Veuillez renseigner le nom du rapport`),
    description: Yup.string().max(500)
  });
  const formik = useFormik({
    initialValues: {
      label: editReport && editReport.label ? editReport.label : '',
      description: editReport && editReport.description ? editReport.description : ''
    },
    validationSchema: reportSchema,
    onSubmit: async () => {
      try {
        const obj = { ...editReport, ...values };
        await saveReport(obj);
        await initReports();
        onComplete();
        notif('Sauvegarde réussie');
      } catch (error) {
        console.error(error);
        notif(null, error);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <>
      <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-end">
        <IconButton onClick={onComplete} sx={{ my: 2 }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ mb: 5 }}>
            <TextField
              fullWidth
              type="text"
              label="Nom du rapport"
              {...getFieldProps('label')}
              error={Boolean(touched.label && errors.label)}
              helperText={touched.label && errors.label}
            />
            <TextField
              fullWidth
              type="text"
              label="Description"
              {...getFieldProps('description')}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mb: 10 }}
          >
            Valider
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
};
