import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------

const initialState = {
  editSvg: null,
  updatedEditAt: null,
  histories: []
};

const slice = createSlice({
  name: 'svgEdit',
  initialState,
  reducers: {
    initEditSvg(state, action) {
      state.editSvg = action.payload.editSvg;
      state.updatedEditAt = action.payload.updatedEditAt;
      state.histories = [];
    },
    updateEditSvg(state, action) {
      state.editSvg = action.payload.editSvg;
      state.updatedEditAt = action.payload.updatedEditAt;
      state.histories = action.payload.histories;
    },
    resetEditSvg(state, action) {
      state.editSvg = null;
      state.updatedEditAt = null;
      state.histories = [];
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { initEditSvg, updateEditSvg, resetEditSvg } = slice.actions;
