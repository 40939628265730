import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import { SVGEDITOR_WIDTH, SVGEDITOR_HEIGHT } from 'config/appConfig';

// ----------------------------------------------------------------------
const pngWidth = SVGEDITOR_WIDTH;
const pngHeight = SVGEDITOR_HEIGHT;
const MARGIN = 10;
export default function SvgTo64({ image, sx, className }) {
  const [imgBase64, setimgBase64] = useState(null);
  useEffect(() => {
    return () => {
      setimgBase64(null);
    };
  }, []);
  useEffect(() => {
    if (!image) return;
    const svgStr = atob(image);
    const blob = new Blob([svgStr], { type: 'image/svg+xml' });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      setimgBase64(base64data);
    };
  }, [image]);
  const downloadImg = () => {
    try {
      if (!imgBase64) return;

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = pngWidth;
      canvas.height = pngHeight;
      const image = new Image();
      image.onload = function () {
        context.clearRect(0, 0, pngWidth, pngHeight);
        context.drawImage(image, 0, 0, pngWidth, pngHeight);
        const pngData = canvas.toDataURL('image/png');
        const a = document.createElement('a');
        a.download = new Date().getTime() + '.png';
        a.href = pngData;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };

      image.src = imgBase64;
    } catch (error) {
      console.error(error);
    }
  };
  if (!imgBase64) return <></>;
  return (
    <Box>
      <img className={className} alt="" src={`${imgBase64}`} />
      <div className="print-hidden">
        <IconButton
          className="print-hidden"
          color="primary"
          aria-label="delete"
          size="medium"
          onClick={downloadImg}
        >
          <CenterFocusWeakIcon fontSize="inherit" />
        </IconButton>
      </div>
    </Box>
  );
}
