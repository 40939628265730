import React, { useEffect } from 'react';
import { Link as RouterLink, useParams, Outlet, useLocation } from 'react-router-dom';
import Page from 'components/Page';
import usePatient from 'hooks/usePatient';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import RouteIcon from '@mui/icons-material/Route';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import LockIcon from '@mui/icons-material/Lock';

import { Container, Box, IconButton, Button, Typography } from '@mui/material';
import { PATH_APP } from 'config/paths';
import PatientNotFounded from './PatientNotFounded';
// ----------------------------------------------------------------------

export default function EditPatient() {
  const { idPatient } = useParams();
  const { isError, isLoading, patient, initPatient } = usePatient();
  useEffect(() => {
    if (!idPatient) return;
    initPatient(idPatient);
  }, [idPatient]);
  return (
    <Page title="Edition dossier médical | OCS - Orthodontie | Céphalométrie | Superposition">
      <Container maxWidth="xl" style={{ height: '100%' }}>
        {isLoading && <></>}
        {patient && patient.id ? (
          <>
            <Box sx={{ pb: 5 }} display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <IconButton
                  to={PATH_APP.patients}
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  sx={{ mr: 2 }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant="h4" sx={{ mr: 2 }}>
                  {`${patient.firstname} ${patient.lastname}`}{' '}
                </Typography>
                {patient.lockOff && (
                  <IconButton variant="contained" color="error">
                    <LockIcon />
                  </IconButton>
                )}
              </Box>
              <Box>
                <MenuPatient patient={patient} />
              </Box>
            </Box>
          </>
        ) : (
          <>{isError ? <PatientNotFounded /> : <></>}</>
        )}
        <Outlet />
      </Container>
    </Page>
  );
}

const MenuPatient = ({ patient }) => {
  const location = useLocation();
  const MENU = [
    {
      label: 'Fiche clinique',
      icon: <SettingsAccessibilityIcon />,
      to: `info`
    },
    {
      label: 'Points et tracés',
      icon: <RouteIcon />,
      to: `point-tracing`
    },
    {
      label: 'Superposition des tracés',
      icon: <TrackChangesIcon />,
      to: `superposition`
    },
    {
      label: 'Comptes-rendu',
      icon: <ContentPasteIcon />,
      to: `report`
    }
  ];
  const getVariant = (it) => {
    try {
      const routeSplit = location.pathname.split('/');
      const routePath = routeSplit[routeSplit.length - 1];
      if (it.to === routePath) {
        return 'contained';
      }
      if (routePath === patient.id && it.to === 'info') {
        return 'contained';
      }
      return '';
    } catch (error) {
      return '';
    }
  };
  if (!patient || !patient.id) return <></>;
  return (
    <>
      <Box display="flex">
        {MENU.map((it, i) => {
          return (
            <Button
              sx={{ mx: 1 }}
              key={it.label}
              to={`${PATH_APP.editPatient}/${patient.id}/${it.to}`}
              variant={getVariant(it)}
              color="primary"
              component={RouterLink}
              startIcon={it.icon}
            >
              {it.label}
            </Button>
          );
        })}
      </Box>
    </>
  );
};
