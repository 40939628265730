import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Button, Checkbox, FormControlLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import useNotification from 'hooks/useNotification';
import useReferentiel from 'hooks/useReferentiel';
import ColorPicker from 'components/color/ColorPicker';
import { API_URL } from 'config/appConfig';
import SimpleUploadFile from 'components/file/SimpleUploadFile';
import ImgBoxFromUrl from 'components/media/ImgBoxFromUrl';
import faker from 'faker';
// ----------------------------------------------------------------------

export default function EditDistanceBtn({ editDistance }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-' + faker.datatype.uuid() : undefined;

  return (
    <>
      {editDistance ? (
        <IconButton onClick={handleClick}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button onClick={handleClick}>
          <AddIcon /> Ajouter une distance
        </Button>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => console.log()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Paper sx={{ width: 520, maxWidth: '100%', px: 3 }}>
          <FormEdit editDistance={editDistance} onComplete={() => handleClose()} />
        </Paper>
      </Popover>
    </>
  );
}
const FormEdit = ({ editDistance, onComplete }) => {
  const [color, setcolor] = useState(null);
  const { notif } = useNotification();
  const { refs, saveDistanceFormdata, saveDistance, initRefs } = useReferentiel();
  useEffect(() => {
    if (!editDistance || !editDistance.color) {
      setcolor('#000000');
    } else {
      setcolor(editDistance.color);
    }
  }, [editDistance]);

  const radioSchema = Yup.object().shape({
    code: Yup.number().required(`Veuillez renseigner le code`),
    label: Yup.string().max(100).required(`Veuillez renseigner le libellé`),
    indication: Yup.string().max(300).required(`Veuillez renseigner l'indication`),
    line: Yup.string().required(`Veuillez renseigner le plan`),
    hidden: Yup.boolean()
  });
  const formik = useFormik({
    initialValues: {
      code: editDistance && editDistance.code ? editDistance.code : '',
      color: editDistance && editDistance.color ? editDistance.color : '',
      label: editDistance && editDistance.label ? editDistance.label : '',
      averageValue: editDistance && editDistance.averageValue ? editDistance.averageValue : '',
      indication: editDistance && editDistance.indication ? editDistance.indication : '',
      line: editDistance && editDistance.line ? editDistance.line.id : '',
      point: editDistance && editDistance.point ? editDistance.point.id : '',
      hidden: editDistance && editDistance.hidden ? true : false,
      negativeXValueAccept: editDistance && editDistance.negativeXValueAccept ? true : false,
      negativeYValueAccept: editDistance && editDistance.negativeYValueAccept ? true : false
    },
    validationSchema: radioSchema,
    onSubmit: async () => {
      try {
        const newData =
          editDistance && editDistance.id
            ? { ...editDistance, ...values, color }
            : { ...values, color };
        /* in case file upload is choiced we need to make formdata */
        const isFormdata = values.image;
        if (isFormdata) {
          const formData = new FormData();

          if (values.image) {
            delete newData.image;
            formData.append('image', values.image);
          }
          Object.entries(newData).forEach(([e, k]) => {
            formData.append(e, k);
          });

          await saveDistanceFormdata(newData, formData);
        } else {
          await saveDistance({
            ...newData
          });
        }

        await initRefs();
        notif('Sauvegarde réussie');
        onComplete();
      } catch (error) {
        notif(null, error);
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange
  } = formik;
  const { refBuildingLine, refPoints } = refs;
  const imageUrl =
    editDistance && editDistance.image ? `${API_URL.file}/${editDistance.image}` : null;
  return (
    <>
      <Box sx={{ width: '100%' }} display="flex" justifyContent="flex-end">
        <IconButton onClick={onComplete} sx={{ my: 2 }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ mb: 5 }}>
            {color && <ColorPicker color={color} hanldeColor={(color) => setcolor(color)} />}
            <TextField
              fullWidth
              type="number"
              label="Code"
              {...getFieldProps('code')}
              error={Boolean(touched.code && errors.code)}
              helperText={touched.code && errors.code}
            />
            <TextField
              fullWidth
              type="text"
              label="Libellé"
              {...getFieldProps('label')}
              error={Boolean(touched.label && errors.label)}
              helperText={touched.label && errors.label}
            />
            {/* 
            <TextField
              fullWidth
              type="text"
              label="Moyenne"
              {...getFieldProps('averageValue')}
              error={Boolean(touched.averageValue && errors.averageValue)}
              helperText={touched.averageValue && errors.averageValue}
            /> */}
            <TextField
              fullWidth
              type="text"
              label="Indication"
              {...getFieldProps('indication')}
              error={Boolean(touched.indication && errors.indication)}
              helperText={touched.indication && errors.indication}
            />

            <TextField
              fullWidth
              select
              label="Plan"
              {...getFieldProps('line')}
              error={Boolean(touched.line && errors.line)}
              helperText={touched.line && errors.line}
            >
              {refBuildingLine.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              select
              label="Point de mesure"
              {...getFieldProps('point')}
              error={Boolean(touched.point && errors.point)}
              helperText={touched.point && errors.point}
            >
              {refPoints.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {`${option.label}-${option.code}`}
                </MenuItem>
              ))}
            </TextField>

            <FormControlLabel
              control={<Checkbox checked={values.hidden} color="primary" />}
              label="Rendre invisible la valeur"
              name="hidden"
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Checkbox checked={values.negativeXValueAccept} color="primary" />}
              label="Valeur X négative acceptée"
              name="negativeXValueAccept"
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Checkbox checked={values.negativeYValueAccept} color="primary" />}
              label="Valeur Y négative acceptée"
              name="negativeYValueAccept"
              onChange={handleChange}
            />
            <Stack direction="row" spacing={2}>
              {imageUrl && (
                <ImgBoxFromUrl
                  image={imageUrl}
                  sx={{
                    borderRadius: 1,
                    objectFit: 'cover',
                    width: '150px',
                    height: 'auto'
                  }}
                />
              )}
              <SimpleUploadFile
                value={values.image}
                label="Image d'illustration"
                onChange={(val) => setFieldValue('image', val)}
                caption="(Les formats *.jpeg and *.png sont acceptés)"
                error={Boolean(touched.image && errors.image)}
                sx={{ maxHeight: '120px' }}
                acceptFile={{
                  accept: {
                    'image/jpeg': ['.jpeg', '.png']
                  }
                }}
                mediaType="image"
              />
            </Stack>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mb: 10 }}
          >
            Valider
          </LoadingButton>
          <Box sx={{ width: '100%', height: '60px' }} />
        </Form>
      </FormikProvider>
    </>
  );
};
