import React from 'react';
import useEditReport from 'hooks/useEditReport';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ImgTo64 from 'components/media/ImgTo64';
import ReportEditText from './ReportEditText';
// ----------------------------------------------------------------------
const Item = styled(Paper)(({ theme, color }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: color ? color : theme.palette.text.secondary
}));
export default function ReportCoverPage() {
  const { editReport, updateEditReport } = useEditReport();

  const onChange = (field, text, objNameField) => {
    if (!editReport) return;
    const newReport = { ...editReport };
    const newRawData = { ...newReport.rawData };
    const newObjChanged = { ...newRawData[objNameField] };
    newObjChanged[field] = text;
    newRawData[objNameField] = newObjChanged;
    newReport.rawData = newRawData;
    updateEditReport(newReport);
  };
  if (!editReport) return <></>;
  const { patient, signature } = editReport.rawData;
  if (!patient) return <></>;
  if (!signature) return <></>;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'flex-start'
            }}
          >
            <Box className="print-logo">
              <ImgTo64 image="/static/logo-ocs.png" sx={{ width: 200, height: 'auto' }} />
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              padding: 0,
              backgroundColor: '#272739',
              borderRadius: 0
            }}
          >
            <Box
              className="hor-bar"
              sx={{
                backgroundColor: '#C35A43',
                color: '#FFFFFF',
                width: 300,
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'space-around'
              }}
            >
              <h5>Orthodontie</h5>
              <h5>Céphalométrie</h5>
              <h5>Superposition</h5>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <h1>Analyse OCS</h1>
          </Item>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: '50px' }}>
          <Item>
            <ReportEditText
              text={patient.displayName}
              onChange={(val) => onChange('displayName', val, 'patient')}
            >
              <h3>{patient.displayName}</h3>
            </ReportEditText>
            <ReportEditText
              text={patient.birthday}
              onChange={(val) => onChange('birthday', val, 'patient')}
            >
              <h3>{patient.birthday}</h3>
            </ReportEditText>
          </Item>
        </Grid>

        <Grid item xs={4}>
          <Item>
            <Box className="print-logo" sx={{ width: 200, height: 'auto' }}>
              <ImgTo64 image="/static/tracing-1.png" sx={{ width: 200, height: 'auto' }} />
            </Box>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <Box className="print-logo" sx={{ width: 200, height: 'auto' }}>
              <ImgTo64 image="/static/tracing-2.png" sx={{ width: 200, height: 'auto' }} />
            </Box>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <Box className="print-logo" sx={{ width: 200, height: 'auto' }}>
              <ImgTo64 image="/static/tracing-3.png" sx={{ width: 200, height: 'auto' }} />
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '50px' }}>
          <Item>
            <ReportEditText
              text={signature.date}
              onChange={(val) => onChange('date', val, 'signature')}
            >
              <h3>{signature.date}</h3>
            </ReportEditText>
            <ReportEditText
              text={signature.doctor}
              onChange={(val) => onChange('doctor', val, 'signature')}
            >
              <h3>{signature.doctor}</h3>
            </ReportEditText>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
