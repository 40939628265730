import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isError: false,
  patient: {},
  pointtracings: null,
  superpositions: null,
  reports: null,
  editReport: null
};
const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setPatient(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.patient = action.payload.patient;
    },
    setPointTracing(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.pointtracings = action.payload.pointtracings;
    },
    setSuperpositions(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.superpositions = action.payload.superpositions;
    },
    setReports(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.reports = action.payload.reports;
    },
    setEditReport(state, action) {
      state.editReport = action.payload.editReport;
    },
    resetPatient(state, action) {
      state.patient = null;
      state.pointtracings = null;
      state.superpositions = null;
      state.reports = null;
      state.editReport = null;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isError = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    }
  }
});
export const {
  startLoading,
  stopLoading,
  setError,
  setPatient,
  setPointTracing,
  setSuperpositions,
  setReports,
  setEditReport,
  resetPatient
} = patientSlice.actions;
const patientReducer = patientSlice.reducer;

export default patientReducer;
