import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';

// ----------------------------------------------------------------------

export default function ImgTo64({ image, sx }) {
  const [imgBase64, setimgBase64] = useState(null);
  useEffect(() => {
    return () => {
      setimgBase64(null);
    };
  }, []);
  useEffect(() => {
    if (!image) return;
    const init = async () => {
      try {
        const response = await axios.get(`${image}`, {
          responseType: 'arraybuffer'
        });
        const blob = new Blob([response.data], {
          type: response.headers['content-type']
        });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64data = reader.result;
          setimgBase64(base64data);
        };
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [image]);
  if (!imgBase64) return <></>;
  return (
    <>
      <img alt="" src={`${imgBase64}`} />
    </>
  );
}
