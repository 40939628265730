import React, { useEffect, useRef, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled, useTheme } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import OpacityIcon from '@mui/icons-material/Opacity';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import GradientIcon from '@mui/icons-material/Gradient';
import ContrastIcon from '@mui/icons-material/Contrast';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Paper from '@mui/material/Paper';
import * as d3 from 'd3';
import useNotification from 'hooks/useNotification';
import { KingBed } from '@mui/icons-material';
import faker from 'faker';
import TransformScalePath from './TransformScalePath';
import TransformRotatePath from './TransformRotatePath';
import TransformScaleTracingGroup from './TransformScaleTracingGroup';
import TransformRotateTracingGroup from './TransformRotateTracingGroup';
// ----------------------------------------------------------------------
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));
const TracingRightToolbar = React.forwardRef(
  ({ svgRef, editTracingGroupRef, boxTransformGroupRef, boxTransformPathRef, ...props }, ref) => {
    return (
      <Drawer variant="permanent" open={false} anchor="right">
        <DrawerHeader />
        <Divider />
        <List sx={{ mt: 4 }}>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ConfigLayerBtn svgRef={svgRef} />
          </ListItem>
        </List>
        <List ref={boxTransformPathRef} style={{ display: 'none' }}>
          <ListItem disablePadding>
            <TransformScalePath svgRef={svgRef} editTracingGroupRef={editTracingGroupRef} />
          </ListItem>
          <ListItem disablePadding>
            <TransformRotatePath svgRef={svgRef} editTracingGroupRef={editTracingGroupRef} />
          </ListItem>
        </List>
        <List ref={boxTransformGroupRef} style={{ display: 'none' }}>
          <ListItem disablePadding>
            <TransformScaleTracingGroup svgRef={svgRef} editTracingGroupRef={editTracingGroupRef} />
          </ListItem>
          <ListItem disablePadding>
            <TransformRotateTracingGroup
              svgRef={svgRef}
              editTracingGroupRef={editTracingGroupRef}
            />
          </ListItem>
        </List>
      </Drawer>
    );
  }
);
export default TracingRightToolbar;

// ----------------------------------------------------------------------
const Input = styled(MuiInput)`
  width: 42px;
`;

// ----------------------------------------------------------------------

const ConfigLayerBtn = React.forwardRef(({ svgRef }, ref) => {
  const [resetAt, setresetAt] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-' + faker.datatype.uuid() : undefined;
  const handleReset = () => {
    if (!svgRef || !svgRef.current) return;
    const svg = d3.select(svgRef.current);
    if (!svg) return;
    setresetAt(new Date());
    svg.select('#radioImage').attr('opacity', 1);
    svg.select('#radioImage').style('filter', 'contrast(1) brightness(1) saturate(1)');
  };
  return (
    <>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: 'center',
          px: 2.5
        }}
        onClick={handleClick}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 'auto',
            justifyContent: 'center'
          }}
        >
          <FormatColorFillIcon />
        </ListItemIcon>
      </ListItemButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Paper sx={{ width: 320, maxWidth: '100%', p: 3 }}>
          <InputSliderOpacity svgRef={svgRef} resetAt={resetAt} />

          <InputSliderFilter svgRef={svgRef} filterAttr="contrast" resetAt={resetAt} />
          <InputSliderFilter svgRef={svgRef} filterAttr="saturate" resetAt={resetAt} />
          <InputSliderFilter svgRef={svgRef} filterAttr="brightness" resetAt={resetAt} />
          <Divider sx={{ my: 3 }} />
          <Button onClick={handleReset}>
            <RestartAltIcon /> Réinitialiser
          </Button>
        </Paper>
      </Popover>
    </>
  );
});
// ----------------------------------------------------------------------

const InputSliderOpacity = React.forwardRef(({ svgRef, resetAt, ...props }, ref) => {
  const [value, setValue] = React.useState(0);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  useEffect(() => {
    if (!svgRef || !svgRef.current) return;
    if (!resetAt) return;
    const svg = d3.select(svgRef.current);
    setValue(100);
  }, [svgRef, resetAt]);

  useEffect(() => {
    if (!svgRef || !svgRef.current) return;
    const svg = d3.select(svgRef.current);
    if (!svg) return;
    const currentOpacityValue = svg.select('#radioImage').attr('opacity');
    if (currentOpacityValue) {
      setValue(Math.floor(Number(currentOpacityValue) * 100));
    } else {
      setValue(100);
    }
  }, [svgRef]);

  useEffect(() => {
    if (!svgRef || !svgRef.current) return;
    if (Number(value) < 1) return;
    const opacityValue = Number(value) / 100;
    const svg = d3.select(svgRef.current);
    if (!svg) return;
    svg.select('#radioImage').transition().duration(250).attr('opacity', opacityValue);
  }, [value]);

  return (
    <>
      <Typography id="input-slider" gutterBottom>
        Opacité
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <OpacityIcon />
        </Grid>
        <Grid item xs>
          <Slider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Input
            value={value}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}
          />
        </Grid>
      </Grid>
    </>
  );
});
// ----------------------------------------------------------------------
const InputSliderFilter = React.forwardRef(({ svgRef, filterAttr, resetAt, ...props }, ref) => {
  const [value, setValue] = React.useState(0);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };
  useEffect(() => {
    if (!svgRef || !svgRef.current) return;
    if (!resetAt) return;
    const svg = d3.select(svgRef.current);
    setValue(100);
  }, [svgRef, resetAt]);

  useEffect(() => {
    if (!svgRef || !svgRef.current) return;
    if (!filterAttr) return;
    const svg = d3.select(svgRef.current);
    if (!svg) return;
    const filterValues = getStyleValue(svg.select('#radioImage').attr('style'), filterAttr);
    if (filterValues) {
      setValue(Math.floor(Number(filterValues) * 100));
    } else {
      setValue(100);
    }
  }, [svgRef, filterAttr]);

  useEffect(() => {
    if (!svgRef || !svgRef.current) return;
    if (Number(value) < 1) return;
    const filterAttrValue = Number(value) / 100;
    const svg = d3.select(svgRef.current);
    if (!svg) return;
    const newStyleValue = setStyleValue(
      svg.select('#radioImage').attr('style'),
      filterAttr,
      filterAttrValue
    );
    svg.select('#radioImage').transition().duration(250).style('filter', newStyleValue);
  }, [value]);

  const getMeta = () => {
    const obj = {};
    if (filterAttr === 'contrast') {
      obj.title = 'Contraste';
      obj.icon = <ContrastIcon />;
    } else if (filterAttr === 'saturate') {
      obj.title = 'Saturation';
      obj.icon = <GradientIcon />;
    } else if (filterAttr === 'brightness') {
      obj.title = 'Luminosité';
      obj.icon = <ContrastIcon />;
    }
    return obj;
  };
  const metadata = getMeta();
  return (
    <>
      <Typography id="input-slider" gutterBottom>
        {metadata.title}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>{metadata.icon}</Grid>
        <Grid item xs>
          <Slider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            min={0}
            max={500}
          />
        </Grid>
        <Grid item>
          <Input
            value={value}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 10,
              min: 0,
              max: 500,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}
          />
        </Grid>
      </Grid>
    </>
  );
});
/*  find in styles values : filter: contrast(1) brightness(1) saturate(1); */
const getStyleValue = (styleValues, attr) => {
  try {
    if (!styleValues) return null;
    let arr = styleValues.split(';');
    const filterAttr = arr.find((it) => it.includes('filter:'));
    if (!filterAttr) return null;
    const filterValuesArr = filterAttr.split(':')[1].trim().split(' ');
    const existAttr = filterValuesArr.find((it) => it.includes(attr));
    if (!existAttr) return;
    return existAttr.replace(/.*\(|\).*/g, '');
  } catch (error) {
    console.error(error);
    return null;
  }
};

const setStyleValue = (styleValues, attr, newValue) => {
  try {
    if (!styleValues) return;
    let arr = styleValues.split(';');

    const filterAttrIndex = arr.findIndex((it) => it.includes('filter:'));
    if (filterAttrIndex < 0) return;
    const filterAttr = arr[filterAttrIndex];
    const filterValuesArr = filterAttr
      .split(':')[1]
      .trim()
      .split(' ')
      .map((it) => {
        let newIt = it;
        if (newIt.includes(attr)) {
          newIt = newIt.replace(/\((.*?)\)/g, `(${newValue})`);
        }
        return newIt;
      });

    const str = `${filterValuesArr.join(' ')}`;
    return str;
  } catch (error) {
    console.error(error);
    return null;
  }
};
