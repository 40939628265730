import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import HubIcon from '@mui/icons-material/Hub';
import GestureIcon from '@mui/icons-material/Gesture';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import ActionBtnPatient from 'pages/patient/ActionBtnPatient';

import usePointTracing from 'hooks/usePointTracing';
import usePatient from 'hooks/usePatient';

import EditRadioStep from './EditRadioStep';
import EditPointsStep from './EditPointsStep';
import EditTracingStep from './EditTracingStep';

import MenuEditPointTracing from './MenuEditPointTracing';

// ----------------------------------------------------------------------
const MENU = [
  {
    index: 0,
    label: 'Radiologie et échelle',
    icon: <AspectRatioIcon />
  },
  { index: 1, label: 'Positions des points', icon: <HubIcon /> },
  {
    index: 2,
    label: 'Tracés',
    icon: <GestureIcon />
  }
];
export default function EditPointTracing({ editPoint, onDelete }) {
  const [activeStep, setActiveStep] = React.useState(null);
  const { deletePointTracing, initPointTracing } = usePointTracing();
  const { patient } = usePatient();
  useEffect(() => {
    if (!editPoint) return;
    if (!editPoint.radiostep) {
      setActiveStep(0);
    } else if (!editPoint.pointstep) {
      setActiveStep(1);
    } else {
      setActiveStep(2);
    }
  }, [editPoint]);

  const handleDeleteStep = async () => {
    try {
      if (!editPoint) return;
      await deletePointTracing(editPoint);
      await initPointTracing();
      onDelete();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getActiveComponent = () => {
    try {
      let content = '';
      if (activeStep === 0) {
        content = <EditRadioStep editPoint={editPoint} />;
      } else if (activeStep === 1) {
        content = <EditPointsStep editPoint={editPoint} />;
      } else if (activeStep === 2) {
        content = <EditTracingStep editPoint={editPoint} />;
      }
      return <>{content}</>;
    } catch (error) {
      return <></>;
    }
  };
  const hanldeSelectStep = (st) => {
    try {
      if (!st) return;
      setActiveStep(st.index);
    } catch (error) {
      console.error(error);
    }
  };
  if (!patient) return <></>;
  return (
    <Box display="flex" flexDirection="column">
      {!patient.lockOff && (
        <Box sx={{ mb: 2 }}>
          <ActionBtnPatient
            label="Supprimer l'étape"
            title={`Suppression d'étape`}
            msg="Le dossier de cette étape sera supprimé. Confirmez-vous ?"
            notifMsg="Suppression avec succès."
            icon={<DeleteForeverIcon />}
            callback={() => handleDeleteStep()}
            color="error"
            variant="contained"
          />
        </Box>
      )}

      <Box sx={{ width: '100%', height: '100%' }} display="flex" justifyContent="space-between">
        {Number(activeStep) > -1 && (
          <>
            <Box sx={{ mr: 2 }}>
              <MenuEditPointTracing
                steps={MENU}
                activeStep={activeStep}
                onSelectStep={hanldeSelectStep}
              />
            </Box>

            <Box style={{ flexGrow: 1 }}>{getActiveComponent()}</Box>
          </>
        )}
      </Box>
    </Box>
  );
}
