import rtl from 'jss-rtl';
import { create } from 'jss';
import PropTypes from 'prop-types';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import useSettings from 'hooks/useSettings';
import React, { useMemo, useEffect } from 'react';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import { jssPreset, StylesProvider } from '@mui/styles';

import { CssBaseline } from '@mui/material';
import componentsOverride from './overrides';
import borderRadius from './borderRadius';
import GlobalStyles from './globalStyles';
import breakpoints from './breakpoints';
import typography from './typography';
import palette from './palette';
import shadows, { customShadows } from './shadows';
// ----------------------------------------------------------------------

RTLProvider.propTypes = {
  direction: PropTypes.oneOf(['ltr', 'rtl']),
  children: PropTypes.node
};

function RTLProvider({ direction, children }) {
  const isRTL = direction === 'rtl';
  const jss = create({
    plugins: [...jssPreset().plugins, rtl()]
  });

  const cache = createCache({
    key: isRTL ? 'rtl' : 'css',
    prepend: true,
    stylisPlugins: isRTL ? [rtlPlugin] : []
  });

  cache.compat = true;

  useEffect(() => {
    document.dir = direction;
  }, [direction]);

  return (
    <CacheProvider value={cache}>
      <StylesProvider jss={jss}>{children}</StylesProvider>
    </CacheProvider>
  );
}

ThemeConfig.propTypes = {
  children: PropTypes.node
};

function ThemeConfig({ children }) {
  const { themeMode, themeDirection } = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions = useMemo(
    () => ({
      palette: palette[isLight ? 'light' : 'dark'],
      shadows: shadows[isLight ? 'light' : 'dark'],
      customShadows: customShadows[isLight ? 'light' : 'dark'],
      typography,
      shape: borderRadius,
      breakpoints,
      direction: themeDirection,
      components: componentsOverride({
        theme: {
          palette: palette[isLight ? 'light' : 'dark'],
          shadows: shadows[isLight ? 'light' : 'dark'],
          typography,
          shape: borderRadius,
          direction: themeDirection
        }
      })
    }),
    [isLight, themeDirection]
  );

  const theme = createTheme(themeOptions);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <RTLProvider direction={themeDirection}>{children}</RTLProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeConfig;
