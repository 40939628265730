import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Page from 'components/Page';
import useReferentiel from 'hooks/useReferentiel';
import ImgFromSvg from 'components/media/ImgFromSvg';
import { fDateTimeSuffix } from 'utils/formatTime';
import DialogSuperSettings from './DialogSuperSettings';
import EditTracingBtn from './EditTracingBtn';
// ----------------------------------------------------------------------
export default function SuperSettings() {
  const { refs, initRefs } = useReferentiel();
  useEffect(() => {
    initRefs();
  }, []);
  if (!refs) return <></>;
  const currentRadio =
    Array.isArray(refs.refRadiologies) && refs.refRadiologies.length > 0
      ? refs.refRadiologies[0]
      : null;
  return (
    <Page
      title="Configuration des superposition et leurs positions par défault | OCS - Orthodontie | Céphalométrie | Superposition"
      style={{ height: '100%' }}
    >
      <Container maxWidth="xl" style={{ height: '100%' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h4">Liste des superpositions</Typography>
          </Box>
          {refs.refSuperposition && currentRadio && (
            <Box display="flex" justifyContent="flex-end" sx={{ my: 2 }}>
              <DialogSuperSettings
                points={refs.refPoints}
                superpositions={refs.refSuperposition}
                tracings={refs.refTracings.filter((it) => Number(it.code) > -1)}
                radio={currentRadio}
              />
            </Box>
          )}
        </Box>
        {refs.refSuperposition && <Grid items={refs.refSuperposition} />}
      </Container>
    </Page>
  );
}
const Grid = ({ items, ...props }) => {
  if (!items) return <></>;

  return (
    <>
      {items.map((it, i) => {
        return (
          <Card key={i} sx={{ display: 'flex', my: 3 }}>
            {it && it.xmlData ? (
              <ImgFromSvg
                svgData64={it.xmlData}
                sx={{ width: 'auto', maxHeight: '600px', height: '450px' }}
              />
            ) : (
              <Typography variant="p" color="text.secondary" component="div">
                Image non disponible
              </Typography>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography component="div" variant="h6">
                  {it.label}
                </Typography>
                <Typography variant="p" color="text.secondary" component="div">
                  Dernière mise à jour le {fDateTimeSuffix(it.updatedAt)}
                </Typography>
              </CardContent>
            </Box>
          </Card>
        );
      })}
    </>
  );
};
