import axios from 'axios';
import { API_URL } from 'config/appConfig';
// ----------------------------------------------------------------------
export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}
export const getImgAsBase64 = async (imgFile) => {
  try {
    const url = `${API_URL.file}/${imgFile}`;
    const response = await axios.get(url, {
      responseType: 'arraybuffer'
    });
    const blob = new Blob([response.data], {
      type: response.headers['content-type']
    });
    // const imageDataUrl = URL.createObjectURL(blob);
    const data64 = await blobToBase64(blob);
    return data64;
  } catch (error) {
    console.error(error);
    return null;
  }
};
