import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from 'config/appConfig';
import { setEditReport } from 'redux/slices/patientSlice';
import useNotification from 'hooks/useNotification';

// ----------------------------------------------------------------------
export default function useEditReport() {
  const { notif } = useNotification();
  const dispatch = useDispatch();
  const { isError, isLoading, patient, editReport } = useSelector((state) => state.patient);
  return {
    editReport,
    initEditReport: (report) => {
      try {
        if (!patient || !patient.id) return;
        if (!report) return;
        dispatch(setEditReport({ editReport: report }));
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    updateEditReport: (newReport) => {
      try {
        if (!patient || !patient.id) return;
        if (!editReport) return;
        if (!newReport) return;
        dispatch(setEditReport({ editReport: newReport }));
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    saveReportData: async (values) => {
      try {
        if (!patient) return;
        if (!editReport) return;
        await axios.post(`${API_URL.report}/${patient.id}/${editReport.id}/saveData`, values);
      } catch (error) {
        notif(null, error);
        throw error;
      }
    }
  };
}
