import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Page from 'components/Page';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { API_URL } from 'config/appConfig';
import { fDate } from 'utils/formatTime';
import { PATH_APP } from 'config/paths';
import { IconButton } from '@mui/material';
import Fab from '@mui/material/Fab';

import useReferentiel from 'hooks/useReferentiel';
import ImgBoxFromUrl from 'components/media/ImgBoxFromUrl';
import EditAngleBtn from './EditAngleBtn';
// ----------------------------------------------------------------------
export default function AnglesSettings() {
  const { refs, initRefs } = useReferentiel();
  useEffect(() => {
    initRefs();
  }, []);
  if (!refs) return <></>;
  return (
    <Page
      title="Configuration des angles | OCS - Orthodontie | Céphalométrie | Superposition"
      style={{ height: '100%' }}
    >
      <Container maxWidth="xl" style={{ height: '100%' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h4">Liste des angles</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <EditAngleBtn />
          </Box>
        </Box>

        {refs.refAngles && <Grid items={refs.refAngles} />}
      </Container>
    </Page>
  );
}
const Grid = ({ items, ...props }) => {
  if (!items) return <></>;
  const rows = items;
  const columns = [
    {
      field: 'codeField',
      headerName: 'Code',
      width: 80,
      sortable: true,
      renderCell: (params) => {
        try {
          const { row } = params;
          return (
            <Fab size="small" sx={{ backgroundColor: row.color }} aria-label="edit">
              {row.code}
            </Fab>
          );
        } catch (error) {
          return <></>;
        }
      }
    },
    {
      field: 'label',
      headerName: 'Libellé',
      sortable: true,
      width: 150
    },
    {
      field: 'averageValue',
      headerName: 'Moyenne',
      width: 200,
      renderCell: (params) => {
        try {
          const { row } = params;
          if (!row.averageValue) return <></>;
          return (
            <Box display="flex" flexDirection="column">
              {row.averageValue.map((it, i) => {
                const { sexe, age, value } = it;
                return (
                  <React.Fragment key={i}>
                    <p>
                      <span>{`${sexe === 'Monsieur' ? 'Garçon' : 'Fille'} ${age} ans: `} </span>
                      <strong>{value}</strong>
                    </p>
                  </React.Fragment>
                );
              })}
            </Box>
          );
        } catch (error) {
          return <></>;
        }
      }
    },
    {
      field: 'indication',
      headerName: 'Indication',
      sortable: true,
      width: 250
    },
    {
      field: 'lineOneField',
      headerName: 'Plan 1',
      width: 150,
      valueGetter: (params) => {
        try {
          const { row } = params;
          return `${row.lineOne.label}`;
        } catch (error) {
          return '';
        }
      }
    },
    {
      field: 'lineTwoField',
      headerName: 'Plan 2',
      width: 200,
      valueGetter: (params) => {
        try {
          const { row } = params;
          return `${row.lineTwo.label}`;
        } catch (error) {
          return '';
        }
      }
    },
    {
      field: 'imageField',
      headerName: 'Image',
      width: 150,
      renderCell: (params) => {
        try {
          const { row } = params;
          const imageUrl = row.image ? `${API_URL.file}/${row.image}` : null;
          return (
            <>
              {imageUrl && (
                <ImgBoxFromUrl
                  image={imageUrl}
                  sx={{
                    borderRadius: 1,
                    objectFit: 'cover',
                    width: '150px',
                    height: 'auto'
                  }}
                />
              )}{' '}
            </>
          );
        } catch (error) {
          return <></>;
        }
      }
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        try {
          return (
            <Box display="flex" alignItems="center">
              <EditAngleBtn editAngle={params.row} />
            </Box>
          );
        } catch (error) {
          return <></>;
        }
      }
    }
  ];

  return (
    <>
      <DataGrid
        rowHeight={150}
        getRowHeight={() => 'auto'}
        sx={{
          pt: 3,
          [`& .${gridClasses.cell}`]: {
            py: 1
          }
        }}
        rows={rows}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[25, 50]}
        disableSelectionOnClick
      />
    </>
  );
};
