import React, { useEffect, useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import useNotification from 'hooks/useNotification';
import useReferentiel from 'hooks/useReferentiel';
import { SketchPicker } from 'react-color';
import Fab from '@mui/material/Fab';
import faker from 'faker';
// ----------------------------------------------------------------------

export default function ColorPicker({ color, hanldeColor }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-' + faker.datatype.uuid() : undefined;

  return (
    <>
      <Fab sx={{ backgroundColor: color }} aria-label="edit" onClick={handleClick}></Fab>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <SketchPicker color={color} onChangeComplete={(color) => hanldeColor(color.hex)} />
      </Popover>
    </>
  );
}
