import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Page from 'components/Page';
import Dialog from '@mui/material/Dialog';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadIcon from '@mui/icons-material/Download';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import CircularProgress from '@mui/material/CircularProgress';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { API_URL } from 'config/appConfig';
import { fDate, fDateTime } from 'utils/formatTime';
import { PATH_APP } from 'config/paths';
import { IconButton } from '@mui/material';
import useSuperposition from 'hooks/useSuperposition';
import usePointTracing from 'hooks/usePointTracing';
import usePatient from 'hooks/usePatient';
import useReport from 'hooks/useReport';
import useNotification from 'hooks/useNotification';
import useEditReport from 'hooks/useEditReport';

import EditReportBtn from './EditReportBtn';
import ReportEditor from './ReportEditor';

// ----------------------------------------------------------------------
export default function ReportPage() {
  const { patient } = usePatient();
  const { reports, initReports } = useReport();
  useEffect(() => {
    if (!patient) return;
    initReports();
  }, [patient]);
  return (
    <Page
      title="Liste des rapports | OCS - Orthodontie | Céphalométrie | Superposition"
      style={{ height: '100%' }}
    >
      <Container maxWidth="xl" style={{ height: '100%' }}>
        <Box sx={{ pb: 5 }} display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h4">Liste des rapports</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" sx={{ my: 2 }}>
            <EditReportBtn />
          </Box>
        </Box>

        {Array.isArray(reports) && reports.length > 0 ? (
          <Grid rows={reports} />
        ) : (
          <Typography variant="h6"> Aucun rapport n'est trouvé</Typography>
        )}
      </Container>
    </Page>
  );
}
/*  <EditReportBtn editReport={params.row} />  

 <Button
                  variant="contained"
                  component={RouterLink}
                  to={`${PATH_APP.editPatient}/${patient.id}/edit-report/${id}`}
                  startIcon={<PictureInPictureAltIcon />}
                >
                  Rédiger le rapport
                </Button>
*/
const Grid = ({ rows }) => {
  const { patient } = usePatient();
  const { initReports, deleteReport } = useReport();
  const { notif } = useNotification();
  const handleDelete = async (id) => {
    try {
      await deleteReport(id);
      await initReports();
      notif('Suppression réussie');
    } catch (error) {
      notif(null, error);
    }
  };
  const columns = [
    {
      field: 'label',
      headerName: 'Nom du rapport',
      sortable: true,
      width: 160
    },
    {
      field: 'description',
      headerName: 'Description',

      width: 200
    },
    {
      field: 'createdAt',
      headerName: 'Créé le',
      width: 150,
      renderCell: (params) => {
        const dt = params.getValue(params.id, 'createdAt');
        return `${fDateTime(dt) || ''}`;
      }
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: true,
      renderCell: (params) => {
        try {
          const { id } = params;
          const { executedAt } = params.row;
          return (
            <Box display="flex" alignItems="center">
              <BtnOpenDialogReport report={params.row} />
              {!executedAt && <EditReportBtn editReport={params.row} />}

              <IconButton
                onClick={() => handleDelete(id)}
                sx={{ mx: 2 }}
                size="small"
                variant="outline"
                color="error"
              >
                <DeleteForeverIcon />
              </IconButton>
            </Box>
          );
        } catch (error) {
          return <></>;
        }
      }
    },
    {
      field: 'exports',
      headerName: 'exportés',
      flex: true,
      renderCell: (params) => {
        try {
          const { reportData, id } = params.row;
          return <ReportsExported reportId={id} data={reportData} />;
        } catch (error) {
          return <></>;
        }
      }
    }
  ];
  if (!Array.isArray(rows)) return <></>;
  return (
    <>
      <DataGrid
        autoHeight
        getRowHeight={() => 'auto'}
        rows={rows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        disableSelectionOnClick
      />
    </>
  );
};
const BtnOpenDialogReport = ({ report }) => {
  const [open, setOpen] = React.useState(false);
  const { editReport, initEditReport } = useEditReport();
  const { initReports } = useReport();
  useEffect(() => {
    if (!report) return;
    initEditReport(report);
  }, [report]);

  // ----------------------------------------------------------------------
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    initReports();
    setOpen(false);
  };
  return (
    <>
      <Button variant="contained" onClick={handleClickOpen} sx={{ mr: 2 }}>
        <PictureInPictureAltIcon sx={{ mr: 2 }} />
        Rédiger le rapport
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose}>
        {editReport && <ReportEditor handleClose={handleClose} />}
      </Dialog>
    </>
  );
};

const ReportsExported = ({ reportId, data }) => {
  if (!Array.isArray(data) || data.length === 0) return <></>;

  return (
    <List sx={{ width: '100%', maxWidth: 360, maxHeight: 400 }}>
      {data.map((it, i) => {
        return (
          <ListItem key={i} secondaryAction={<DownloadReportBtn reportId={reportId} it={it} />}>
            <ListItemAvatar>
              <Avatar>
                <PictureAsPdfIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={it.name} secondary={fDateTime(it.createdAt)} />
          </ListItem>
        );
      })}
    </List>
  );
};

const DownloadReportBtn = ({ reportId, it }) => {
  const { getPdfFromReportData } = useReport();

  const [loading, setloading] = useState(false);
  const { id, name } = it;
  const handleDownload = async () => {
    try {
      setloading(true);
      await getPdfFromReportData(reportId, id, name);
      setTimeout(() => {
        setloading(false);
      }, 2000);
    } catch (error) {
      setloading(false);
    }
  };
  if (!it) return <></>;

  return (
    <>
      {loading ? (
        <IconButton edge="end" aria-label="delete">
          <CircularProgress />
        </IconButton>
      ) : (
        <IconButton edge="end" aria-label="delete" onClick={handleDownload}>
          <DownloadIcon />
        </IconButton>
      )}
    </>
  );
};
