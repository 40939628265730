import React from 'react';
import { Tabs, Tab, Box, Container, Typography } from '@mui/material';

import usePatient from 'hooks/usePatient';
import FormPatient from './FormPatient';
import FormMedinfo from './FormMedinfo';

// ----------------------------------------------------------------------
export default function PatientInfoPage({ ...props }) {
  const { patient } = usePatient();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isEditMode = patient && patient.id;
  return (
    <Container maxWidth="xl">
      <Box display="flex">
        <Tabs
          centered
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab style={{ width: '200px' }} sx={{ m: 2 }} label="Informations personnelles" />
          <Tab
            style={{ width: '200px' }}
            sx={{ m: 2 }}
            label="Dossier médical (Facultatif)"
            disabled={!isEditMode}
          />
        </Tabs>
        <Box display="flex" sx={{ flexGrow: 1, width: '100%', ml: 3 }}>
          <TabPanel value={value} index={0}>
            <FormPatient patient={patient} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FormMedinfo />
          </TabPanel>
        </Box>
      </Box>
    </Container>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
