import * as d3 from 'd3';
import {
  SVGEDITOR_POINT_RADIUS,
  SVGEDITOR_LABEL_SPACING,
  O1,
  O2,
  A_PRIME,
  B_PRIME,
  UIE,
  UMT,
  LIE,
  LMT,
  A,
  B,
  UIE_PRIME,
  LIE_PRIME,
  SVGEDITOR_TRACING_STROKEWIDTH
} from 'config/appConfig';
import { extendVector, findCenterBetweenPoints, findProjectingPointOnLine } from 'utils/d3Util';

// ----------------------------------------------------------------------

export const drawSpecificPoint = (svgRef, point, points) => {
  if (!svgRef || !svgRef.current) return;
  calculatePO(svgRef, point, points);
};
const PO_POINTS = [A, B, UIE, UMT, LIE, LMT];

export const isSpecificPoint = (point) => {
  try {
    const { code } = point;
    if (!PO_POINTS.includes(code)) return false;
    return true;
  } catch (error) {
    return false;
  }
};
export const calculatePO = (svgRef, point, points) => {
  try {
    if (!point) return;
    if (!svgRef || !svgRef.current) return;
    const { code } = point;
    if (!PO_POINTS.includes(code)) return;

    const svg = d3.select(svgRef.current);
    const mapPo = {};
    const g = d3.select('#pointGroup');
    const arrCurrent = [];
    g.selectAll('circle').filter((it) => {
      if (it && PO_POINTS.includes(Number(it.code))) {
        mapPo[`p_${it.code}`] = it;
      }
      arrCurrent.push(it);
      return true;
    });

    const pointO1 = findCenterBetweenPoints(mapPo[`p_${UIE}`], mapPo[`p_${LIE}`]);
    const pointO2 = findCenterBetweenPoints(mapPo[`p_${LMT}`], mapPo[`p_${UMT}`]);
    const pointA = points.find((it) => Number(it.code) === Number(A));
    const pointB = points.find((it) => Number(it.code) === Number(B));
    /* A, B */
    const pointAPrime = findProjectingPointOnLine(
      { ...pointA, x: mapPo[`p_${A}`].x, y: mapPo[`p_${A}`].y },
      pointO1,
      pointO2
    );
    const pointBPrime = findProjectingPointOnLine(
      { ...pointB, x: mapPo[`p_${B}`].x, y: mapPo[`p_${B}`].y },
      pointO1,
      pointO2
    );
    /* UIE, LIE */
    const pointUIEPrime = findProjectingPointOnLine(
      { ...pointA, x: mapPo[`p_${UIE}`].x, y: mapPo[`p_${UIE}`].y },
      pointO1,
      pointO2
    );
    const pointLIEPrime = findProjectingPointOnLine(
      { ...pointB, x: mapPo[`p_${LIE}`].x, y: mapPo[`p_${LIE}`].y },
      pointO1,
      pointO2
    );

    const newPoints = points.map((it) => {
      try {
        let newIt = { ...it };
        if (Number(newIt.code) === Number(point.code)) {
          newIt = { ...newIt, xposition: point.x, yposition: point.y };
        } else if (Number(newIt.code) === O1) {
          newIt = {
            ...newIt,
            xposition: pointO1.x,
            yposition: pointO1.y,
            isSpecific: false,
            isCalculated: true
          };
        } else if (Number(newIt.code) === O2) {
          newIt = {
            ...newIt,
            xposition: pointO2.x,
            yposition: pointO2.y,
            isSpecific: false,
            isCalculated: true
          };
        } else if (Number(newIt.code) === A_PRIME) {
          newIt = {
            ...newIt,
            xposition: pointAPrime.x,
            yposition: pointAPrime.y,
            isSpecific: false,
            isCalculated: true
          };
        } else if (Number(newIt.code) === B_PRIME) {
          newIt = {
            ...newIt,
            xposition: pointBPrime.x,
            yposition: pointBPrime.y,
            isSpecific: false,
            isCalculated: true
          };
        } else if (Number(newIt.code) === UIE_PRIME) {
          newIt = {
            ...newIt,
            xposition: pointUIEPrime.x,
            yposition: pointUIEPrime.y,
            isSpecific: false,
            isCalculated: true
          };
        } else if (Number(newIt.code) === LIE_PRIME) {
          newIt = {
            ...newIt,
            xposition: pointLIEPrime.x,
            yposition: pointLIEPrime.y,
            isSpecific: false,
            isCalculated: true
          };
        } else {
          const pointOnSvg = arrCurrent.find((p) => Number(p.code) === Number(newIt.code));
          if (pointOnSvg) {
            newIt = { ...newIt, xposition: pointOnSvg.x, yposition: pointOnSvg.y };
          }
        }
        return { ...newIt };
      } catch (error) {
        console.error(error);
        return { ...it };
      }
    });
    return newPoints;
  } catch (error) {
    console.error(error);
  }
};
/*********************** drawPoints ************************/
export const drawBoldPoints = (svgRef, refs, boldPointsDisplay) => {
  try {
    const svg = d3.select(svgRef.current);
    ['circle', 'text'].forEach((it) => {
      svg
        .select('#pointGroup')
        .selectAll(it)
        .attr('opacity', (d) => {
          if (!Array.isArray(boldPointsDisplay) || boldPointsDisplay.length === 0) {
            return 1;
          }
          const isBold = boldPointsDisplay.includes(Number(d.code));
          return isBold ? 1 : 0.1;
        });
    });
  } catch (error) {
    console.error(error);
  }
};
/*********************** drawLines ************************/
export const drawLines = (svgRef, refs, boldLines) => {
  try {
    const arrPoints = [];
    d3.select('#pointGroup')
      .selectAll('circle')
      .filter((it) => {
        arrPoints.push(it);
        return true;
      });

    const arr = [];
    if (arrPoints.length === 0) return;
    refs.refBuildingLine.forEach((it) => {
      const newIt = { ...it };
      const { startPoint, endPoint } = it;

      const tracingStartPoint = arrPoints.find((pointtracing) => {
        return Number(startPoint.code) === Number(pointtracing.code);
      });
      const tracingEndPoint = arrPoints.find(
        (pointtracing) => Number(endPoint.code) === Number(pointtracing.code)
      );
      if (tracingStartPoint && tracingEndPoint) {
        let startX = tracingStartPoint.x;
        let startY = tracingStartPoint.y;
        let endX = tracingEndPoint.x;
        let endY = tracingEndPoint.y;

        /* if (Number(it.code) === 5) { }  to check the specific line with code*/
        if (Number(newIt.startLarger) !== 0) {
          const pointC = extendVector(
            { x: tracingStartPoint.x, y: tracingStartPoint.y },
            { x: tracingEndPoint.x, y: tracingEndPoint.y },
            Number(newIt.startLarger)
          );
          startX = pointC.x;
          startY = pointC.y;
        }
        if (Number(newIt.endLarger) !== 0) {
          const pointC = extendVector(
            { x: tracingEndPoint.x, y: tracingEndPoint.y },
            { x: tracingStartPoint.x, y: tracingStartPoint.y },
            Number(newIt.endLarger)
          );
          endX = pointC.x;
          endY = pointC.y;
        }
        const coords = [startX, startY, endX, endY];

        const isNoError = coords.length === 4;
        if (isNoError) {
          newIt.coords = [...coords];
          arr.push(newIt);
        }
      }
    });

    const svg = d3.select(svgRef.current);
    svg
      .select('#tracingGroup')
      .selectAll('line')
      .data(arr)
      .join('line')
      .attr('id', (d) => d.id)
      .attr('code', (d) => d.code)
      .attr('opacity', (d) => {
        if (!boldLines) {
          return 1;
        }
        const isBold = boldLines.includes(Number(d.code));
        return isBold ? 1 : 0.1;
      })
      .style('stroke', (d) => {
        return d.color ? d.color : '#000000';
      })
      .style('stroke-width', (d) => {
        if (!boldLines) {
          return 1;
        }
        const isBold = boldLines.includes(Number(d.code));
        return isBold
          ? SVGEDITOR_TRACING_STROKEWIDTH
          : Math.floor(SVGEDITOR_TRACING_STROKEWIDTH / 2);
      })
      .attr('x1', (d) => d.coords[0])
      .attr('y1', (d) => d.coords[1])
      .attr('x2', (d) => d.coords[2])
      .attr('y2', (d) => d.coords[3]);
  } catch (error) {
    console.error(error);
  }
};
export const getTextPosition = (textPosition, d) => {
  let obj = {
    x: d.x - SVGEDITOR_LABEL_SPACING - 30,
    y: d.y - SVGEDITOR_LABEL_SPACING
  };
  try {
    if (!textPosition) {
      return obj;
    }
    if (textPosition === 'topLeft') {
      obj = {
        x: d.x - SVGEDITOR_LABEL_SPACING - 20,
        y: d.y - SVGEDITOR_LABEL_SPACING
      };
    } else if (textPosition === 'topRight') {
      obj = {
        x: d.x - SVGEDITOR_LABEL_SPACING + 20,
        y: d.y - SVGEDITOR_LABEL_SPACING
      };
    } else if (textPosition === 'bottomLeft') {
      obj = {
        x: d.x - SVGEDITOR_LABEL_SPACING,
        y: d.y - SVGEDITOR_LABEL_SPACING - 20
      };
    } else if (textPosition === 'bottomRight') {
      obj = {
        x: d.x - SVGEDITOR_LABEL_SPACING + 15,
        y: d.y - SVGEDITOR_LABEL_SPACING + 20
      };
    }
    return obj;
  } catch (error) {
    console.error(error);
    return obj;
  }
};
