import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Page from 'components/Page';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { TEXT_POS_OPTS } from 'config/appConfig';
import { fDate } from 'utils/formatTime';
import { PATH_APP } from 'config/paths';
import { IconButton } from '@mui/material';
import useReferentiel from 'hooks/useReferentiel';
import Fab from '@mui/material/Fab';
 import DialogPointsSettings from './DialogPointsSettings';
import EditPointBtn from './EditPointBtn';
// ----------------------------------------------------------------------
export default function PointsSettings() {
  const { refs, initRefs } = useReferentiel();
  useEffect(() => {
    initRefs();
  }, []);
  if (!refs) return <></>;
  const currentRadio =
    Array.isArray(refs.refRadiologies) && refs.refRadiologies.length > 0
      ? refs.refRadiologies[0]
      : null;
  return (
    <Page
      title="Configuration des points et leurs position par défault | OCS - Orthodontie | Céphalométrie | Superposition"
      style={{ height: '100%' }}
    >
      <Container maxWidth="xl" style={{ height: '100%' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h4">Liste des points</Typography>
          </Box>
          {refs.refPoints && currentRadio && (
            <Box display="flex" justifyContent="flex-end" sx={{ my: 2 }}>
              <DialogPointsSettings points={refs.refPoints} radio={currentRadio} />
            </Box>
          )}
        </Box>

        {refs.refPoints && <Grid items={refs.refPoints} />}
      </Container>
    </Page>
  );
}
const Grid = ({ items, ...props }) => {
  if (!items) return <></>;
  const rows = items;
  const columns = [
    {
      field: 'codeField',
      headerName: 'Code',
      width: 80,
      sortable: true,
      renderCell: (params) => {
        try {
          const { row } = params;
          return (
            <Fab size="small" sx={{ backgroundColor: row.color }} aria-label="edit">
              {row.code}
            </Fab>
          );
        } catch (error) {
          return <></>;
        }
      }
    },
    {
      field: 'label',
      headerName: 'Libellé',
      width: 150
    },
    {
      field: 'name',
      headerName: 'Nom',
      width: 250
    },
    {
      field: 'indication',
      headerName: 'Indication',
      width: 350
    },
    {
      field: 'position',
      headerName: 'Position',
      width: 200,
      valueGetter: (params) => {
        try {
          return `x:${Math.floor(params.getValue(params.id, 'xposition')) || ''}, y:${
            Math.floor(params.getValue(params.id, 'yposition')) || ''
          }`;
        } catch (error) {
          return '';
        }
      }
    },
    {
      field: 'textPosition',
      headerName: 'Position du text',
      width: 200,
      valueGetter: (params) => {
        try {
          const { textPosition } = params.row;
          if (!textPosition) return '';
          const obj = TEXT_POS_OPTS.find((it) => String(it.code) === String(textPosition));
          if (!obj) return '';
          return `${obj.label}`;
        } catch (error) {
          return '';
        }
      }
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        try {
          return (
            <Box display="flex" alignItems="center">
              <EditPointBtn editPoint={params.row} />
            </Box>
          );
        } catch (error) {
          return <></>;
        }
      }
    }
  ];

  return (
    <>
      <DataGrid
        sx={{ pt: 3 }}
        rows={rows}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[25, 50]}
        disableSelectionOnClick
      />
    </>
  );
};
